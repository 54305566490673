import React, { useEffect} from "react";

//custom components



import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import "./profile.scss";
import {connect} from 'react-redux';

import * as authAction from '../../store/authentication/actions';
import {  useHistory } from "react-router-dom";

const Logout = ({ logout}) => {
    const history = useHistory();
    useEffect(() => { 
        logout();
        history.push('/')
	},[])
    

    

// console.log('profile', profile)
    return (
        <MusicDashboard>
        

        </MusicDashboard>
    );
}

function mapStateToProps(state) {
    return {
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
       logout: () => dispatch(authAction.logout())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Logout);