import React , {useState, useEffect} from "react";
import "./MusicDashboard.scss";
import {
  useLocation,
  useParams,
  useRouteMatch
} from "react-router-dom";

//custom components ----------------------------------------------------------------
import NavBar from "../../components/navBar/navBar";

import Header from "../../components/header/header";


import { Scrollbars } from 'react-custom-scrollbars';

import LoginModal from '../../elements/loginModal/loginModal';
import SignUpModal from '../../elements/signUpModal/signUpModal';
import DjAppModal from '../../elements/djAppModal/djAppModal';
import ForgetPasswordModal from '../../elements/forgetPasswordModal/forgetPasswordModal';
import ResetPasswordModal from '../../elements/resetPasswordModal/resetPasswordModal';

import {connect} from 'react-redux';
import * as actions from '../../store/authentication/actions';
import * as customerActions from '../../store/customer/actions';

import LoadingBar from 'react-redux-loading-bar';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

const MusicDashboard = ({children, loggingIn, user, login, getUserProfile, forgetPassword, 
  resetPassword, menuClass, rightContent, signUp, displayLoading, hideLoading, submitDJApp, customerSignUpSuccess
}) => {

  let match = useRouteMatch("/reset-password/:email/:token");
  
  const [show, setShow] = useState(loggingIn ? false : true);
  const [showForget, setShowForget] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [signUpshow, signUpsetShow] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [loginErr, setLoginErr] = useState();
  const [forgetErr, setForgetErr] = useState();
  const [resetErr, setResetErr] = useState();
  const [ responseError, setResponseError ] = useState();
  const [successMessage, setSuccessMessage] = useState('');
  const [navSlide, setNavSlide] = useState(false);
  const [ djAppShow, setDjAppShow ] = useState(false);
  const [ djApplicationSuccess, setDjApplicationSuccess ] = useState(false);

  useEffect(() => { 
      if(match && match.path === '/reset-password/:email/:token'){
        setShow(false);
        setShowResetPassword(true);
      }
  },[match])

  useEffect(() => { 
    setNavSlide(menuClass)
  },[menuClass])


  const displayLogin = () => {
    signUpsetShow(false);
    setShowForget(false);
    setShowResetPassword(false);
    setShow(true);
  }
  
  useEffect(() => { 
    if(loggingIn){
      getUserProfile({token: user}).then(res => {
        // window.location.href= "/";
      })
    }
  },[loggingIn])
 

  const displaySignup = () => {
    setShow(false)
    signUpsetShow(true);
    setDjAppShow(false);
  }

  const displayDjApp = () => {
    setShowForget(false);
    setShow(false);
    signUpsetShow(false);
    setDjAppShow(true);
  }

  const displayForgetPassword = () => {
    setShowForget(true);
    setShow(false);
    signUpsetShow(false);
  }

  const getUserData = async(user) => {
    await getUserProfile({token: user}).then(res => {
      window.location.href= "/";
    })
  }
  
  const processLogin = async(post) => {
    setProcessing(true)
    await login(post).then(res => { 
      // console.log('hi', res.payload.response)
        if(res.payload.response && res.payload.response.status === 401){
          if(res.payload.response.data.message === 'You have not submitted DJ Application.'){
            setProcessing(false);
            setResponseError(res.payload.response.data.message);
            signUpsetShow(false);
            setShow(false);
            setDjAppShow(true);
          } else {
            setLoginErr(res.payload.response.data.message);
          }
        } else if(res.payload.data) {
          // console.log('bye', res.payload.data)
            setLoginErr('');
            localStorage.setItem('user', res.payload.data.token);
            setShow(false);
            getUserData(res.payload.data.token);
        }
        setProcessing(false)
    }).catch(err=>{ 
      // console.log('hello', err)
        setProcessing(false)
    });
    
  }

  const processForgetRequest = async(post) => {
    setProcessing(true);
    await forgetPassword(post).then(res => { 
      // console.log('hi', res.payload.response)
        if(res.payload.response && res.payload.response.status === 401){
          setLoginErr(res.payload.response.data.message);
        } else if(res.payload.data) {
          // console.log('bye', res.payload.data)
            setLoginErr('');
            setShowForget(false);
            setShow(true);
            setSuccessMessage('Please check your email to reset your password')
        }
        setProcessing(false)
    }).catch(err=>{ 
      // console.log('hello', err)
        setProcessing(false)
    });
  }

  const processresetRequest = async(post) => {
    setProcessing(true);
    post.email = match.params.email;
    post.token = match.params.token;
    resetPassword(post).then(res => {
      
      if(res.payload.response && res.payload.response.status === 401){
        setResetErr(res.payload.response.data.message);
      } else if(res.payload.response && res.payload.response.status === 500){
        setResetErr(res.payload.response.data.message.message);
      } else if(res.payload.data) {
          setSuccessMessage('Password changed successfully.')
          setShowResetPassword(false);
          setShow(true);
      }
    });
    setProcessing(false);
  }

  const processRegistration = async(data) => {
    displayLoading(); 
    setProcessing(true);
    setResponseError();
    signUp(data).then(res => {
      if(res && res.payload && res.payload.data === null && res.payload.message && res.payload.message.errors && res.payload.message.errors.length>0){
        let errorsHtml = '<ul>';
        for(var i=0; i < res.payload.message.errors.length; i++){
          errorsHtml += '<li>'+res.payload.message.errors[i]+'</li>';
        }
        errorsHtml += '</ul>';
        setProcessing(false)
        setResponseError(errorsHtml);
      } else {
        setProcessing(false);
        signUpsetShow(!signUpshow);
        setDjAppShow(!djAppShow);
      }
      hideLoading();
    });
  }

  const processDjApp = async(data) => {
    data.token = customerSignUpSuccess.auth_key;
    displayLoading(); 
    setProcessing(true);
    setResponseError();
    submitDJApp(data).then(res => {
      if(res && res.payload && res.payload.data === null && res.payload.message && res.payload.message.errors && res.payload.message.errors.length>0){
        let errorsHtml = '<ul>';
        for(var i=0; i < res.payload.message.errors.length; i++){
          errorsHtml += '<li>'+res.payload.message.errors[i]+'</li>';
        }
        errorsHtml += '</ul>';
        setProcessing(false)
        setResponseError(errorsHtml);
      } else {
        setProcessing(false);
        setDjApplicationSuccess(true);
      }
    })
  }

  return (
    <div className={navSlide ? "App main-page active" : "App main-page"}>
      <LoadingBar className="loading" style={{ backgroundColor: '#ffa800', height: '3px', position: 'fixed', zIndex: '999' }} updateTime={100} maxProgress={95} progressIncrease={10} />
      <LoginModal showState={show}  showSignup={() => displaySignup()} ShowFunction={() => setShow(!show)} login={processLogin} processing={processing} loginErr={loginErr} showForgetPassword={() => displayForgetPassword()} successMessage={successMessage} />
      <SignUpModal showState={signUpshow} showLogin={() => displayLogin()} ShowFunction={() => signUpsetShow(!signUpshow)} processRegistration={processRegistration} responseError={responseError} processing={processing} />
      <DjAppModal  showState={djAppShow} showDjApp={()=> displayDjApp()} ShowFunction={() => setDjAppShow(!djAppShow)} processDjApp={processDjApp} responseError={responseError} processing={processing} djApplicationSuccess={djApplicationSuccess} />
      <ForgetPasswordModal showState={showForget} showLogin={() => displayLogin()} ShowFunction={() => setShowForget(!showForget)} forgetPasswordRequest={processForgetRequest} processing={processing} forgetErr={forgetErr} />
      <ResetPasswordModal showState={showResetPassword} showLogin={() => displayLogin()} ShowFunction={() => setShowResetPassword(!showResetPassword)} resetPasswordRequest={processresetRequest} processing={processing} resetErr={resetErr} />
      <div className="navSideContain">
        <Scrollbars style={{ height: "100%" }}>
          <NavBar />
        </Scrollbars>
      </div>
      <div className="pageContain d-flex">
        <div className="midContent flex-grow-1">
        {/* <div>
          <span onClick={() => setShow(!show)}>Login</span>
          <span onClick={() => signUpsetShow(!signUpshow)}>SignUp</span>
        </div> */}
          <Header />
            {children}
        </div>
        {/* <div className="rightSideList">
          <RightSide />
        </div> */}
        {!rightContent ? '' :
        <div className="rightSideList">
          {rightContent}
        </div>
        }
      </div>
    </div>
  );
};


function mapStateToProps(state) {
  
    if (state.authentication.loggedIn !== undefined && state.authentication.loggedIn) {
       // window.location.href= "/";
    }
   
    return {
      loggingIn: state.authentication.loggedIn,
      user: state.authentication.user,
      useLocation: state.location,
      menuClass: state.mobilemenu.menuClass,
      customerSignUpSuccess: state.customer.customerSignUpSuccess
    };
      
}
 
 function mapDispatchToProps(dispatch) {
  return {
    login: (post) => dispatch(actions.signIn(post)),
    getUserProfile: (token) => dispatch(customerActions.getCustomerProfile(token)),
    forgetPassword: (post) => dispatch(customerActions.forgetPassword(post)),
    resetPassword: (post) => dispatch(customerActions.resetPassword(post)),
    signUp: (post) => dispatch(customerActions.signUp(post)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    submitDJApp: (post) => dispatch(customerActions.submitDJApp(post))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(MusicDashboard);
