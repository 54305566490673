import React  from "react";


import AccountNav from "../../elements/accountNav/accountNav";

import PaymentFailed from "../../assets/img/paymentFailed.png";
import Thankyou from "../../assets/img/thankyou-icon.png";
import payPal from "../../assets/img/paypal.svg";
import creditCard from "../../assets/img/credit-card.svg";
import appleGooglePayIcon from "../../assets/img/apple-google-icon-png.png";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

import "./subscription.scss";


import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";


const FailedPayment = ({displayLoading, hideLoading}) => {

    let match = useRouteMatch("/subscription/choose-payment/:plan_id");
    const location = useLocation();
    const history = useHistory();

    
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">                    
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Card className="border-0 text-center">
                                <Card.Body>
                                    <img src={PaymentFailed} />
                                    <h3 className="mt-4">Payment Failed</h3>
                                    <p>Don’t worry! You can update your payment and try again.To keep Premium Membership, you may need to update your payments details</p>
                                    {/* <a href="#" className="btn btn-light primary-bg btn-block p-3 btn btn-light">update payment details</a> */}
                                    <Link to={`/account/manage-subscription`} className="btn btn-light primary-bg btn-block p-3 btn btn-light">update payment details</Link>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> 
                </Container>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FailedPayment);