import React, { useState, useEffect, useRef } from "react";
// import ReactPlayer from 'react-player';

import ReactJkMusicPlayer from "react-jinke-music-player";
import "react-jinke-music-player/assets/index.css";

import { Scrollbars } from 'react-custom-scrollbars';

import Slider from "react-rangeslider";

import "./player.scss";
import AlbumImg from "../../assets/img/album-img.png";
import DownloadVersionButton from '../../elements/actionBtn/downloadVersion';
import FavouriteButton from '../../elements/actionBtn/addToFavourite';

import {connect} from 'react-redux';
import * as actions from '../../store/playlist/actions';
import * as songActions from '../../store/song/actions';
import * as downloadActions from '../../store/download/actions';

// import Waveform from 'waveform-react';
import { current } from "immer";

// import Waveview from "./waveview";
import WaveSurfer from 'wavesurfer.js';

const Player = ({playLists, downloadSongVersion, userProfile, currentSong, setCurrentSong, setIsPlaying, addSongToCrate, crateSongs, playListIndex, isPlaying, setPlayListIndex}) => {

    const [playState, updatePlayState] = useState(true);
    const [playSong, updatePlaySong] = useState([]);
    const [volSet, updateVolSet] = useState(false);
    const [currentSongIndex, setCurrentSongIndex] = useState();
    const [showPlayList, setShowPlayList] = useState(false);
    const [songDuration, setSongDuration] = useState();
    const [repeatSong, setRepeatSong ] = useState(false);
    const [ shuffleSong, setShuffleSong ] = useState(false);
     
    
    

    const [volume, updateVolume] = useState(1);
    const [loopState, updateLoopState] = useState(false);
    const [audioInstance, UpdateAudioInstance] = useState(null);
    const [progressTime, setProgressTime] = useState('00:00');
    const [songTime, setSongTime] = useState('00:00');

    let playListRef = useRef();

    useEffect(() => {
      document.addEventListener("mousedown", (event) => {
        if(!playListRef.current.contains(event.target)){
          setShowPlayList(false);
        }
      })
    })

    useEffect(() => {
		if(playLists && playLists.length > 0){
			displayWaves(playLists[playListIndex].musicSrc);
			audioInstance.playByIndex(playListIndex);
			setCurrentSong(playLists[playListIndex]);
			setCurrentSongIndex(playListIndex)
		}
	},[playListIndex])
	

    useEffect(() => { 
		if(audioInstance && !isPlaying){
			audioInstance.pause();
		} else if(audioInstance && isPlaying){
			audioInstance.play();
		}
    },[isPlaying])

    
    useEffect(() => {
		if(playLists.length > 1){
			const playIndex = playLists.length -1;
			audioInstance.playByIndex(playIndex);
			// console.log('curr', currentSong)
		}
      
      	if(playLists != null && playLists.length > 0){
			displayWaves(playLists[playLists.length-1].musicSrc);
			setCurrentSong(playLists[playLists.length-1]);
			setCurrentSongIndex((playLists.length-1));
      	}
    },[playLists])

    const playSongFromPlayList = (playIndex) => {
		displayWaves(playLists[playIndex].musicSrc);
		audioInstance.playByIndex(playIndex);
		setCurrentSong(playLists[playIndex]);
		setCurrentSongIndex(playIndex)
    }

    const displayWaves = (musicSrc) => {
      
		document.getElementById('waveform').innerHTML = '';
		var wavesurfer = WaveSurfer.create({
			container: '#waveform',
			// waveColor: 'violet',
			// progressColor: 'purple',
			height: 80,
			progressColor: '#FF0000',
			responsive: true,
			waveColor: '#d4d4d4',
			cursorColor: '#4a74a5',
		}); 
		wavesurfer.load(musicSrc);
    }

    const changeSongTime = (event) => {
		var obj = document.getElementById('waveform');
		var objWidth = obj.offsetWidth / songDuration;
		const clickedTime  = Math.floor((event.clientX-obj.offsetLeft)  / objWidth);
		audioInstance.currentTime = clickedTime;
    }

    

    const volShow = () => {
      	updateVolSet(!volSet);
    };

    const volChange = (value) => {
		audioInstance.volume = value;
		updateVolume(value);
    };

    const addToCrate = () => {
      	addSongToCrate(currentSong.id);
    }

    const downloadCurrentSong = () => {
      	downloadVersion(currentSong.songData, currentSong.versionData)
    }

    const downloadSong = (version, songData) => {
		downloadSongVersion({ version: version}).then(res => {
			if(res.payload.response && res.payload.response.status === 403){
				alert(res.payload.response.data.message);
			} else if(res.payload.data) {
				const versionData = res.payload.data;
				const downloadFilename = versionData.uploaded_mp3_name;
				const url = songData.song_section_id == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
				var downloadurl = `https://www.clubkillers.com/neverbeendownload.php?url=${url}&file=${downloadFilename}`;
				window.location.assign(downloadurl);
				// CAll parent function
			}
		})
  	}

	const downloadVersion = (song, version) => {
		if(userProfile && userProfile.group_id == 2){
			if(userProfile.payment_profile_status == 'paid'){
				downloadSong(version, song)
			}
		}
	}

    const updateProgressBar = (audioInfo) => {
		const currentSec = Math.floor(audioInfo.currentTime);
		setProgressTime(formatTimer(currentSec))
		setSongTime(formatTimer(audioInfo.duration));
		setSongDuration(Math.floor(audioInfo.duration))
		const width = ((audioInfo.currentTime/audioInfo.duration) * 100);
		var e1 = document.getElementById('waveform').lastChild;
		var e2 = e1.firstChild;
		e2.style.width= width+'%';
      
    }

    const formatTimer = (time) => {   
		// Hours, minutes and seconds
		var hrs = ~~(time / 3600);
		var mins = ~~((time % 3600) / 60);
		var secs = ~~time % 60;
	
		// Output like "1:01" or "4:03:59" or "123:03:59"
		var ret = "";
		if (hrs > 0) {
			ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
		}
		ret += "" + mins + ":" + (secs < 10 ? "0" : "");
		ret += "" + secs;
		return ret;
    }

    const playPrev = () => {
		setIsPlaying(true); // set play song
		audioInstance.playPrev();
		let prevIndex = currentSongIndex - 1;
		if(currentSongIndex > 0){
			setCurrentSongIndex(prevIndex);
		} else {
			prevIndex = (playLists.length - 1);
			setCurrentSongIndex(prevIndex);
		}
		setCurrentSong(playLists[prevIndex]);
		displayWaves(playLists[prevIndex].musicSrc)
    }

    const playNext = () => {
		setIsPlaying(true); // set play song
		let nextIndex  = currentSongIndex + 1;
		if(playLists.length > nextIndex){
			setCurrentSongIndex(nextIndex);
		} else {
			nextIndex = 0;
			setCurrentSongIndex(nextIndex);
		}
		setCurrentSong(playLists[nextIndex]);
		displayWaves(playLists[nextIndex].musicSrc);
		audioInstance.playNext();
    }

    const playPauseSong = () => {
      
		if(audioInstance && !isPlaying){
			audioInstance.play();
		} else if(audioInstance && isPlaying){
			audioInstance.pause();
		}
    }


    let options = {
		// audio lists model
		audioLists: playLists ? playLists : [],
		autoPlay: true,
		defaultVolume: volume,
		defaultPlayIndex: 0
    };

    return (
      <>
      <ReactJkMusicPlayer
        {...options}
        getAudioInstance={(audio) => {
          	UpdateAudioInstance(audio);
        }}
        onAudioPlay={(playMode) => {
			updatePlayState(playMode.paused);
			updatePlaySong(playMode);
			setIsPlaying(true);
        }}
        onAudioPause={(playMode) => {
			updatePlayState(playMode.paused);
			setIsPlaying(false)
        }}
        onAudioProgress={(audioInfo) => {
			// updateAudioProgress(audioInfo);
			updateProgressBar(audioInfo);
        }}
        onAudioPlayTrackChange={(currentPlayId, audioLists, audioInfo) => {
          	// console.log(audioLists);
        }}
        onAudioEnded={(currentPlayId,audioLists,audioInfo) => {
			if(repeatSong){
				audioInstance.load();
			} else if(shuffleSong){
				const playIndex = Math.floor(Math.random() * audioLists.length);
				playSongFromPlayList(playIndex);
				audioInstance.load()
			}
        }}
        
      />

      <div className="player position-fixed d-flex align-items-center">
        <div className="songInfo d-flex align-items-center">
          <div className={"img " + (playState ? "" : "animate")}>
            <img src={playSong.length != 0 ? playSong.cover : AlbumImg} />
          </div>
          <div className="infoText">
            <h5 className="text-truncate">{playSong.name}</h5>
            <div className="creator">{playSong.singer}</div>
          </div>
          <div className="ml-5 actionLink text-nowrap">
            {/* <span onClick={() => addToCrate()} className={ crateSongs && crateSongs.length > 0 && currentSong && ((crateSongs.indexOf(currentSong.id.toString()) > -1) ) ? 'active pointer' : 'pointer'}>
              <i className="lnir lnir-heart" ></i>
			</span> */}
			{currentSong ?  <FavouriteButton song={currentSong.songData} /> : <span className="pointer" ><i className="lnir lnir-heart" ></i></span> }
			{currentSong ? <DownloadVersionButton song={currentSong.songData} version={currentSong.versionData} /> : <span className="pointer"><i className="lnir lnir-download"></i></span> }
            <span className="pointer" onClick={() => setShowPlayList(!showPlayList)}>
              <i className="lnir lnir-playlist"></i>
            </span>
            <div className={ playLists.length > 0 && showPlayList ? "playListBox" : "playListBox playListBoxHide"} ref={playListRef}>
            <Scrollbars style={{ height: "100%" }} >
              {
                playLists.map((list, index) => (
                  <div key={index} className="playItem d-flex" onClick={()=> playSongFromPlayList(index)}>
                    <aside className="iconSide">
                      <i className={currentSong && currentSong.version == list.version && playState == false ? "lnir lnir-pause playBox": "lnir lnir-play playBox" }></i>
                    </aside>
                    <aside>
                      <div className="name">{list.name} </div>
                      <div className="singer">{list.singer} {list.version} {currentSong.version}</div>
                    </aside>
                  </div>
                ))
              }
              </Scrollbars>
            </div>
          </div>
        </div>
        <div className="playControl d-flex align-items-center mr-auto">
          <span className={shuffleSong ? "active" : ""} onClick={() => setShuffleSong(!shuffleSong)}>
            <i className="lnir lnir-shuffle"></i>
          </span>
          <span onClick={() => playPrev()}>
            <i className="lnir lnir-backward"></i>
          </span>
          <span
            onClick={() => playPauseSong()}
            className="songState pause"
          >
            {playState ? (
              <i className="play-icon"></i>
            ) : (
              <i className="pause-icon"></i>
            )}
          </span>
          <span onClick={() => playNext()}>
            <i className="lnir lnir-forward"></i>
          </span>
          <span className={repeatSong ? "active" : "" } onClick={()=> setRepeatSong(!repeatSong)}>
            <i className="lnir lnir-repeat-one"></i>
          </span>
        </div>
        <div className="timer ml-4 mr-4 wave-outer-width" >
          {progressTime} / {songTime}
          <div id="waveform" className="wave-width" onClick={(e) => changeSongTime(e)}></div>
        </div>
        <div className="sound ml-4" onClick={() => volShow()}>
          {volume == 0 ? (
            <i className="lnir lnir-volume-mute"></i>
          ) : (
            <i className="lnir lnir-volume-high"></i>
          )}
        </div>
        <div className={"volSlider " + (volSet ? "active" : "")}>
			<Slider
				value={volume}
				orientation="vertical"
				onChange={volChange}
				min={0}
				max={1}
				step={0.1}
				format={(value) => {
				return Math.ceil(value * 10);
				}}
			/>
        </div>
        <div
          className={"volDroper " + (volSet ? "active" : "")}
          onClick={() => volShow()}
        ></div>
      </div>
    </>
    )
  
}

function mapStateToProps(state) {
  return {
    playLists: state.playlist.playListSongs,
    currentSong: state.song.currentSong,
    isPlaying: state.song.isPlaying,
    crateSongs: state.song.customerCurates,
    userProfile: state.customer.customerProfile,
    playListIndex: state.playlist.playListIndex
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getPlayList: () => dispatch(actions.getPlayList()),
    setCurrentSong: (song) => dispatch(songActions.setCurrentSong(song)),
    setIsPlaying: (bool) => dispatch(songActions.setIsPlaying(bool)),
    addSongToCrate: (songId) => dispatch(songActions.addSongToCrate(songId)),
    downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
    setPlayListIndex: (index) => dispatch(actions.setPlayListIndex(index))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Player);