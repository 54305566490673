import React from "react";
import "./carouselItem.scss";

import Slider from "react-slick";


const settings = {
  className: "slider",
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 6,
  slidesToScroll: 5,
  // variableWidth: true,
  arrows: true
};

const CarouselItem = (props) =>  {
  return(
    <div>
        <div className="d-flex align-items-baseline sectionTitleHead">
          <h3 className="text-truncate text-uppercase">{props.title}</h3>
          <div className="pl-5">
            {props.updatedDate != '' ? (<div><span className="text-muted">Last update on</span> {props.updatedDate}</div>) : ''}
          </div>
        </div>
        <div className="sliderBoxFixed">

        <Slider {...settings}>

          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}            
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6><a href='#'>March 2021</a></h6>
            {props.price != '' ? (<div className="price">{props.price}</div>) : ''}
          </div>
          </Slider>
    </div>
    </div>
      )
  
}

export default CarouselItem;
