import React, {useState, useEffect} from "react";
import Form from 'react-bootstrap/Form'
import AccountNav from "../../elements/accountNav/accountNav";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button'

import "./changePassword.scss";

import {connect} from 'react-redux';
import * as customerAction from '../../store/customer/actions'

const ChangePassword = ({customer, changePasswordRequest, customerError, customerSuccess}) => {

    const [formData, setFormData] = useState({
        current_password: '',
        password: '',
        confirm_password: ''
    });
    const [ errors, setErrors ] = useState({})
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    
    // const [submited, setSubmited] = useState(false);
  
    const [validated, setValidated] = useState(false);
  
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      });
  
      
    }
  
    const findFormErrors = () => {
      const { current_password, password, confirm_password, } = formData
      const newErrors = {}
      
      // password errors
      if ( !current_password || current_password === '' ) newErrors.current_password = 'current password cannot be blank!'
      if ( !password || password === '' ) newErrors.password = 'password cannot be blank!'
      // confirm password errors
      if ( !confirm_password || confirm_password === '' ) newErrors.confirm_password = 'confirm password cannot be blank!'
      else if ( confirm_password != password ) newErrors.confirm_password = 'password mismatch'
  
      return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
    
        if ( Object.keys(newErrors).length > 0 ) {
            console.log(newErrors)
          // We got errors!
          setErrors(newErrors)
        } else {
          changePasswordRequest(formData);
        }
    }

    return(
    <MusicDashboard>
        <div className="d-flex align-items-baseline sectionTitleHead mb-4">
            <h3 className="text-truncate text-uppercase">Change Password</h3>
        </div>
        <AccountNav/>        
        <div className="content-box">
            <Card className="border-0 profileEditCard">
                <Card.Body>
                    {customerError ? <div class="alert alert-danger">{customerError}</div> : ''}
                    {customerSuccess ? <div class="alert alert-success">{customerSuccess}</div> : ''}
                    <Form  validated={validated} onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formCurrentPassword" >
                            <Form.Label>Current Password</Form.Label>
                            <Form.Control type="password" placeholder="Current Password" name="current_password"  onChange={handleChange} isInvalid={ !!errors.current_password } />
                            <Form.Control.Feedback type="invalid">
                                { errors.current_password }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formNewPassword" >
                            <Form.Label>New Password</Form.Label>
                            <Form.Control type="password" placeholder="New Password" name="password"  onChange={handleChange} isInvalid={ !!errors.password } />
                            <Form.Control.Feedback type="invalid">
                                { errors.password }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formConfirmPassword" >
                            <Form.Label>Confirm Password</Form.Label>
                            <Form.Control type="password" placeholder="Confirm Password" name="confirm_password"  onChange={handleChange} isInvalid={ !!errors.confirm_password } />
                            <Form.Control.Feedback type="invalid">
                                { errors.confirm_password }
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button variant="primary"  full-width type="submit">
                            Change Password
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        authentication: state.authentication,
        customerError: state.customer.customerError,
        customerSuccess: state.customer.customerSuccess
    };
}

function mapDispatchToProps(dispatch) {
    return {
        changePasswordRequest: (post) => dispatch(customerAction.changePassword(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);