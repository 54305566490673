import React, { useEffect, useState} from "react";

//custom components

import RightSide from "../../components/rightSide/rightSide";

import loadingImage from '../../assets/img/loading.gif'
import Announcement from "../../elements/Announcement/announcement";

import { Link } from "react-router-dom";

import "./genres.scss";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as genreActions from '../../store/genres/actions';


const  Genres = ({getGenresWithCount, genresWithCount, displayLoading, hideLoading}) => {

  const [dataProcessing, setDataProcessing] = useState();

  // useEffect(() => { 
  //   getGenresWithCount();
  // },[]);


  useEffect(() => { 
    getData()
  },[]);

  const getData = async() => {
    displayLoading();
    await getGenresWithCount();
    hideLoading();
  }

  return (
    <MusicDashboard rightContent={<RightSide blocks={{mostDownloaded: true, trendingTracks: true }} />}>
      <Announcement page_name="mp3_genres" />
      <div>
        <div className="d-flex align-items-baseline sectionTitleHead mb-3">
          <h3 className="text-truncate text-uppercase">Moods</h3>
        </div>
        <div className="d-flex flex-wrap mb-4">
        {
          genresWithCount && genresWithCount.length > 0 ?
            genresWithCount.map((genre, index) => {
              return <div className="albumGroup" key={`genresimage-${genre.genre_id}`} style={{width: '15%'}}>
                      <Link to={`/genres/view/${genre.genre_id}`}><figure style={{backgroundImage: `url(${genre.image})`, backgroundSize: 'cover'}}></figure></Link>
                      <h6>
                        <Link to={`/genres/view/${genre.genre_id}`} dangerouslySetInnerHTML={{__html: genre.name}}></Link>
                      </h6>
                    </div>
                    // {(index+1)%6 == 0 ? </div></div><div className="d-flex mb-4"> : ''}
            })
          : ''
          }
        </div>
        {/* <div className="d-flex mb-4">
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Romance</a>
            </h6>
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Feeling Blue</a>
            </h6>
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Motivation</a>
            </h6>
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Party</a>
            </h6>
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Wellness</a>
            </h6>
          </div>
          <div className="albumGroup">
            <figure></figure>
            <h6>
              <a href="#">Moods</a>
            </h6>
          </div>
        </div> */}
        <div className="d-flex align-items-baseline sectionTitleHead mb-4 mt-5">
          <h3 className="text-truncate text-uppercase">Genres</h3>
        </div>
        <div className="row">
        {
          genresWithCount && genresWithCount.length > 0 ?
            genresWithCount.map((genre, index) => {
              return <aside className="col-md-4" key={`maingenre-${genre.genre_id}`}>
                <div className="genresList mb-5">
                  <h6 className="text-uppercase" dangerouslySetInnerHTML={{__html: genre.name}}></h6>
                  <ul>
                    {
                      genre.subgenre && genre.subgenre.length > 0 ?
                        genre.subgenre.map((subgenre, subindex) => {
                           return <li key={`subgenre-${subgenre.genre_id}`}><Link to={`/genres/view/${subgenre.genre_id}`}><span dangerouslySetInnerHTML={{__html: subgenre.name}}></span> <span>{subgenre.song_count}</span></Link></li>
                        })
                    
                     : '' 
                    }
                  </ul>
                </div>
              </aside>
            })
          : ''
        }
        </div>
      </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    genresWithCount: state.genre.genresWithCount,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Genres);