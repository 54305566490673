import React, { useState, useEffect } from "react";
import "./playgroup.scss";

import AlbumImg from '../../assets/img/album-img.png'
import DownloadVersionButton from '../actionBtn/downloadVersion';
import FavouriteButton from '../actionBtn/addToFavourite';

import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import config from '../../config/config';

const playGroupPlaylist = ({ songs}) =>  {
  
  return(
    <div>
        {
          songs && songs.length > 0 ? 
          songs.map( (song, index) => {
        return <div className="playGroup"  >
        {/* key={`homesongs${song.entity_id}`} */}
          <div className="d-flex align-items-center topView flex-wrap">
            <div className="count">{index+1}</div>
            <div className="info d-flex align-items-center">
              <div className="img">
                <figure>
                  <img src={AlbumImg}  />
                </figure>
              </div>
              <aside>
                <div className="musicTitle text-truncate">{song.songData.title}</div>
                <div className="musicCreator">{song.songData.artist}</div>
              </aside>
            </div>
            <div className="action ml-auto text-nowrap">
             
              <FavouriteButton song={song.songData} />
							<DownloadVersionButton song={song.songData} version={song.versionData} />
              
            </div>
            <div className="d-flex align-items-baseline justify-content-around infoMid">
              <div className="songType">
                <div className="text-nowrap"><span>BPM:</span> {song.songData.min_bpm}</div>
                <div className="text-nowrap"><span>Key:</span> {song.songData.song_key}</div>
              </div>
              <div className="createDate">
                <span>Date</span>
                <div className="text-nowrap">{song.songData.created_at}</div>
              </div>
              {/* <div className="genre">
                <span>Genre</span>
                <div>Mainstream / hip hop 
                </div>
              </div> */}
              <div className="version">
                <span>Version</span>
                <div className="text-nowrap">{song.versionData.version_label}</div>
              </div>
            </div>
          </div>
          
        </div>
          })
          : ''
        }
    </div>
      )
  
}

function mapStateToProps(state) {
  return {
    crateSongs: state.song.customerCurates,
    playLists: state.playlist.playListSongs,
    currentSong: state.song.currentSong,
    userProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
    addSongVersionToPlayList: (song, version) => dispatch(playlistActions.addSongVersionToPlayList(song, version)),
    getPlayList: () => dispatch(playlistActions.getPlayList()),
    downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
    downloadBundleSong: (data) => dispatch(downloadActions.downloadBundleSong(data)),
    setPlayListIndex: (index) => dispatch(playlistActions.setPlayListIndex(index)),
    getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(playGroupPlaylist);
