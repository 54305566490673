import React, { useState, useEffect } from "react";

import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as playlistActions from '../../store/playlist/actions';
import * as songActions from '../../store/song/actions';

const PlayPauseButton = ({ addSongVersionToPlayList, setPlayListIndex, currentSong, isPlaying, song, version, playLists, setIsPlaying, setActiveAlbumSong }) => {

    const [ firstVersionAdded, setFirstVersionAdded ] = useState(0);
    // const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
    const checkIfVersionAlreadyExist = (version) => {
		var hasMatch = 0;
		for (var index = 0; index < playLists.length; ++index) {
		    var songs = playLists[index];
            if(songs.version == version){
                hasMatch = index;
                break;
            }
        }
        console.log('hasMatch', hasMatch)
		return hasMatch;
    }
    

    const playPauseSong = (song, version) => {
        setIsPlaying(!isPlaying); 
		// console.log('playpause', currentSong, song, version);
        const songIndex = checkIfVersionAlreadyExist(version.song_version_id);
        if(playLists.length == 0){
            setFirstVersionAdded(version.song_version_id);
        }
		if(!songIndex){
            
			if(firstVersionAdded === version.song_version_id && playLists.length == 0){
				addSongVersionToPlayList(song, version);
			} else if(firstVersionAdded !== version.song_version_id){
				addSongVersionToPlayList(song, version);
            } 
            else {
				setPlayListIndex(songIndex);
			}
			
		} else {
			setPlayListIndex(songIndex);
		}
		// setActiveAlbumSong(true);
	   
	}

    
    return (
        <i className={currentSong && currentSong.version && version && currentSong.version == version.song_version_id && isPlaying ? "pause-icon ico": "play-icon ico"} onClick={() => playPauseSong(song, version)}></i>
    )
   

}
function mapStateToProps(state) {
    return {
        playLists: state.playlist.playListSongs,
        currentSong: state.song.currentSong,
        isPlaying: state.song.isPlaying,
        playLists: state.playlist.playListSongs,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongVersionToPlayList: (song, version) => dispatch(playlistActions.addSongVersionToPlayList(song, version)),
        setPlayListIndex: (index) => dispatch(playlistActions.setPlayListIndex(index)),
        setIsPlaying: (bool) => dispatch(songActions.setIsPlaying(bool)),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PlayPauseButton);
  