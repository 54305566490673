import React from "react";
import "./accountNav.scss";

import { NavLink } from 'react-router-dom'
import {connect} from 'react-redux';

const AccountNav = ({userProfile}) =>  {
  console.log(userProfile, 'accountna')
  return(
    <div>
          <ul className="nav flex-row AccountNav">
            {/* <li><Link to={``}><i className="lnir lnir-layout-alt-1"></i> Overview</Link></li> */}
            <li><NavLink activeClassName="active" to={`/account/update`}><i className="lnir lnir-user"></i> Edit Profile</NavLink></li>
            <li><NavLink activeClassName="active" to={`/account/change-password`}><i className="lnir lnir-lock"></i> Change Password</NavLink></li>
            { 
              userProfile && userProfile.payment_profile_status === 'unpaid' ? 
                <li><NavLink activeClassName="active" to={`/subscription/plans`}><i className="lnir lnir-coin"></i> Create Subscription</NavLink></li>
               :
               <li><NavLink activeClassName="active" to={`/account/manage-subscription`}><i className="lnir lnir-coin"></i> Subscription</NavLink></li>
            }
            { 
              userProfile && userProfile.payment_profile_status === 'unpaid' ? ''
               :
               <li><NavLink activeClassName="active"v to={`/account/billing`}><i className="lnir lnir-dollar-card"></i> Billing History</NavLink></li>
            }
            { 
              userProfile && userProfile.payment_profile_status === 'unpaid' ? ''
               :
                <li><NavLink activeClassName="active" to={`/download-rescue`}><i className="lnir lnir-download"></i> Download Rescue</NavLink></li>
            }
            <li><NavLink activeClassName="active" to={`/support/faqs`}><i className="lnir lnir-life-ring"></i> Help Desk</NavLink></li>
          </ul>
        </div>
      )
  
}


function mapStateToProps(state) {
  return {
    userProfile: state.customer.customerProfile
  };
}

function mapDispatchToProps(dispatch) {
  return {
  };
 }
export default connect(mapStateToProps, mapDispatchToProps)(AccountNav);