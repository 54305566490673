import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Song = {
    getHomePageGenreSong,
    getNewRelease,
    getTop20Albums,
    addSongToCrate,
    getCustomerCrates,
    renewSong,
    createSong,
    getSongEntry,
    updateSong,
    deleteSong,
    getSearchResultSongs,
    getChartSongs,
    addSongToFavourite,
    getCustomerFavouriteSongs,
    getFavouriteSongs
}

function getHomePageGenreSong(data){
    const url = 'song/get-genres-song.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
    return res;       
    })
    .catch(err => { 
    // alert(err.response.data.message)
    return err; 
    });
}

function getNewRelease(data){
    const url = 'song/get-new-release.json?store_id=1&lang_code=en';
    data.token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getTop20Albums(){
    const url = 'top20/get-tracks.json?store_id=1&lang_code=en';
    return axiosReqGet()
            .get(url)
            .then(res => {
            return res; 
        })
        .catch(err => { 
            // alert(err.response.data.message)      
            return err; 
        })
}

function addSongToCrate(songId, versionId){
    const url = 'crate/add-remove.json?store_id=id=1&lang_code=en';
    const data = {
        token: USER_TOKEN,
        songId: songId,
        versionId: versionId
    }
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function addSongToFavourite(songId){
    const url = 'favourite-song/add-remove.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN,
        songId: songId
    }
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getCustomerFavouriteSongs(){
    const url = 'favourite-song/index.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getFavouriteSongs(songId){
    const url = 'favourite-song/get-favourite-songs.json?store_id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getCustomerCrates(){
    const url = 'crate/get-crates.json?store_id=id=1&lang_code=en';
    const data = {
        token: USER_TOKEN
    }
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function renewSong(data){
    const url = 'song/renew-song.json?store_id=1&lang_code=en';
    data.token= USER_TOKEN;
    
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function createSong(data){

    const url = 'song/create-song.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getSongEntry(data){
    const url = 'song/get-song.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function updateSong(data){
    const url = 'song/update-song.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function deleteSong(data){
    const url = 'song/delete-song.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getSearchResultSongs(data){
    const url = 'song/search.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

function getChartSongs(data){
    const url = 'song/chart.json?store_id=1&lang_code=en';
    data .token = USER_TOKEN;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}

export default Song;