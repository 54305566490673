import React, {useEffect, useState} from "react";



import AccountNav from "../../elements/accountNav/accountNav";


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';



import "./helpdesk.scss";
import SubmitTicket from "./submitTicket";
import RecentTickets from "./recentTickets";
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import * as faqAction from '../../store/faq/actions';
import * as helpdeskAction from '../../store/helpdesk/actions'

const Helpdesk = ({getFAQS, displayLoading, hideLoading, faqs, recentTickets}) => {

  

  useEffect(() => { 
		// load Page Data
		loadPageData();
  },[]);

  const loadPageData = async() => {
		displayLoading();
    await getFAQS();
		hideLoading();
  }
  
return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Helpdesk</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
        <Container fluid className="p-0">
          <Row>
            <Col lg={7}>
            <Card className="border-0">
              <Card.Body>
                <Row className="ticket-summary">
                  <Col>
                    <div className="text-muted text-uppercase">Your Ticket Inquries</div>
                    <div className="ticket-number tt"><Link to={`/tickets/open`}>{recentTickets && recentTickets.summary ? recentTickets.summary.total : 0}</Link></div>
                    <div>Tickets</div>
                  </Col>
                  <Col>
                    <div className="text-muted text-uppercase">Open Ticket</div>
                    <div className="ticket-number to"><Link to={`/tickets/open`}>{recentTickets && recentTickets.summary ? recentTickets.summary.open : 0}</Link></div>
                    <div>Tickets</div>
                  </Col>
                  <Col>
                    <div className="text-muted text-uppercase">Close Ticket</div>
                    <div className="ticket-number tc"><Link to={`/tickets/close`}>{recentTickets && recentTickets.summary ? recentTickets.summary.close : 0}</Link></div>
                    <div>Tickets</div>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
              <Card className="border-0 mt-4 faqList">
                <Card.Body>
                  <h5><b>FREQUENTLY ASKED QUESTIONS</b></h5>
                  {
                    faqs && faqs.faqs && faqs.faqs.length > 0 ? 
                    <Accordion> 
                      {
                      faqs.faqs.map((faq, index) => {
                        return <Card key={`faq-${index+1}`}>
                            <Accordion.Toggle as={Card.Header} eventKey={index+1} dangerouslySetInnerHTML={{__html:faq.question}}></Accordion.Toggle>
                            <Accordion.Collapse eventKey={index+1}>
                              <div dangerouslySetInnerHTML={{__html:faq.answer}}></div>
                            </Accordion.Collapse>
                        </Card>
                        
                      })
                    }
                    </Accordion>
                    : ''
                  }
                </Card.Body>
              </Card>
            </Col>
            <Col lg={5}>
              <RecentTickets />
              <div className="mt-4">
              <SubmitTicket />
              </div>
            </Col>
          </Row>
        </Container>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
  return {
    faqs: state.faq.faqs,
    recentTickets: state.helpdesk.recentTickets
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getFAQS: () => dispatch(faqAction.getFAQS()),
      displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
      hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Helpdesk);