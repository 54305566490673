import React, {useEffect, useState} from "react";


import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";

import PaymentFailed from "../../assets/img/paymentFailed.png";
import Thankyou from "../../assets/img/thankyou-icon.png";
import payPal from "../../assets/img/paypal-logo-png-transparent.png";
import creditCard from "../../assets/img/card.png";
import wallet from "../../assets/img/wallet.png";
import appleGooglePayIcon from "../../assets/img/apple-google-icon-png.png";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import Form from 'react-bootstrap/Form'
import NumberFormat from 'react-number-format';

import "./subscription.scss";

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import * as couponAction from '../../store/coupon/actions';
import InputMask from 'react-input-mask';

const PaymentMethod = ({getServices, displayLoading, hideLoading, plans, validateCoupon, getCustomerAgreement, agreement, processCardPaymentMethod, processPaypalPayment, getPlanDetails}) => {

    let match = useRouteMatch("/subscription/choose-payment/:plan_id");
    const location = useLocation();
    const history = useHistory();

    const [ planId, setPlanId ] = useState();
    const [ subscriptionData, setSubscriptionData ] = useState();
    const [ paymentMethod, setPaymentMethod ] = useState();
    const [ couponCode, setCouponCode ] = useState();
    const [ coupon, setCoupon ] = useState();
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [formData, setFormData] = useState({
        cardholder_name: '',
        card_number: '',
        card_type: '',
        expire_date: '',
        ccv: '', 
        payment_method: '', 
        terms_condition: ''
    });
    const visaTypeOptions = [
        {label: "Visa", value: "Visa"},
        {label: "MasterCard", value: "MasterCard"},
        {label: "Discover", value: "Discover"},
        {label: "Amex", value:"Amex"}
    ];
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [ couponApplied, setCouponApplied ] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState();
    const [value, setValue] = useState();

    useEffect(() => { 
        
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => { 
		if(match.params.plan_id){
            setPlanId(match.params.plan_id)
        }else{
            history.push("/subscription/plans");
        }
    },[location]);

    
    if(agreement && agreement.profile_status == 'CREATED'){
        history.push('/account/manage-subscription');
    }
    
	const loadPageData = async() => {
   		displayLoading();
        await getServices({subscription_type_id: 1}).then((res) => {
            if(res && res.payload && res.payload.data && res.payload.status == 'true'){
                // console.log('res', res)
                setSelectedPlan(res.payload.data.find(  service  => service.id ===  match.params.plan_id.toString()));
                
            }
        });
        await getCustomerAgreement();
		hideLoading();
    }
    
    const handleChangePayment = (event) => {
        const { name, value } = event.target;
        setPaymentMethod(value);
        setSubscriptionData({
            ...subscriptionData,
            payment_method: value
        })
    }

    const setPromoCode = (event) => {
        const { name, value } = event.target;
        setCouponCode(value)
    }


    const processPayment = async (formData) => { 
        displayLoading();
        if(paymentMethod && paymentMethod == 'credit_card') {
            await processCardPaymentMethod({formData: formData, coupon: coupon,  subscription: selectedPlan});
            hideLoading();
        } else if(paymentMethod && paymentMethod == 'paypal') {
            let purchasePlan = selectedPlan;
            purchasePlan.payment_method = paymentMethod;
            
            await processPaypalPayment({subscription: purchasePlan, coupon: coupon}).then((res) => {
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    hideLoading();
                    window.location.href = res.payload.data.paypal;
                }
            });
        } else if(paymentMethod && paymentMethod == 'mobile_wallet') {
            await getPlanDetails({coupon: coupon,  subscription: selectedPlan}).then(res => {
                hideLoading();
                history.push(`/subscription/mobile-wallet/${planId}`)
            })
        }
    }

    const checkCoupon = async() => {
        if(couponCode){
            // call Validate coupon code
            await validateCoupon({coupon_code: couponCode, subscription_id: planId}).then((res)=>{
                if(res && res.payload && res.payload.data && res.payload.data.status && res.payload.status == 'true'){
                    setCoupon(res.payload.data);
                    setCouponApplied(true)
                }
            })
            // setCouponApplied(true)
        } else {
            alert('Please enter coupon code');
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
        if(name == 'payment_method'){
            setPaymentMethod(value);
            setForrmSubmited(false);
        }
        if(formSubmited && paymentMethod && paymentMethod == 'credit_card'){
            const newErrors = findFormErrors();
            setErrors(newErrors)
        }
    }

    const findFormErrors = () => {
        const { cardholder_name, card_number, expire_date, ccv, payment_method, terms_condition, card_type } = formData
        const newErrors = {}

        if(payment_method && payment_method == 'credit_card'){
            if ( !cardholder_name || cardholder_name === '' ) newErrors.cardholder_name = 'Card Holder Name cannot be blank!'
            if ( !card_number || card_number === '' ) newErrors.card_number = 'Card Number cannot be blank!'
            if ( !expire_date || expire_date === '' ) newErrors.expire_date = 'Expire Date cannot be blank!'
            if ( !ccv || ccv === '' ) newErrors.ccv = 'CCV cannot be blank!'
            if(!card_type || card_type === '') newErrors.card_type = 'Select card type'
        }
        
        if( !payment_method || payment_method === '')  newErrors.payment_method = 'Select a payment method'
        if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms & Condition is require'

        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        if ( Object.keys(newErrors).length > 0 ) {
            
            // We got errors!
            setErrors(newErrors)
        } else {
           
            processPayment(formData);
        }
    }

    
    
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12} className="text-center mb-4">
                            <h2 className="mb-3">Choose Your Payment Method</h2>
                            <p className="text-muted">
                            Please review your membership details and billing information to complete your order
                            </p>
                        </Col>
                    </Row>
                    
                    <Row className="justify-content-center">
                        {   
                            agreement ? 
                            <Col lg={5}>
                                <Card className="border-0">
                                
                                        <Card.Body>
                                            <h5 className="plan-title text-uppercase mb-3"><b>your plan</b></h5>
                                            <h1 className="mb-3">{agreement.subscription_title}</h1>
                                            {  selectedPlan && selectedPlan.is_premimum == "yes" ? <p class="text-muted">All feature of Standard membership plus with extra services for only premium membership</p> : "" }
                                            <hr/>
                                            {
                                                selectedPlan && selectedPlan.is_premimum == "yes" ?  <p>The new plan take effect immediately once your payment has been processed. You will be charged ${ selectedPlan && coupon && coupon.discount_amount ? (parseInt(selectedPlan.price) - parseInt(coupon.discount_amount)).toFixed(2) : parseInt(selectedPlan.price).toFixed(2)} now to upgrade your membership from Standard to Premium</p> : '' 
                                            }
                                            {
                                                selectedPlan && selectedPlan.is_premium == "no" ?  <p>The new plan take effect immediately once your payment has been processed. You will be charged ${ selectedPlan && coupon && coupon.discount_amount ? (parseInt(selectedPlan.price) - parseInt(coupon.discount_amount)).toFixed(2) : parseInt(selectedPlan.price).toFixed(2)} now to start your membership</p> : ''
                                            }
                                            <div><b>Starting Today</b> ${ selectedPlan && selectedPlan.price && coupon && coupon.discount_amount ? ( parseInt(selectedPlan.price) - parseInt(coupon.discount_amount)).toFixed(2) : selectedPlan ? parseInt(selectedPlan.price).toFixed(2) : ''} per month</div>
                                            {selectedPlan ? <div className="mt-2"><b>Billing Detail</b> <span>{selectedPlan.description}</span></div> : ''}
                                            <p class="mt-4"><i>Cancel anytime. <a href="javascript: void(0)">Offer term</a> apply</i></p>
                                            <h6 class="text-muted">Promotion Code</h6>
                                            <InputGroup className="align-items-center mb-2">
                                                <FormControl
                                                placeholder="Have a Promo Code?"
                                                aria-label="Promo Code"
                                                className="border-top-0 border-left-0 border-right-0 pl-0 border-dark border-round-0" 
                                                onChange={setPromoCode}
                                                />
                                                { couponApplied == true ? <InputGroup.Text class="border-0 bg-none px-2 "><i className="lnir mr-1 lnir-checkmark"></i> Applied</InputGroup.Text> : ''}
                                                { couponApplied == false ? <Button variant="outline-secondary" onClick={() => checkCoupon()}>Apply</Button> : ''}
                                            </InputGroup>
                                            { couponApplied == true && coupon ? <p>Discount <span class="text-danger">${coupon.discount_amount}</span> applied {couponApplied}</p> : ''}
                                        </Card.Body>
                                    
                                </Card>
                            </Col>
                            : ''
                        }
                        <Col lg={ 4 }>
                            <Card className="border-0">
                                <Card.Body>
                                <h5 className="plan-title text-uppercase mb-3"><b>SELECT PAYMENT METHOD</b></h5>
                                    <div className="payment-summary bg-light p-3 mb-4">
                                        {
                                            selectedPlan && selectedPlan.trial_price && parseInt(selectedPlan.trial_price) > 0 ?
                                            <table class="w-100 table mb-0">
                                                {
                                                    selectedPlan ? 
                                                        <tr>
                                                            <td className="border-0">{selectedPlan.description}</td>
                                                            <td className="border-0">${parseInt(selectedPlan.trial_price).toFixed(2)}</td>
                                                        </tr>
                                                    : '' 
                                                }
                                                {
                                                    couponApplied ? 
                                                    <tr>
                                                        <td className="border-0">Discount</td>
                                                        <td className="text-danger border-0">-${coupon.discount_amount.toFixed(2)}</td>
                                                    </tr>
                                                    : ''
                                                }
                                                {
                                                    selectedPlan ? 
                                                        <tr className="border-grey border-top">
                                                            <td><h5 className="mb-0">Total</h5></td>
                                                            <td><h5 className="mb-0 text-success">${ coupon && coupon.discount_amount ? (parseInt(selectedPlan.trial_price) - parseInt(coupon.discount_amount)).toFixed(2) : parseInt(selectedPlan.trial_price).toFixed(2) }</h5></td>
                                                        </tr>
                                                    : ''
                                                }
                                            </table>
                                            :
                                            <table class="w-100 table mb-0">
                                                {
                                                    selectedPlan ? 
                                                        <tr>
                                                            <td className="border-0">{selectedPlan.title}</td>
                                                            <td className="border-0">${parseInt(selectedPlan.price).toFixed(2)}</td>
                                                        </tr>
                                                    : '' 
                                                }
                                                {
                                                    couponApplied ? 
                                                    <tr>
                                                        <td className="border-0">Discount</td>
                                                        <td className="text-danger border-0">-${coupon.discount_amount.toFixed(2)}</td>
                                                    </tr>
                                                    : ''
                                                }
                                                {
                                                    selectedPlan ? 
                                                        <tr className="border-grey border-top">
                                                            <td><h5 className="mb-0">Total</h5></td>
                                                            <td><h5 className="mb-0 text-success">${ coupon && coupon.discount_amount ? (parseInt(selectedPlan.price) - parseInt(coupon.discount_amount)).toFixed(2) : parseInt(selectedPlan.price).toFixed(2) }</h5></td>
                                                        </tr>
                                                    : ''
                                                }
                                            </table>
                                        }
                                        
                                    </div>
                                    <div className="payment Method">
                                    <Form  validated={validated} onSubmit={handleSubmit}>
                                        <div className="payment-type">
                                            <Form.Group className="mb-3" controlId="formDepartmentId" >
                                                <Form.Check 
                                                    name="payment_method"
                                                    type="radio"
                                                    id={`creditcard`}
                                                    label={<div className="d-flex justify-content-between"><span>Google/Apple Pay</span> <img src={wallet} alt="Moble Wallet" height="20"  /></div> }
                                                    onChange={handleChange} 
                                                    value="mobile_wallet" 
                                                    isInvalid={ !!errors.payment_method }
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="payment-type">
                                            <Form.Group className="mb-3" controlId="formDepartmentId" >
                                                <Form.Check 
                                                    name="payment_method"
                                                    type="radio"
                                                    id={`paypal`}
                                                    label={<div className="d-flex justify-content-between"><span>PayPal</span><img src={payPal} alt="Paypal" height="20" /></div>}
                                                    onChange={handleChange}
                                                    value="paypal"
                                                    isInvalid={ !!errors.payment_method }
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className="payment-type">
                                            <Form.Group className="mb-3" controlId="formDepartmentId" >
                                                <Form.Check 
                                                    name="payment_method"
                                                    type="radio"
                                                    id={`creditcard`}
                                                    label={<div className="d-flex justify-content-between"><span>Credit Card</span><img src={creditCard} alt="Credit Card" height="20"  /></div>}
                                                    onChange={handleChange}
                                                    value="credit_card" 
                                                    isInvalid={ !!errors.name }
                                                    isInvalid={ !!errors.payment_method }
                                                />
                                            </Form.Group>
                                            <Form.Control.Feedback type="invalid">
                                                { errors.payment_method }
                                            </Form.Control.Feedback>
                                            
                                        </div>
                                        {
                                            paymentMethod && paymentMethod == 'credit_card' ?
                                                <div className="">
                                        
                                                    <Form.Group className="mb-3" controlId="formCardHolderName" >
                                                        <Form.Label>Card Holder Name</Form.Label>
                                                        <Form.Control type="text" placeholder="Card Holder Name" name="cardholder_name" onChange={handleChange} isInvalid={ !!errors.cardholder_name } />
                                                        <Form.Control.Feedback type="invalid">
                                                            { errors.cardholder_name }
                                                        </Form.Control.Feedback>
                                                        
                                                    </Form.Group>
                                                    <Form.Group className="mb-3" controlId="formCardNumber">
                                                        <Form.Label>Card Number</Form.Label>
                                                        {/* <Form.Control type="tel" inputmode="numeric" pattern="\d*" autocomplete="cc-number" maxlength="19" placeholder="xxxx xxxx xxxx xxxx" name="card_number" onChange={handleChange} isInvalid={ !!errors.card_number } />  */}
                                                        <NumberFormat customInput={Form.Control} name="card_number" format="#### #### #### ####" mask="X" placeholder="XXXX XXXX XXXX XXXX" onChange={handleChange} isInvalid={ !!errors.card_number } />
                                                        <Form.Control.Feedback type="invalid">
                                                            { errors.card_number }
                                                        </Form.Control.Feedback>
                                                        
                                                    </Form.Group>
                                                    
                                                    {
                                                        visaTypeOptions && visaTypeOptions.length > 0 ?
                                                            <Form.Group className="mb-3" controlId="formCardType">
                                                                <Form.Label>Card Type</Form.Label>
                                                                <Form.Control  as="select" type="select" name="card_type" onChange={handleChange} isInvalid={ !!errors.card_type }>
                                                                    <option value="">Select Card Type</option> 
                                                                    {
                                                                        visaTypeOptions.map((type, index)=>{
                                                                        return <option value={type.value} key={`cardtype-${index}`}>{type.label}</option>
                                                                        })
                                                                    }
                                                                </Form.Control>
                                                                <Form.Control.Feedback type="invalid">
                                                                    { errors.card_type }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        : ''
                                                    }
                                                    <div className="d-flex">
                                                        <div className="flex-1">
                                                            <Form.Group className="mb-3" controlId="formCardNumber">
                                                                <Form.Label>Expiration</Form.Label>
                                                                {/* <Form.Control type="text" placeholder="MM/YY" name="expire_date" onChange={handleChange} isInvalid={ !!errors.expire_date } /> */}
                                                                <NumberFormat customInput={Form.Control} name="expire_date" format="##/##"  placeholder="MM/YY" onChange={handleChange} isInvalid={ !!errors.expire_date } />
                                                                <Form.Control.Feedback type="invalid">
                                                                    { errors.expire_date }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                        <div className="flex-1 pl-3">
                                                            <Form.Group className="mb-3" controlId="formCardNumber">
                                                                <Form.Label>CCV</Form.Label>
                                                                <Form.Control type="text" placeholder="" name="ccv" onChange={handleChange} isInvalid={ !!errors.ccv } />
                                                                <Form.Control.Feedback type="invalid">
                                                                    { errors.ccv }
                                                                </Form.Control.Feedback>
                                                            </Form.Group>
                                                        </div>
                                                    </div>

                                            
                                                </div>
                                            : ''
                                        }
                                    
                                        
                                        { paymentMethod && paymentMethod == 'paypal' ? <div className="">We'll redirect you to PayPal to complete your purchase.</div> : '' }
                                        <div>
                                            <Form.Check 
                                                value="1"
                                                type='checkbox'
                                                id={`terms-vondition`}
                                                onChange={handleChange}
                                                name="terms_condition"
                                                label={<div>By check you accept our <a href="javascript: void(0)" onClick={() =>{}}>Terms & Condition</a></div>} 
                                                isInvalid={ !!errors.terms_condition }
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                { errors.terms_condition }
                                            </Form.Control.Feedback>
                                        </div>
                                        <div className="action mt-2">
                                            <Button variant="light" type="submit" className="btn btn-light primary-bg btn-block p-3">Proceed To Payment</Button>
                                        </div>
                                    </Form>
                                    </div>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>

                    {/* <Row className="justify-content-center">
                        <Col lg={4}>
                            <Card className="border-0 text-center">
                                <Card.Body>
                                    <img src={Thankyou} />
                                    <h3 className="mt-4">Upgrade Successful</h3>
                                    <p>Thank you for upgrading! Your plan was changed successful.We’ll send receipt to your email <b>cshpartners@gmail.com</b>.You also find it in your <a href="#">subscription</a></p>
                                    <h6>Subcribe our newsletter to get the latest about<br/>new tracks &amp; promotions</h6>
                                    <InputGroup className="align-items-center mt-3 subscribeInput">
                                    <InputGroup.Text class="border-0 bg-none pl-3 "><i className="lnir lnir-envelope"></i></InputGroup.Text>
                                        <FormControl
                                        placeholder="Your Email Address"
                                        aria-label="Your Email Address"
                                        className=""
                                        />
                                        <Button variant="outline-secondary">subscribe</Button>
                                    </InputGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Card className="border-0 text-center">
                                <Card.Body>
                                    <img src={PaymentFailed} />
                                    <h3 className="mt-4">Payment Failed</h3>
                                    <p>Don’t worry! We’ll try your payment again over the nextfew days.To keep Premium Membership, you may need toupdate your payments details</p>
                                    <a href="#" className="btn btn-light primary-bg btn-block p-3 btn btn-light">update payment details</a>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row> */}

                </Container>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        plans: state.subscription.plans
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        validateCoupon: (post) => dispatch(couponAction.validateCoupon(post)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        processCardPaymentMethod: (post) => dispatch(subscriptionAction.processCardPaymentMethod(post)),
        processPaypalPayment: (post) => dispatch(subscriptionAction.processPaypalPayment(post)),
        getPlanDetails: (post) => dispatch(subscriptionAction.getPlanDetails(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentMethod);