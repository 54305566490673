import React, { useState, useEffect } from "react";
import "./header.scss";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import logo from "../../assets/img/club_killer_log.png";
import { Link } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as mobilemenuAction from '../../store/mobilemenu/actions'
import * as themeAction from '../../store/theme/actions'



const  Header = ({crateSongs, getCustomerCrates, setMobileMenu, customer, changeTheme, themeType}) => {

    const history = useHistory();

    const [toggleClass, setToggleClass] = useState(false);
    const [searchTerm, setSearchTerm ] = useState();
    const [theme, setTheme ] = useState('light');
  
    useEffect(() => { 
      if(themeType === 'dark-theme'){
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    },[themeType])


    useEffect(() => {
      getCustomerCrates();
    }, [])

    const displayHideMenu = () => {
      const displayHide = !toggleClass;
      setToggleClass(displayHide);
      setMobileMenu(displayHide)
    }

    const handleChange = (event) => {
      setSearchTerm(event.target.value);
    }

    const handleKeyDown = (event) => {
      if(event.key === 'Enter'){
        if(searchTerm && searchTerm.length > 2){
          history.push(`/search/result/${searchTerm}`);
        }
      }
    }

    const changeMode = (event) => {
      
      if(theme === 'light'){
        setTheme('dark');
        changeTheme('dark-theme');
        document.body.classList.add('dark-theme');
      } else {
        setTheme('light');
        changeTheme('light');
        document.body.classList.remove('dark-theme');
        // document.body.classList.add('light-theme');
      }
    }
    
    return (
      <header>
        <div className="d-flex mb-5 align-items-center justify-content-between flex-wrap">
          <div className="d-flex d-md-none align-items-center">
            <div className="menuBtn mr-3 rounded" onClick={()=> displayHideMenu()}>
              <i className="lnir lnir-menu"></i>
              <i className="lnir lnir-close"></i>
            </div>
            <figure className="header-logo mb-0">
              <img src={logo} />
            </figure>
          </div>

          {/* <div className="d-flex align-items-center justify-content-start flex-grow-1 w-100"> */}
            {/* <div className="flex-grow-1 "> */}
            {/* </div> */}
          {/* </div> */}
          <div className="justify-content-around optionSide justify-content-sm-end d-flex align-items-center">
            <div className="mr-3">
              <Form className="themeMode text-nowrap">
                <Form.Check type="switch" id="custom-switch" label="Day Mode" onChange={changeMode}  />
              </Form>
            </div>
            <div className="pl-2 pl-sm-4 cartLink text-nowrap">
              <Link to={`/crate`} className="">
                <i className="lnir lnir-package position-relative">
                  <span className="count position-absolute">{crateSongs ? crateSongs.length : 0}</span>
                </i>
                <span className="d-none d-lg-inline-block">
                  Your Crate
                </span>
              </Link>
            </div>
            <div className="ml-4 ml-sm-5">
              <Dropdown className="userOption" alignRight={true}>
                <Dropdown.Toggle id="dropdown-basic">
                {
                  customer && customer.customerProfile && customer.customerProfile.profile_picture ? <span className="img" style={{ backgroundImage: 'url('+customer.customerProfile.profile_picture+')', display: 'InlineBlock', backgroundSize: 'cover' }}></span> : <span className="img"></span>
                }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item as={Link} to="/account/update">Profile</Dropdown.Item>
                  <Dropdown.Item as={Link} to="/logout">
                    Logout
                  </Dropdown.Item>
            
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <InputGroup className="searchBox flex-nowrap pr-sm-3 flex-grow-1 w-auto">
                <InputGroup.Prepend>
                  <InputGroup.Text id="Search">
                    <i className="lnir lnir-search-alt"></i>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  placeholder="Search for songs or anything..."
                  aria-label="Search"
                  aria-describedby="Search"
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
            </InputGroup>
        </div>
      </header>
    );
  
}


function mapStateToProps(state) {
  return {
    crateSongs: state.song && state.song.customerCurates && state.song.customerCurates.version ?  state.song.customerCurates.version : [],
    customer: state.customer,
    themeType: state.theme.theme
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
    setMobileMenu: (bool) => dispatch(mobilemenuAction.setMobileMenu(bool)),
    changeTheme: (data) => dispatch(themeAction.changeTheme(data))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Header);
