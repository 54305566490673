import * as types from './type';

const initialState = { 
    menuClass: '' 
}


export default function mobilemenuReducer(state = initialState, action) {
    switch (action.type) {
        case types.BOOL_MOBILE_MENU:
            return {
                ...state,
                menuClass: action.payload
            };
        default:
            return state
    }
}