import React, {useState, useEffect} from "react";



import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button'


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';
import AccountNav from "../../elements/accountNav/accountNav";
import Pagination from "react-js-pagination";
import "./download.scss";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import config from '../../config/config';

import PlayGroupDownloadRescue from "../../elements/playgroup/playGroupDownloadRescue";
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';


import { showLoading, hideLoading } from 'react-redux-loading-bar'

const Crate = ({ displayLoading, hideLoading, getDownloadRescue, downloadHistory}) => {
  const [  page, setPage ] = useState(1);
  const perPageLimit =  config.itemsCountPerPage;
  const [ currentGenreId, setCurrentGenreId ] = useState(0);
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ activeBadge, setActiveBadge ] = useState('all');
  const [years, setYears] = useState();
  const currentYear = new Date().getFullYear();

  useEffect(() => { 
    loadPageData()
  },[]);
  
  const loadPageData = async () => {
    var yearArray = []
    for (var i=currentYear; i > (currentYear-3); i--) {
      yearArray.push(i);
    }
    setYears(yearArray)
    displayLoading()
    await getDownloadRescue({ page: page, pageLimit: perPageLimit }, () => {
    })
    hideLoading();
  }
  

  const filterBillingHistoryByYear = async(YY) => {
    setActiveBadge(YY);
    displayLoading();
    if(YY === 'all'){
      // await getDownloadRescue({page: page, pageLimit: perPageLimit});
    } else {
      const start_date = {"day":1, "month":1, "year": YY};
      setStartDate(start_date);
      const end_date = {"day":31, "month":12, "year": YY}
      setPage(1)
      await getDownloadRescue({page: 1, pageLimit: perPageLimit, startDate: start_date, endDate: end_date}).then((res) => {});
      setEndDate(end_date);
    }
    hideLoading();
  }

  const handlePageChange = async (pageNumber) => {
      setPage(pageNumber);
      displayLoading();
      await getDownloadRescue({page: pageNumber, pageLimit: perPageLimit, startDate: startDate, endDate: endDate}).then((res) => {});
      hideLoading();
  }

  const filterDownloadRescue = async() => {
    displayLoading();
    setPage(1)
    await getDownloadRescue({page: 1, pageLimit: perPageLimit, startDate: startDate, endDate: endDate}).then((res) => {});
    hideLoading();
  }

  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Download Rescue</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
          <Card className="border-0">
              <Card.Body>
                <div className="cardMark mb-3">Download history</div>

                <Container fluid className="p-0 mb-4">
                  <Row className="align-items-center">
                    <div>
                      <div className="badgeList d-none d-lg-block">
                        <Badge variant={activeBadge === 'all' ? 'active' : ''}>All</Badge>
                        {
                          years && years.length > 0 ? 
                            years.map((year, index) => {
                              return <Badge variant={activeBadge === year ? 'active' : ''} key={`year${year}`} onClick={() => filterBillingHistoryByYear(year)}>{year}</Badge>
                            })
                          : ''
                        }
                      </div>
                    </div>
                    <Col>
                    <DatePicker
                        value={startDate}
                        onChange={setStartDate}
                        inputPlaceholder="From"
                        shouldHighlightWeekends
                     /> &nbsp;
                     <DatePicker
                        value={endDate}
                        onChange={setEndDate}
                        inputPlaceholder="To"
                        shouldHighlightWeekends
                     /> &nbsp;
                     <Button variant="light" className="primary-bg" size="sm" onClick={() => filterDownloadRescue()}><i className="lnir lnir-search"></i></Button>
                    </Col>
                    
                  </Row>
                </Container>


        
          {
              downloadHistory &&  downloadHistory.results && downloadHistory.results.length > 0 ?
              <PlayGroupDownloadRescue songs={downloadHistory.results}    /> 
              : ''
          }
          <div className="pagin text-center mt-5 mb-5">
          {
          downloadHistory && downloadHistory.pagination ? 
            <Pagination
                  activePage={downloadHistory.pagination.page}
                  itemsCountPerPage={downloadHistory.pagination.perPageLimit}
                  totalItemsCount={downloadHistory.pagination.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>
          </Card.Body>
          </Card>
        </div>
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    downloadHistory: state.download.downloadRescue
  };
}

function mapDispatchToProps(dispatch) {
  return {
      // getDownloadRescue: (post) => dispatch(songActions.getDownloadRescue(post)),
      displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
      hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
      getDownloadRescue: (post) => dispatch(downloadActions.getDownloadRescue(post))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Crate);