import React, { useState, useEffect } from "react";

import Albumtracks from '../albumtracks/albumtracks'
import Titletracks from '../titletracks/titletracks';

import AlbumImg from '../../assets/img/album-img.png';

import {connect} from 'react-redux';
import * as trendingActions from '../../store/trending/actions';
import * as downloadActions from '../../store/download/actions';

const RecentPlayedPlayList = ({getTrendingSongs, currentTrending, getMostDownloadedToday, mostDownloadedToday, blocks}) => {
    useEffect(() => { 
        
      },[])

    return (
		<aside className="mb-4">
			<h3>Playlist Just Played</h3>
			<div className="d-flex border-bottom pt-3 pb-3 border-grey text-dark trackList ofAlbum">
			<div className="img">
				<figure className="bg-dark">
				<i className="icon play-icon"></i>
				</figure>
			</div>
			<div className="info">
				<h6>Badbees (Smassh’ SayMy Name’ Blend</h6>
				<p>Lil Dicky ft Chris Brown</p>
			</div>
			<div className="download-link">
				<a href=""><i className="lnir lnir-more"></i></a>
			</div>
			</div>
			<div className="d-flex border-bottom pt-3 pb-3 border-grey text-dark trackList ofAlbum">
			<div className="img">
				<figure className="bg-dark">
				<i className="icon pause-icon"></i>
				</figure>
			</div>
			<div className="info">
				<h6>Badbees (Smassh’ SayMy Name’ Blend</h6>
				<p>Lil Dicky ft Chris Brown</p>
			</div>
			<div className="download-link">
				<a href=""><i className="lnir lnir-more"></i></a>
			</div>
			</div>
		</aside>
    )
}



function mapStateToProps(state) {
    return {
        currentTrending: state.trending.currentTrending,
        mostDownloadedToday: state.download.mostDownloadedToday
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getTrendingSongs: () => dispatch(trendingActions.getLatestTrendingSong()),
        getMostDownloadedToday: (post) => dispatch(downloadActions.getMostDownloadedToday(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentPlayedPlayList);