import * as types from './type';

const initialState = { 
    currentTrending: '' 
}


export default function trendingReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_CURRENT_TRENDING_SONGS:
            if(action.payload && action.payload.data && action.payload.data.songs){
                return {
                    ...state,
                    currentTrending: action.payload.data.songs
                };
            } 
        default:
            return state
    }
}