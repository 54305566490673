import React from "react";

//custom components
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';

import CarouselList from "../../elements/carousellist/carousellist";
import CarouselItem from "../../elements/carouselItem/carouselItem";

import "./playlist.scss";

export default function Playlist() {
  return (
    <MusicDashboard>
      <div>
          <div>
            <CarouselList title="MONTHLY TOP 20" sellAllLink="www.google.com" price="$134.99"/>
          </div>
          <div className="mt-5">
            <CarouselItem title="Recommended For You" updatedDate="Apr 6, 2021 09:35AM" price="$134.99"/>
          </div>
          <div className="mt-5">
            <CarouselItem title="Must Played" updatedDate="" price="$134.99"/>
          </div>
          <div className="mt-5">
            <CarouselItem title="Edits & Exclusives" updatedDate="" price="$134.99"/>
          </div>
          <div className="mt-5">
            <CarouselItem title="All Time Hits" updatedDate="" price="$134.99"/>
          </div>
      </div>
    </MusicDashboard>
  );
}
