import React, {useState} from "react";
import "./forgetPasswordModal.scss";


import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';

import {connect} from 'react-redux';



const ForgetPasswordModal = ({showState, ShowFunction, showLogin, forgetPasswordRequest, processing, forgetErr}) =>  {
  const [formData, setFormData] = useState({
      email: ''
  });

  // const [submited, setSubmited] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      
    } else {
      // setSubmited(true);
      forgetPasswordRequest(formData);
    }
    setValidated(true);
  }

  return(
    <Modal
    show={showState}
    onHide={ShowFunction}
    backdrop="static"
    keyboard={false}
    size="md"
  >
    <Modal.Header>
      <Modal.Title>Recover Password</Modal.Title>
    </Modal.Header>
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="popup-form">
      <Modal.Body>
        {
          forgetErr && forgetErr !== '' ?
          <div className="alert alert-danger">{forgetErr}</div>
          : ''
        }
        <InputGroup className="lineInput">
          <InputGroup.Text>
            <i className="lnir lnir-user-alt-1"></i>
          </InputGroup.Text>
          <Form.Control type="email" name="email" placeholder="Email" onChange={handleChange} required  />
          <Form.Control.Feedback type="invalid">
          Invalid Email
          </Form.Control.Feedback>
        </InputGroup>
        
      </Modal.Body>
      <Modal.Footer>
          <Button type="submit" className="text-uppercase" variant="primary" block disabled={processing}> { !processing ? 'Reset Password' : 'Processing ...'}</Button>
          <Button className="text-uppercase" variant="light" block onClick={showLogin}>Login</Button>
      </Modal.Footer>
    </Form>
  </Modal>
  )  
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgetPasswordModal);