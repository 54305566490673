import React, {useState} from "react";
import "./loginModal.scss";


import Modal from 'react-bootstrap/Modal';
// import ModalDialog from 'react-bootstrap/ModalDialog';
// import ModalHeader from 'react-bootstrap/ModalHeader';
// import ModalTitle from 'react-bootstrap/ModalTitle';
// import ModalBody from 'react-bootstrap/ModalBody';
// import ModalFooter from 'react-bootstrap/ModalFooter';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';

import {connect} from 'react-redux';



const LoginModal = ({showState, ShowFunction, showSignup, login, processing, loginErr, showForgetPassword, successMessage}) =>  {
  const [formData, setFormData] = useState({
      email: '',
      password: ''
  });

  // const [submited, setSubmited] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });
  }

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === false) {
      
    }
    setValidated(true);
    // setSubmited(true);
    login(formData);
  }

  return(
    <Modal
    show={showState}
    onHide={ShowFunction}
    backdrop="static"
    keyboard={false}
    size="md"
  >
    <Modal.Header>
      <Modal.Title>SIGN IN</Modal.Title>
    </Modal.Header>
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="popup-form">
      <Modal.Body>
        {
          loginErr && loginErr !== '' ?
          <div className="alert alert-danger">{loginErr}</div>
          : ''
        }
        {
          successMessage && successMessage !== '' ?
          <div className="alert alert-success">{successMessage}</div>
          : ''
        }
        <InputGroup className="lineInput">
          <InputGroup.Text>
            <i className="lnir lnir-user-alt-1"></i>
          </InputGroup.Text>
          <Form.Control type="email" name="email" placeholder="Email" onChange={handleChange} required  />
          <Form.Control.Feedback type="invalid">
          Invalid Email
          </Form.Control.Feedback>
        </InputGroup>
        <InputGroup className="mt-3 lineInput"> 
          <InputGroup.Text>
            <i className="lnir lnir-lock-alt"></i>
          </InputGroup.Text>
          <Form.Control type="password" name="password" placeholder="Password *" onChange={handleChange} required />
          <Form.Control.Feedback type="invalid">
          Password is required field *
          </Form.Control.Feedback>
          <InputGroup.Text>
          <span className="fpass" onClick={showForgetPassword}>Forgot Password</span>
          </InputGroup.Text>
        </InputGroup>
        <Form.Group className="mt-4 mb-0" controlId="formBasicCheckbox">
          <Form.Check type="checkbox" label="Remember me" />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
          <Button type="submit" className="text-uppercase" variant="primary" block disabled={processing}> { !processing ? 'Sign in' : 'Processing ...'}</Button>
          <Button className="text-uppercase" variant="light" block onClick={showSignup}>create an account</Button>
      </Modal.Footer>
    </Form>
  </Modal>
  )  
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);