import * as types from './type';

// import { Product }  from  '../../services/product';

export function setMobileMenu(bool) {
  console.log('action', bool)
    return {
      type: types.BOOL_MOBILE_MENU,
      payload: bool,
    };
};