import React, { useState, useEffect } from "react";

import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as songActions from '../../store/song/actions';

const FavouriteButton = ({addSongToFavourite, getCustomerCrates, song, favouriteSongs }) => {

    const [ downloadInProcess, setDownloadInProcess ] = useState(false);

    const addRemoveSongToFavourite = async(song) => {
        setDownloadInProcess(true);
        await addSongToFavourite(song.entity_id);
        await getCustomerCrates();
        setDownloadInProcess(false)
    }
    
    if(downloadInProcess){
        return <span className="pointer"><Spinner animation="border" size="sm" variant="warning" /></span>
    } else {
        return (
            <span onClick={() => addRemoveSongToFavourite(song)} className={ favouriteSongs && favouriteSongs && favouriteSongs.length > 0 && ((favouriteSongs.indexOf(song.entity_id.toString()) > -1) ) ? 'active pointer' : 'pointer'}>
                <i className="lnir lnir-heart"></i>
            </span>
        )
    }

}
function mapStateToProps(state) {
    return {
        favouriteSongs: state.song.favouriteSongs,
    };
   }
   
   function mapDispatchToProps(dispatch) {
    return {
        addSongToFavourite: (songId) => dispatch(actions.addSongToFavourite(songId)),
        getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(FavouriteButton);
  