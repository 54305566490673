import React, {useEffect, useState} from "react";


import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import payPal from "../../assets/img/paypal.svg";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';

import "./subscription.scss";

import config from '../../config/config';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import * as billingAction from '../../store/billing/actions'

const Subscription = ({getCustomerAgreement, displayLoading, hideLoading, agreement, billing, getBillingHistory, downloadBillingEntry, cancelSubscription, cancelCustomerFeedback}) => {

	const MySwal = withReactContent(Swal) 
	useEffect(() => { 
		// load Page Data
		loadPageData();
	},[]);
  
	const loadPageData = async() => {
   		displayLoading();
		await getCustomerAgreement();
		await getBillingHistory({page: 1});
		hideLoading();
	}

	const downloadBillingById = async (historyId, format='') => {
        displayLoading();
        await downloadBillingEntry({ history_id: historyId, format:format }).then((res) => {
          if(res.payload.data && res.payload.data.url){
            downloadFile(res.payload.data)
          }
		    });
		    hideLoading();
	}
	
	const downloadFile = (data) => {
		var downloadurl = `${config.uploadUrl}site/download?url=${data.url}&file=${data.file}`;
		// const downloadurl = data.url;
		window.location.assign(downloadurl);
	}

	

	const cancelPlan = () => {
		Swal.fire({
			title: 'Are you sure?',
			text: "You won't be able to revert this!",
			icon: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#ffa800',
			cancelButtonColor: '#d33',
			confirmButtonText: 'Yes, cancel it!'
		  }).then((result) => {
			if (result.isConfirmed) {
				cancelSubscription().then(res => {
					getCustomerAgreement();
					Swal.fire({
						title: 'Subsciption Cancelled',
						text: "Your subscription has been cancelled.no payment will be charge.",
						input: 'text',
						inputAttributes: {
						  autocapitalize: 'off',
						  placeholder: 'Sad to see you go.We would love some constructive criticism on our site'
						},
						showCancelButton: true,
						confirmButtonText: 'Submit',
						showLoaderOnConfirm: true,
						preConfirm: (message) => {
							if(message != ''){
								console.log(message)
								sendCustomerCancelFeedback(message)
							}
						},
						allowOutsideClick: () => !Swal.isLoading()
					  }).then((result) => {
						
					  })
				})
			
			}
		  })
	}

	const sendCustomerCancelFeedback = (message) => {
        
        cancelCustomerFeedback({message: message}).then(res  => {
			Swal.fire({
				title: "Thank you for your valuable feedback!", 
				text: "Hope to see you soon",
				type: "success", 
				closeOnConfirm: true
			}, function(){
                
            });
        });
    }
	  
  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Subscription</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
        <Container fluid className="p-0">
          <Row>
            <Col lg={7}>
            <Card className="border-0 h-100">
              <Card.Body>
                <div className="cardMark mb-3">Your Current Plan</div>
				{
					agreement ?
						<div>
							<Row>
								<Col>
									<h2>{agreement.subscription_title}</h2>
									<p className="text-muted">Unlimited uploads &amp; donwloads Mp3 audio, MP4 Videoon Club Killers site</p>
								</Col>
								<Col>
									<ul className="planInfoList">
									<li><label>Price:</label>  {agreement.plan_price} per month</li>
									<li><label>Joined:</label>  {agreement.created_at}</li>
									<li><label>Status:</label>  <span className="text-success">{agreement.profile_status}</span></li>
									<li><label>Renewal Cycle:</label>  {agreement.interval}</li>
									</ul>
								</Col>
							</Row>
						
							<Row className="align-items-center mt-3">
								{
									agreement.profile_status == 'Active' ?
										<Col>
											<Link  to={`/subscription/plans`} className="btn btn-light primary-bg">Upgrade Plan</Link>
											<Button variant="light" className="ml-3" onClick={()=>cancelPlan()}>cancel Plan</Button>
										</Col>
									:  
									agreement.profile_status == 'CREATED' ? 
										<Col>
											<Button variant="light" className="primary-bg">Payment Processing</Button>
										</Col>
									:
										<Col>
											<Link  to={`/subscription/plans`} className="btn btn-light primary-bg">Subscribe</Link>
											<Button variant="light" className="ml-3" onClick={()=>cancelPlan()}>cancel Plan</Button>
										</Col>
								}
								{
									agreement.profile_status == 'Active'
									?
									<Col>
										<p className="mb-0">Next payment of <b>{agreement.plan_price}</b> ({agreement.interval}) occurs on <b>{agreement.next_payment_date}</b></p>
									</Col>
									: ''
								}
							</Row>
					</div>
				: ''
			}
              </Card.Body>
            </Card>
            </Col>
            <Col lg={5}>
			{
				agreement.payment_method == 'Credit Card' ?
					<Card className="border-0 h-100">
						<Card.Body>
							<div className="cardMark mb-3">payment method</div>
							<div className="d-flex">
								<aside className="pr-3"><img src={payPal}/></aside>
								{/* <aside>
									<p className="text-muted">****  ****  ****  1234 <br/>Expires 12/2025</p>
								</aside>*/}
							</div>
							{/* <p className="text-muted">Logan Cee <br/>192 Orchard St, Brooklyn Town, New York, CA 90002</p> */}
							<div className="mt-3"> 
								<Button variant="light">Update</Button>
								{/* <Button variant="light" className="ml-3">Remove</Button> */}
							</div>
						</Card.Body>
					</Card>
				:  agreement.payment_method == 'Paypal' ? 
					<Card className="border-0 h-100">
						<Card.Body>
							<div className="cardMark mb-3">payment method</div>
							<div className="d-flex">
								<aside className="pr-3"><img src={payPal}/></aside>
							</div>
						</Card.Body>
					</Card>
				: ''
			}
			{
				agreement.payment_method == 'paypal' ? 
					<Card className="border-0 h-100">
						<Card.Body>
							<div className="cardMark mb-3">payment method</div>
							<div className="d-flex">
								<aside className="pr-3"><img src={payPal}/></aside>
							</div>
						</Card.Body>
					</Card>
				: ''
			}

            </Col>
            <Col lg={12} className="mt-4">
            <Card className="border-0">
              <Card.Body>
                <div className="cardMark mb-3">Recently billing history</div>
				<Table hover responsive>
					<thead>
						<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Payment Method</th>
						<th>Amount</th>
						<th>Invoice</th>
						</tr>
					</thead>
					{
						billing && billing.billing_history && billing.billing_history.length > 0 ?
						<tbody>
							{
								billing.billing_history.map((history, index)=>{
									return <tr key={`billing${index}`}>
											<td>{history.payment_date}</td>
											<td>{history.plan_name}</td>
											<td>{history.payment_method}</td>
											<td>{history.amount}</td>
											<td className="text-uppercase">	
												<span  onClick={()=> downloadBillingById(history.history_id, 'pdf')}>PDF</span> &nbsp;
												<span onClick={()=> downloadBillingById(history.history_id, 'excel')}>Excel</span>
											</td>
									</tr>
								})
							}	
							
						</tbody>
						: ''
					}
				</Table>
				<Link to={`/account/billing`} className="linkAction">See All Billings History</Link>
              </Card.Body>
            </Card>
            </Col>
          </Row>
        </Container>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
	billing: state.billing.billing
  };
}

function mapDispatchToProps(dispatch) {
  	return {
      	getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
      	displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
		getBillingHistory: (post) => dispatch(billingAction.getBilling(post)),
		downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
		cancelSubscription: () => dispatch(subscriptionAction.cancelSubscription()),
		cancelCustomerFeedback: (post) => dispatch(subscriptionAction.cancelCustomerFeedback(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);