import * as types from './type';

const initialState = {
    customerProfile: '',
    customerError: '',
    customerSuccess: '',
    customerSignUpSuccess: ''
};



export default function customerReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.GET_CUSTOMER_PROFILE: {
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerProfile: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }
    case types.CHANGE_PASSWORD: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSuccess: action.payload.data.message
        }
      } else {
        return { 
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.CUSTOMER_SIGNUP: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSignUpSuccess: action.payload.data
        }
      } else {
        return { 
          
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.SUBMIT_DJ_APP: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerSuccess: action.payload.data.message
        }
      } else {
        return { 
          ...state,
          customerError: action.payload.message.errors
        }
      }
    }
    case types.UPDATE_PROFILE: {
      
      if (action.payload && action.payload.data) {
        return {
          ...state,
          customerProfile: action.payload.data
        }
      } else {
        return {
          ...state
        }
      }
    }

    default: {
      return {
        ...state
      };
    }
    
  }

}