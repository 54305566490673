import React, {useState, useEffect} from "react";

//custom components
import Form from 'react-bootstrap/Form'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';

import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import "./profile.scss";
import  API_BASE_URL  from '../../config/config';
import { FilePond, File, registerPlugin } from 'react-filepond'

// Import FilePond styles
import 'filepond/dist/filepond.min.css'

import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import * as commonActions from '../../store/common/actions';
import * as customerAction from '../../store/customer/actions';


const Profile = ({customer, getCountryList, countryList, authentication, updateProfile, getUserProfile, user}) => {

    const [formData, setFormData] = useState();
    const [formDataEnter, setFormDataEnter] = useState(false);
    const [isCountrySelected, setIsCountrySelected] = useState(false)
    const [ responseError, SetResponseError ] = useState();
    const [ responseSuccess, SetResponseSuccess ] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [region, setRegion] = useState();
    const [files, setFiles] = useState([]);
    const uploadURL = API_BASE_URL.apiUrl+"/customer/upload-profile-image.json?token="+authentication.user+"&store_id=1&lang_code=en";

    useEffect(() => { 
		getCountryList();
	},[])

    useEffect(() => { 
		if(customer && customer.customerProfile && formDataEnter === false){
            setFormData({
                firstname: customer && customer.customerProfile && customer.customerProfile.firstname ? customer.customerProfile.firstname : '',
                lastname: customer && customer.customerProfile && customer.customerProfile.lastname ? customer.customerProfile.lastname : '',
                street: customer && customer.customerProfile && customer.customerProfile.street ? customer.customerProfile.street : '',
                country_id: customer && customer.customerProfile && customer.customerProfile.country_id ? customer.customerProfile.country_id : '',
                region_id: customer && customer.customerProfile && customer.customerProfile.region_id ? customer.customerProfile.region_id : '',
                city: customer && customer.customerProfile && customer.customerProfile.city ? customer.customerProfile.city : '',
                postcode: customer && customer.customerProfile && customer.customerProfile.postcode ? customer.customerProfile.postcode : '',
                email: customer && customer.customerProfile && customer.customerProfile.email ? customer.customerProfile.email : '',
                dj_name: customer && customer.customerProfile && customer.customerProfile.dj_name ? customer.customerProfile.dj_name : '',
            });
            setFormDataEnter(true);
        }
        if(isCountrySelected == false && customer && customer.customerProfile &&  countryList && countryList.length > 0){
            const selectedCountry = countryList.find((element) => {
                return element.id === customer.customerProfile.country_id;
            });
            if(selectedCountry && selectedCountry.region){
                setRegion(selectedCountry.region)
                setIsCountrySelected(true);
            }
            
            
        }
    },[customer])
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
        
        if(name === 'country_id'){
            const selectedCountry = countryList.find((element) => {
                return element.id === value;
            });
            setRegion(selectedCountry.region)
        } 
    }

    const findFormErrors = () => {
        const { firstname, lastname, street, country_id, region_id, postcode, email, city } = formData
        const newErrors = {}

        
        // password errors
        if ( !firstname || firstname === '' ) newErrors.firstname = 'First Name cannot be blank!'
        if ( !lastname || lastname === '' ) newErrors.lastname = 'Last Name cannot be blank!'
        if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
        if ( !country_id || country_id === '' ) newErrors.country_id = 'Country cannot be blank!'
        if ( !region_id || region_id === '' ) newErrors.region_id = 'State cannot be blank!'
        if ( !city || city === '' ) newErrors.city = 'City cannot be blank!'
        if ( !postcode || postcode === '' ) newErrors.postcode = 'Zip Code cannot be blank!'
        if ( !street || street === '' ) newErrors.street= 'Address cannot be blank!'

        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log(newErrors)
        if ( Object.keys(newErrors).length > 0 ) {
            console.log(newErrors)
            // We got errors!
            setErrors(newErrors)
        } else {
            updateProfile(formData).then(res => {
                if(res && res.payload && res.payload.message && res.payload.message.errors){
                    SetResponseError(res.payload.message.errors[0]);
                    SetResponseSuccess('')
                } else {
                    SetResponseError('');
                    SetResponseSuccess('Profile successfully updated')
                }
            });
        }
    }

    const getProfile =  async() => {
        
        await getUserProfile({token: user}).then(res => {
            // window.location.href= "/";
        })
    }

// console.log('profile', profile)
    return (
        <MusicDashboard>
        <div className="d-flex align-items-baseline sectionTitleHead mb-4">
            <h3 className="text-truncate text-uppercase">Account Setting</h3>
            </div>
            
            <AccountNav/>        

            <div className="content-box">
            <Card className="border-0 profileEditCard">
                <Card.Body>
                    <div className="imageBox d-flex mb-4">
                    { customer && customer.customerProfile && customer.customerProfile.profile_picture ? 
                        <figure style={{ backgroundImage: 'url('+customer.customerProfile.profile_picture+')', display: 'block', backgroundSize: 'cover' }}></figure>
                        : 
                        <figure></figure>
                    }
                    <aside className="pl-4">
                        <h4>{customer && customer.customerProfile ? customer.customerProfile.dj_name : ''}</h4>
                        {/* <p className="mb-3">Las Vegas, CA</p> */}
                        <div className="avatarAction">
                        <FilePond
                            files={files}
                            onupdatefiles={setFiles}
                            allowMultiple={true}
                            maxFiles={2}
                            server={uploadURL}
                            name="UploadFilesForm[files]" 
                            labelIdle='Change Avatar'
                            onprocessfile={(error, file) => {
                                // Set currently active file objects to this.state
                                getProfile(); 
                            }}
                        />
                        <a className="text-muted" href="#">Remove Image</a>
                        </div>
                    </aside>
                    </div>
                    { responseSuccess && responseSuccess !== '' ? <div className="alert alert-success">{responseSuccess}</div> : '' }
                    { responseError && responseError !== '' ? <div className="alert alert-danger">{responseError}</div> : ''}
                    <Form  validated={validated} onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail" >
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" name="firstname" defaultValue={customer.customerProfile.firstname} onChange={handleChange} isInvalid={ !!errors.firstname } />
                        <Form.Control.Feedback type="invalid">
                            { errors.firstname }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text" placeholder="Last Name" name="lastname" defaultValue={customer.customerProfile.lastname} onChange={handleChange} isInvalid={ !!errors.lastname } />
                        <Form.Control.Feedback type="invalid">
                            { errors.lastname }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control type="text" placeholder="Email" name="email" defaultValue={customer.customerProfile.email} onChange={handleChange} isInvalid={ !!errors.email } />
                        <Form.Control.Feedback type="invalid">
                            { errors.email }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control type="text" placeholder="Phone Number" name="telephone" defaultValue={customer.customerProfile.telephone} onChange={handleChange} isInvalid={ !!errors.telephone } />
                        <Form.Control.Feedback type="invalid">
                            { errors.telephone }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Address</Form.Label>
                        <Form.Control type="text" placeholder="Address" name="street" defaultValue={customer.customerProfile.street} onChange={handleChange} isInvalid={ !!errors.street } />
                        <Form.Control.Feedback type="invalid">
                            { errors.street }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>City</Form.Label>
                        <Form.Control type="text" placeholder="City" name="city" defaultValue={customer.customerProfile.city} onChange={handleChange} isInvalid={ !!errors.city } />
                        <Form.Control.Feedback type="invalid">
                            { errors.city }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Row>
                        <Col>
                        {
                            isCountrySelected ? 
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Country </Form.Label>
                                <Form.Control required as="select" type="select" name="country_id" onChange={handleChange} defaultValue={customer.customerProfile.country_id}>
                                    <option value="">Select country </option> 
                                    {
                                        countryList && countryList.length > 0 ?
                                        countryList.map((country, index)=>{
                                            return <option value={country.id} key={`country-${country.id}`}>{country.name}</option>
                                        })
                                        : ''
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    { errors.country_id }
                                </Form.Control.Feedback>
                            </Form.Group> :
                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Country </Form.Label>
                                <Form.Control required as="select" type="select" name="country_id" onChange={handleChange}>
                                    {/* <option value="">Some Invalid Option </option>  */}
                                    {
                                        countryList && countryList.length > 0 ?
                                        countryList.map((country, index)=>{
                                            return <option value={country.id} key={`country-${country.id}`}>{country.name}</option>
                                        })
                                        : ''
                                    }
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    { errors.country_id }
                                </Form.Control.Feedback>
                            </Form.Group>
                        }
                        
                        </Col>
                        {
                            region && region.length > 0 ?
                                <Col>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Label>State</Form.Label>
                                    <Form.Control required as="select" type="select" name="region_id" onChange={handleChange} defaultValue={customer.customerProfile.region_id}>
                                        <option value="">Select Region</option> 
                                        {
                                            region.map((reg, index)=>{
                                            return <option value={reg.id} key={`region-${reg.id}`}>{reg.name}</option>
                                            })
                                        }
                                    </Form.Control>
                                    <Form.Control.Feedback type="invalid">
                                        { errors.region_id }
                                    </Form.Control.Feedback>
                                </Form.Group>
                                </Col>
                            : ''
                        }
                    </Row>

                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>Zip Code</Form.Label>
                        <Form.Control type="text" placeholder="Zip Code" name="postcode" defaultValue={customer.customerProfile.postcode} onChange={handleChange} />
                        <Form.Control.Feedback type="invalid">
                            { errors.postcode }
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary"  full-width type="submit">
                        Update
                    </Button>
                    </Form>
                </Card.Body>
                </Card>
            </div>

        </MusicDashboard>
    );
}

function mapStateToProps(state) {
    return {
        customer: state.customer,
        countryList: state.common.countryList,
        user: state.authentication.user,
        authentication: state.authentication
    };
}

function mapDispatchToProps(dispatch) {
    return {
        getCountryList: () => dispatch(commonActions.getCountryList()),
        updateProfile: (post) => dispatch(customerAction.updateProfile(post)),
        getUserProfile: (token) => dispatch(customerAction.getCustomerProfile(token))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Profile);