import React, {useEffect, useState}  from "react";


import AccountNav from "../../elements/accountNav/accountNav";

import PaymentFailed from "../../assets/img/paymentFailed.png";
import Thankyou from "../../assets/img/thankyou-icon.png";
import payPal from "../../assets/img/paypal.svg";
import creditCard from "../../assets/img/credit-card.svg";
import appleGooglePayIcon from "../../assets/img/apple-google-icon-png.png";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'



import "./subscription.scss";


import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";


const SuccessPayment = ({displayLoading, hideLoading, customer}) => {

    let match = useRouteMatch("/subscription/choose-payment/:plan_id");
    const location = useLocation();
    const history = useHistory();
    const MySwal = withReactContent(Swal)
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [formData, setFormData] = useState({
        email: ''
    });

    useEffect(() => { 
        displayLoading();
        hideLoading();
        // MySwal.fire({
        //     icon: 'success',
        //     title: 'You have successfully subscribed to our newsletter',
        //     showConfirmButton: false,
        //     timer: 3000
        //   })
	},[])
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        }); 
        findFormErrors();
    }

    const findFormErrors = () => {
        const {  email } = formData
        const newErrors = {}
        // email errors        
        if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'


        return newErrors
    }
    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        // console.log(newErrors)
        if ( Object.keys(newErrors).length > 0 ) {
            console.log(newErrors)
            // We got errors!
            setErrors(newErrors)
        } else {
            // updateProfile(formData);
        }
    }

    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">                    
                    <Row className="justify-content-center">
                        <Col lg={4}>
                            <Card className="border-0 text-center">
                                <Card.Body>
                                    <img src={Thankyou} />
                                    <h3 className="mt-4">Upgrade Successful</h3>
                                    <p>Thank you for upgrading! Your plan was changed successful.We’ll send receipt to your email <b>{customer && customer.customerProfile ? customer.customerProfile.email : '' }</b>.You also find it in your <Link to={`/account/manage-subscription`}>subscription</Link></p>
                                    {/* <h6>Subcribe our newsletter to get the latest about<br/>new tracks &amp; promotions</h6>
                                    <Form  validated={validated} onSubmit={handleSubmit}>
                                        <InputGroup className="align-items-center mt-3 subscribeInput">
                                        <InputGroup.Text class="border-0 bg-none pl-3 "><i className="lnir lnir-envelope"></i></InputGroup.Text>
                                            <FormControl
                                            placeholder="Your Email Address"
                                            aria-label="Your Email Address"
                                            className=""
                                            type="email"
                                            name="email" 
                                            isInvalid={ !!errors.email }
                                            onChange={handleChange}
                                            />
                                            <Button type="submit" variant="outline-secondary">subscribe</Button>
                                        </InputGroup>
                                        
                                    </Form> */}
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        customer: state.customer,
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SuccessPayment);