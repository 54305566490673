import React, {useEffect, useState}  from "react";

//custom components

import PlayGroupPlayList from "../../elements/playgroup/playGroupPlaylist";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/playlist/actions';
import * as songActions from '../../store/song/actions';
import * as downloadActions from '../../store/download/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import "./recentPlayed.scss";

const RecentPlayed = ({ displayLoading, hideLoading, playLists, getPlayList, setCurrentSong, setIsPlaying, addSongToCrate, downloadSongVersion }) => {

	
	useEffect(() => { 
		// load Page Data
		loadPageData();
  	},[]);

  	const loadPageData = async() => {
		displayLoading();
    	await getPlayList();
		hideLoading();
  	}
	console.log('playLists', playLists)
	return ( <MusicDashboard>
			<div className="d-flex align-items-center sectionTitleHead">
				<h3 className="text-nowrap text-uppercase mb-0">Recently Played</h3>
				<div className="ml-auto">
					<span className="btn btn-primary rounded-circle">
						<i className="play-icon"></i>
					</span>
					<span className="btn btn-primary rounded-circle ml-3">
						<i className="play-icon"></i>
					</span>
				</div>
			</div>
			{ playLists && playLists.length > 0 ? <PlayGroupPlayList songs={playLists}   />  : ''}
			
		</MusicDashboard>
	);
}

function mapStateToProps(state) {
  return {
    playLists: state.playlist.playListSongs,
    currentSong: state.song.currentSong,
    isPlaying: state.song.isPlaying,
    crateSongs: state.song.customerCurates,
    userProfile: state.customer.customerProfile,
    playListIndex: state.playlist.playListIndex
  };
 }
 
 function mapDispatchToProps(dispatch) {
	return {
		getPlayList: () => dispatch(actions.getPlayList()),
		setCurrentSong: (song) => dispatch(songActions.setCurrentSong(song)),
		setIsPlaying: (bool) => dispatch(songActions.setIsPlaying(bool)),
		addSongToCrate: (songId) => dispatch(songActions.addSongToCrate(songId)),
		downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentPlayed);