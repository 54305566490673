import React, { useState, useEffect } from 'react';
import "./spotlight.scss";
import Slider from "react-slick";
import Dropdown from 'react-bootstrap/Dropdown';

import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';

import { Link }  from "react-router-dom"


const Spotlight =  ({ curatedItems, getCuratedItems}) => {

  const [items, setItems ] = useState(curatedItems);
  const [bigItems, setBigItems] = useState();
  const [smallItemsOne, setSmallItemsOne] = useState();

  useEffect(() => { 
	getCuratedItems();
	
   },[])

   useEffect(() => {
	if(curatedItems && curatedItems.length > 0){
		let bigImage = [curatedItems[0]];
		bigImage.push(curatedItems[5]);
		setBigItems(bigImage)
		
		let SmallImageOne = [curatedItems[1]];
		SmallImageOne.push(curatedItems[2]);
		SmallImageOne.push(curatedItems[3]);
		SmallImageOne.push(curatedItems[4]);
		SmallImageOne.push(curatedItems[6]);
		SmallImageOne.push(curatedItems[7]);
		SmallImageOne.push(curatedItems[8]);
		SmallImageOne.push(curatedItems[9]);
		SmallImageOne.push(curatedItems[10]);
		SmallImageOne.push(curatedItems[11]);
		setSmallItemsOne(SmallImageOne)
		// setBigItems(bgImages);
		
	  }
   }, [curatedItems])
  

  const renderBigBox = (bigItems) => {
	  return <div style={{ width: 442 }} className={'one-box'} >
				<div className="albumContain">
					<div className="albumBox" style={{backgroundImage: bigItems && bigItems.image ? `url(${bigItems.image})` : ''}}>
					
					<div className="hoverAction position-absolute align-items-end">
						<div className="d-flex justify-content-between flex-grow-1 align-items-center">{bigItems.title}
						{
							bigItems && bigItems.curated_list_id ? 
							<Link className="playBtn rounded-circle" to={`/spotlight/view/${bigItems.curated_list_id}`}><i className="play-icon"></i></Link>
							: ''
						}
						
						{/* <Dropdown className="dropNav">
						<Dropdown.Toggle>
						<i className="lnir lnir-more-alt"></i>
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
							<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
							<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
						</Dropdown.Menu>
						</Dropdown> */}
						</div>
					</div>  
					</div>
				</div>
			</div>
  }

  const renderSmallBox = (smallBox) => {
	  return  <div className="albumContain">
						<div className="albumBox" style={{backgroundImage: smallBox && smallBox.image ? `url(${smallBox.image})` : ''}}>
							<div className="hoverAction position-absolute align-items-end">
							<div className="d-flex justify-content-between flex-grow-1 align-items-center">
							{
								smallBox && smallBox.curated_list_id ? 
								<Link className="playBtn rounded-circle" to={`/spotlight/view/${smallBox.curated_list_id}`}><i className="play-icon"></i></Link>
								: ''
							}
							{/* <Dropdown className="dropNav">
								<Dropdown.Toggle>
								<i className="lnir lnir-more-alt"></i>
								</Dropdown.Toggle>
								<Dropdown.Menu>
								<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
								<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
								<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown> */}
							</div>
							</div>  
						</div>
					</div>
					
				
  }

  const renderSmallBoxSEcondStyle = (smallBox, smallBoxSecond) => {
	return <div style={{ width:221 }} className="two-box">
				<div className="albumContain">
					<div className="albumBox" style={{backgroundImage: smallBox && smallBox.image ? `url(${smallBox.image})` : ''}}>
						<div className="hoverAction position-absolute align-items-end">
						<div className="d-flex justify-content-between flex-grow-1 align-items-center">
						{
							smallBox && smallBox.curated_list_id ? 
							<Link className="playBtn rounded-circle" to={`/spotlight/view/${smallBox.curated_list_id}`}><i className="play-icon"></i></Link>
							: ''
						}
						{/* <Dropdown className="dropNav">
							<Dropdown.Toggle>
							<i className="lnir lnir-more-alt"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
							<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
							<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
							<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
						</div>
						</div>  
					</div>
				</div>
				<div className="albumContain">
					<div className="albumBox" style={{backgroundImage: smallBoxSecond && smallBoxSecond.image ? `url(${smallBoxSecond.image})` : ''}}>
						<div className="hoverAction position-absolute align-items-end">
						<div className="d-flex justify-content-between flex-grow-1 align-items-center">
						{
							smallBoxSecond && smallBoxSecond.curated_list_id ? 
							<Link className="playBtn rounded-circle" to={`/spotlight/view/${smallBoxSecond.curated_list_id}`}><i className="play-icon"></i></Link>
							: ''
						}
						{/* <Dropdown className="dropNav">
							<Dropdown.Toggle>
							<i className="lnir lnir-more-alt"></i>
							</Dropdown.Toggle>
							<Dropdown.Menu>
							<Dropdown.Item href="#/action-1">Action</Dropdown.Item>
							<Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
							<Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
						</div>
						</div>  
					</div>
				</div>
	</div>
  }

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: true,
    centerMode: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    variableWidth: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1000,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
    ]
  };
//   console.log(smallItemsOne.length)
  if(bigItems && smallItemsOne && smallItemsOne.length > 0 && bigItems.length > 0){
  return (
      <div>
        <div className="d-flex align-items-baseline sectionTitleHead">
          <h3>Curated Spotlight</h3>
          <div className="pl-5">
          <Link to={`/spotlight/index`} className="sellAll-link">See All <i className="lnir lnir-chevron-right"></i></Link>
          </div>
        </div>
        <div className="sliderBox c">
        {
			// curatedItems.map((item, index)=>{ 
			// 	if(index == 0){
			// 		return renderBigBox(curatedItems[0])
			// 	} else if(index == 2) {
			// 		return <div style={{ width:221 }} className="two-box">
			// 		{
			// 			renderSmallBox(curatedItems[1])
						
			// 		}
			// 		{
			// 			renderSmallBox(curatedItems[2])
			// 		}
			// 		</div>
			// 	} else if(index ==3) {

			// 	} else if (index == 4){
			// 		return <div style={{ width:221 }} className="two-box">
			// 			{
			// 				renderSmallBox(curatedItems[3])
			// 			}
			// 			{
			// 				renderSmallBox(curatedItems[4])
			// 			}
			// 		</div>
			// 	} else if(index == 5){

			// 	} else if(index == 6){
			// 		return <div style={{ width:221 }} className="two-box">
			// 			{
			// 				renderSmallBox(curatedItems[5])
			// 			}
			// 			{
			// 				renderSmallBox(curatedItems[6])
			// 			}
			// 		</div>
			// 	} else if(index == 7){

			// 	} else if(index == 8){
			// 		return renderBigBox(curatedItems[7])
			// 	} else if(index == 9) {
			// 		return <div style={{ width:221 }} className="two-box">{
			// 			renderSmallBox(curatedItems[8])
						
			// 		}
			// 		{
			// 			renderSmallBox(curatedItems[9])
			// 		}
			// 		</div>
			// 	} else if(index ==10) {

			// 	} else if (index == 11){
			// 		return <div style={{ width:221 }} className="two-box">
			// 			{
			// 				renderSmallBox(curatedItems[10])
			// 			}
			// 			{
			// 				renderSmallBox(curatedItems[11])
			// 			}
			// 		</div>
			// 	}

			// })
			// curatedItems && curatedItems.length > 0 &&  smallItemsOne && smallItemsOne.length > 0 &&  bigItems && bigItems.length > 0 ? 
		  	<Slider {...settings}>
		  	{
				bigItems && bigItems.length > 0 ?	
					renderBigBox(bigItems[0])
				:
				''
			}
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[0], smallItemsOne[1]) 
					// : ''
			}
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[2], smallItemsOne[3]) 
				// : '' 
			}
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[4], smallItemsOne[5]) 
				// : ''
			}
			{
				bigItems && bigItems.length > 1 ?	
					renderBigBox(bigItems[1])
				: ''
				
			}
			
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[6], smallItemsOne[7]) 
				// : ''
			}
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[8], smallItemsOne[9]) 
				// : ''
			}
			{ 
				// smallItemsOne && smallItemsOne.length > 0 ? 
				renderSmallBoxSEcondStyle(smallItemsOne[10], smallItemsOne[11]) 
				// : ''
			}
          </Slider>
		 // : ''
		}
          </div>
      </div>
	);
  } else {
	  return <div>Loading....</div>
  }
}

function mapStateToProps(state) {
  return {
   curatedItems: state.curated.homeCuratedList
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getCuratedItems: (orderId) => dispatch(actions.getHomeCuratedLists()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Spotlight);
