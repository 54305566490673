import React, {useEffect, useState} from "react";

//custom components
import Badge from "react-bootstrap/Badge";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import PlayGroup from "../../elements/playgroup/playgroup";
import RightSide from "../../components/rightSide/rightSide";

import { showLoading, hideLoading } from 'react-redux-loading-bar';

import Pagination from "react-js-pagination";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from 'react-bootstrap/Button'
import "./searchResultMp3.scss";
import { useLocation, useRouteMatch } from "react-router-dom";
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';
import * as versionAction from '../../store/version/actions'


const  SearchResultMp3 = ({getSearchResultSongs, searchResult, paginationData, displayLoading, hideLoading, genres, getGenresWithCount, versions, getVersions }) => {
  let match = useRouteMatch("/search/result/:searchTerm");
  const location = useLocation();
  const [ page, setPage ] = useState(1);
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [ songSectionId, setSongSectionId ] = useState(1);
  const [currentGenreId, setCurrentGenreId] = useState();
  const [ searchTerm, setSearchTerm ] = useState();
  const [ searchModel , setSearchModel ] = useState({
    bpm: '',
    song_key: '',
    year: '',
    title: '',
    artist: '',
  })
  const [ selectedVersion, setSelectedVersion ] = useState();
  
  
  useEffect(() => { 
    // load Page Data
    loadPageData();
  },[]);
  
  const loadPageData = async() => {
    displayLoading();
    if(match){
      setSearchTerm(match.params.searchTerm);
      await getSearchResultSongs({page: page, order: order, orderDir, song_section_id: songSectionId, searchTerm: match.params.searchTerm});
    }
    setCurrentGenreId('all');
    setSelectedVersion('0');
   
    await getVersions();
    await getGenresWithCount();

    
    hideLoading();
    
		
  }
  const handlePageChange = (pageNumber) => {
      setPage(pageNumber);
      
      displayLoading();
      if(match){
        if(currentGenreId === 'all'){
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
          })
        } else {
          getSearchResultSongs({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
            hideLoading();
          })
        }
      }
      
  }

  const genresSong = (genre_id) => {
    setCurrentGenreId(genre_id)
    displayLoading();
    if(match){
      getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, genre_id: genre_id, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
      })
    }
  }
  const getAllSong = () => {
    setCurrentGenreId('all')
    displayLoading();
    getSearchResultSongs({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
      hideLoading();
    })
  }

  const handleChangeSearchTerm = (event) => {
    setSearchTerm(event.target.value);
    
  }

  const handleChangeSearch = (e) => {
    const { name, value } = e.target;
        setSearchModel({
            ...searchModel,
            [name]: value
        });
  }

  const filterResult = async () => {
    displayLoading();
    if(currentGenreId === 'all'){
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
      })
    } else {
      getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: selectedVersion}).then((res) => {
        hideLoading();
      })
    }
  }

  const getAllVersionSong = async(versionId) => {
    displayLoading();
    setSelectedVersion(versionId);
   
    getSearchResultSongs({page: 1, order: order, orderDir, song_section_id: songSectionId, searchTerm: searchTerm, SongEntitySearch: searchModel, versions: versionId}).then((res) => {
      hideLoading();
    })
  }

  return (
  <MusicDashboard rightContent={<RightSide blocks={{trendingTracks: true }} />}>
      <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Search Result</h3>
      </div>
      <div>
        <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter Result By</span> <Button variant="light" className="btn btn-light primary-bg" onClick={() => filterResult()}>Apply Filter</Button></div>
        <Row>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search for songs or anything..."
                  aria-label="Search"
                  aria-describedby="Search"
                  onChange={handleChangeSearchTerm}
                  value={searchTerm}
                />
            </InputGroup>
          </Col>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by song name"
                  aria-label="songTitle"
                  aria-describedby="songTitle"
                  onChange={handleChangeSearch}
                  value={searchModel.title}
                  name="title"
                />
            </InputGroup>
          </Col>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by artist"
                  aria-label="artist"
                  aria-describedby="artist"
                  onChange={handleChangeSearch}
                  value={searchModel.artist}
                  name="artist"
                />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by BPM"
                  aria-label="BPM"
                  aria-describedby="BPM"
                  onChange={handleChangeSearchTerm}
                  value={searchModel.bpm}
                  name="bpm"
                />
            </InputGroup>
          </Col>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by year"
                  aria-label="songTitle"
                  aria-describedby="songTitle"
                  onChange={handleChangeSearch}
                  value={searchModel.year}
                  name="year"
                />
            </InputGroup>
          </Col>
          <Col lg={4}>
          <InputGroup className="mb-3">
                <Form.Control
                  placeholder="Search by song key"
                  aria-label="artist"
                  aria-describedby="artist"
                  onChange={handleChangeSearch}
                  value={searchModel.song_key}
                  name="song_key"
                />
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
          <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Genre</span></div>
          { genres && genres.length > 0 ?
            <div className="badgeList d-none d-lg-block mb-4">
                <Badge variant={currentGenreId === 'all' ? 'active' : '' } onClick={() => getAllSong('0')}>All</Badge>
                {
                  genres.map((genre, index)=>{
                    return <Badge variant={currentGenreId === genre.genre_id ? 'active' : '' } key={`genre-filter-${genre.genre_id}`} onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html:genre.name}}></Badge>
                  })
                }
            </div>
            : ''
          }
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
          <div className="cardMark mb-3 d-flex justify-content-between align-items-center"><span>Filter By Version</span></div>
          { versions && versions.length > 0 ?
            <div className="badgeList d-none d-lg-block mb-4">
                <Badge variant={selectedVersion === '0' ? 'active' : '' } onClick={() => getAllVersionSong('0')}>All</Badge>
                {
                  versions.map((version, index)=>{
                    return <Badge variant={selectedVersion === version.version_id ? 'active' : '' } key={`version-filter-${version.version_id}`} onClick={() => getAllVersionSong(version.version_id)}>{version.name}</Badge>
                  })
                }
            </div>
            : ''
          }
          </Col>
        </Row>

        <PlayGroup songs={searchResult}   /> 

        
        
        <div className="pagin text-center mt-5 mb-5">
          {
          paginationData ? 
            <Pagination
                  activePage={paginationData.page}
                  itemsCountPerPage={paginationData.perPageLimit}
                  totalItemsCount={paginationData.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>


      </div>
    </MusicDashboard>
  );
}


function mapStateToProps(state) {
  return {
    searchResult: (state.song.searchResult && state.song.searchResult.songs ) ? state.song.searchResult.songs : [],
    paginationData:  (state.song.searchResult && state.song.searchResult.pagination) ? state.song.searchResult.pagination : '',
    genres: state.genre.genresWithCount,
    top20Albums: state.song.top20Albums,
    versions: state.version.versions
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getSearchResultSongs: (post) => dispatch(songActions.getSearchResultSongs(post)),
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getVersions: () => dispatch(versionAction.getVersions())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SearchResultMp3);