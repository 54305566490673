import React, {useEffect, useState} from "react";


import AccountNav from "../../elements/accountNav/accountNav";


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Spinner from 'react-bootstrap/Spinner';
import "./helpdesk.scss";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import config from '../../config/config';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import SubmitTicket from "./submitTicket";
import RecentTickets from "./recentTickets";
import { useLocation, useRouteMatch } from "react-router-dom";

import * as helpdeskAction from '../../store/helpdesk/actions'



const ViewTicket = ({displayLoading, hideLoading, getTicket, ticket, submitReply, uploadAttachments, uploadedAttachments}) => {
    let match = useRouteMatch("/ticket/details/:ticketNumber");
    const location = useLocation();

    const [ page, setPage ] = useState();
    const [formData, setFormData] = useState({
        details: '',
        ip: '',
        ticket_number: match.params.ticketNumber,
        attachments: ''
    });
    const [ attachments, setAttachments ] = useState();
    const [fileMessage, setFileMessage] = useState();
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [ isSubmitted, setIsSubmitted ] = useState(false);

    useEffect(() => { 
        loadPageData();
    },[location]);

    const loadPageData = async() => {
        displayLoading();
        await getTicket({ticket_number: match.params.ticketNumber, page: 1, pageLimit: 10}, () => {
            
        });
        hideLoading();
    }

    const handlePageChange = (pageNumber) => {
        displayLoading();
        getTicket({ticket_number: match.params.ticketNumber, page: pageNumber, pageLimit: 10}, () => {
            // this.setState({genres: this.props.song.latestSong});
        });
        hideLoading();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });   
    }

    const findFormErrors = () => {
        const { details } = formData
        const newErrors = {}
        
        if ( !details || details === '' ) newErrors.details = 'Message cannot be blank!'

        return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            sendReply(formData);
        }
    }
    // console.log('ticket', ticket)
    const sendReply = async(data) => {
        displayLoading();
        setIsSubmitted(true);
        if(attachments && attachments.length > 0){
            const formDataCustom = new FormData();
            for(let i=0; i<attachments.length ;i++){
                formDataCustom.append('UploadAttachmentsForm[attachments]['+i+']', attachments[i]);
            }
            
            // formDataCustom.append('details', formData.details);
            // formDataCustom.append('ticket_number', formData.ticket_number); 
            await uploadAttachments(formDataCustom).then((res)=> {
                if(res && res.payload && res.payload.data &&  res.payload.data.UploadAttachmentsForm && res.payload.data.UploadAttachmentsForm.name && res.payload.data.UploadAttachmentsForm.name.attachments){
                    postReply(res.payload.data.UploadAttachmentsForm.name.attachments)
                }
                
            })
            // if(uploadedAttachments.UploadAttachmentsForm.name);
            // formData.attachments = uploadedAttachments.UploadAttachmentsForm.name.attachments
            // await submitReply(formData, ()=>{
                
            // });
        } else {
            await submitReply(formData, ()=>{
                
            });
        }
        setIsSubmitted(false);
        hideLoading();
    }

    const postReply = (attachments) => {
        formData.attachments = attachments;
        submitReply(formData, ()=>{
                
        });
    }

    const changeFile = (event) => {
        let files = event.target.files,
            value = event.target.value,
            message;
        if( files && files.length > 1 ){
            message = `${files.length} files selected`;
        } else{
            message = value.split( '\\' ).pop();
        }   
        if(message){
            setFileMessage(message);   
        }  
        setAttachments(event.target.files);   
        // console.log(event.target.files)              
    }
    // console.log(ticket.replies)
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Helpdesk</h3>
            </div>
            <AccountNav/>        
            <div className="content-box">
            <Container fluid className="p-0">
                <Row>
                    <Col lg={12}>
                        <Card className="border-0 h-100">
                            <Card.Body>
                                { ticket && ticket.ticket ? 
                                <div>
                                    <Row className="ticket-summary">
                                        <Col>
                                            <div className="text-muted text-uppercase">Ticket Number</div>
                                            <div className="text-bold mb-2">{ticket.ticket.ticket_number}</div>
                                            
                                        </Col>
                                        <Col>
                                            <div className="text-muted text-uppercase">Status</div>
                                            <div className="text-bold mb-2">{ticket.ticket.status}</div>
                                        </Col>
                                        <Col>
                                            <div className="text-muted text-uppercase">Last Updated</div>
                                            <div className="text-bold mb-2">{ticket.ticket.modified_at}</div>
                                        </Col>
                                    </Row>
                                    <Row className="ticket-summary mt-4">
                                        <Col>
                                            <div className="text-muted text-uppercase">Department</div>
                                            <div className="text-bold mb-2">{ticket.ticket.department}</div>
                                            
                                        </Col>
                                        <Col>
                                            <div className="text-muted text-uppercase">Priority</div>
                                            <div className="text-bold mb-2">{ticket.ticket.priority}</div>
                                        </Col>
                                        <Col>&nbsp;</Col>
                                    </Row>
                                </div>
                                : ''
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={7}>
                        <Card className="border-0">
                            <Card.Body>
                                <h5 className="text-uppercase">Reply your ticket </h5>
                                <Form  validated={validated} onSubmit={handleSubmit}>
                                    <Form.Group className="mb-3" controlId="formDetails" >
                                        <Form.Label>Message</Form.Label>
                                        <Form.Control as="textarea" placeholder="Please enter your message. A member of our support staff will responde as soon as possible" style={{ height: '100px' }} name="details"  onChange={handleChange} isInvalid={ !!errors.details } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.details }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formFileLg" className="mb-3">
                                        <Form.Label>Attachments</Form.Label>
                                        <Form.Control type="file" size="lg" onChange={(e) => {
                                                                            changeFile(e)
                                                                        }} />
                                    </Form.Group>
                                    <div>
                                        {
                                            isSubmitted ? <Button variant="dark" className="btn-block p-3 text-uppercase"><Spinner animation="border" size="lg" variant="warning" /></Button>
                                            : <Button type="submit" variant="light" className="primary-bg btn-block p-3 text-uppercase">Submit your reply</Button>
                                        }
                                        
                                    </div>
                                </Form>
                            </Card.Body>
                        </Card>
                        <Card className="mt-4 border-0">
                            <Card.Body>
                            {
                                ticket && ticket.replies && ticket.replies.length > 0 ?
                                ticket.replies.map((reply) => {
                                    return <div className={reply.admin_id === 0 ? 'reply-msg reply-customer' : 'reply-msg reply-admin'} key={`reply${reply.id}`} >
                                                <div className="reply-content">
                                                    <div className={reply.admin_id === 0 ? "" : ""} dangerouslySetInnerHTML={{__html: reply.details}} />
                                                    {
                                                        reply.attachments && reply.attachments.length > 0 ?
                                                            <div className="reply-attachments mt-5">
                                                                <span className="icon-attachment mr-3"></span>
                                                                {
                                                                    reply.attachments.map((attachment, a) => {
                                                                        return <span key={'attachment-'+a} className="mr-3">
                                                                            <a href={`${config.downloadSelfUrl}${attachment.file_path}`} target="_blank">{attachment.filename}</a>
                                                                        </span>
                                                                    })
                                                                }
                                                            </div>
                                                        : ''
                                                    }
                                                </div>
                                                <div className="reply-date">{reply.created_at}</div>
                                        </div>
                                })
                            : ''
                        }
                   
                        <div className="pagin text-center mt-5">
                        {
                        ticket && ticket.pagination ? 
                            <Pagination
                                activePage={ticket.pagination.page}
                                itemsCountPerPage={ticket.pagination.perPageLimit}
                                totalItemsCount={ticket.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                        </div>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col lg={5}>
                        <RecentTickets />
                        {/* <SubmitTicket /> */}
                    </Col>
                </Row>
            </Container>
            </div>
    
        </MusicDashboard>
      );
}
function mapStateToProps(state) {
    return {
        ticket: state.helpdesk.ticketDetail,
        uploadedAttachments: state.helpdesk.attachments
    };
}

function mapDispatchToProps(dispatch) {
    return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getTicket: (post) => dispatch(helpdeskAction.getTicket(post)),
        submitReply: (post) => dispatch(helpdeskAction.submitReply(post)),
        uploadAttachments: (post) => dispatch(helpdeskAction.uploadAttachments(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewTicket);