import React , {useState} from "react";
import "./ContentPage.scss";


//custom components ----------------------------------------------------------------
import NavBar from "../../components/navBar/navBar";
import RightSide from "../../components/rightSide/rightSide";
import Header from "../../components/header/header";
import Player from "../../components/player/player";


import { Scrollbars } from 'react-custom-scrollbars';

import LoginModal from '../../elements/loginModal/loginModal';
import SignUpModal from '../../elements/signUpModal/signUpModal';

import ContentHeader from "../../components/contentHeader/contentHeader";
import ContentFooter from "../../components/contentFooter/contentFooter";

const ContentPage = ({children}) => {

  const [show, setShow] = useState(false);
  const [signUpshow, signUpsetShow] = useState(false);

  return (
    <div className="App main-page">
      <ContentHeader/>
      {children}
      <ContentFooter/>
      </div>
  );
};

export default ContentPage;
