import * as types from './type';


export function addSongVersionToPlayList(song, version) {
    const songVersion = {
          songData: song,
          versionData: version,
          id: song.entity_id,
          version: version.song_version_id,
          song_version_id: version.song_version_id,
          name: song.title,
          singer: song.artist,
          cover:
            "http://res.cloudinary.com/alick/image/upload/v1502689731/Despacito_uvolhp.jpg",
          musicSrc: version.mp3,
    }
    return {
      type: types.ADD_SONG_TO_PLAYLIST,
      payload: songVersion,
    };
};



export function getPlayList() {
    return {
      type: types.GET_CUSTOMER_PLAYLIST
    };
};

export function setPlayListIndex(index){
  return {
    type: types.SET_PLAYLIST_INDEX,
    payload: index
  }
}

