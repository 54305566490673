import React, {useState, useEffect} from "react";


import AlbumImg from "../../assets/img/album-img.png";
import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button'


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
// import RightSide from "../../components/rightSide/rightSide";
import "./favourite.scss";

import config from '../../config/config';
import { Link } from "react-router-dom";
import PlayGroup from "../../elements/playgroup/playgroup";
import {connect} from 'react-redux';
import * as crateActions from '../../store/crate/actions';
import * as songActions from '../../store/song/actions';
import LoadingBar from 'react-redux-loading-bar'
import { showLoading, hideLoading } from 'react-redux-loading-bar'

import RightSide from "../../components/rightSide/rightSide";


const FavouriteSongs = ({getCustomerFavouriteSongs, customerFavSongs, favouriteSongs, getFavouriteSongs, displayLoading, hideLoading}) => {
  const [page, setPage] = useState(1);
  const perPageLimit =  config.itemsCountPerPage;
  
  

  useEffect(() => { 
    loadPageData()
  },[]);
  
  const loadPageData = async () => {
    displayLoading()
    await getFavouriteSongs();
    await getCustomerFavouriteSongs({page: page, pageLimit: perPageLimit}, () => {
        // this.setState({genres: this.props.song.latestSong});
    })
    
    hideLoading();
  }

  

  

  return (
    <MusicDashboard rightContent={<RightSide blocks={{ recentPlayedSong: true, nowPlaying: true, suggestedCollection: true}} />}>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Favourite Songs</h3>
        </div>
        <hr className="alignHr"/>
        

        
          {
              customerFavSongs && customerFavSongs.songs && customerFavSongs.songs.length > 0 ?
              <PlayGroup songs={customerFavSongs.songs}    /> 
              : ''
          }
        
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    customerFavSongs: state.song.customerFavouriteSongs,
    favouriteSongs: state.song.favouriteSongs  
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getFavouriteSongs: () => dispatch(songActions.getFavouriteSongs()),
      getCustomerFavouriteSongs: () => dispatch(songActions.getCustomerFavouriteSongs()),
      displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FavouriteSongs);