import React, { useState, useEffect } from "react";

import DownloadVersionButton from '../actionBtn/downloadVersion';
import FavouriteButton from '../actionBtn/addToFavourite'
import AlbumImg from '../../assets/img/album-img.png';
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';

const NowPlaying = ({ currentSong, setIsPlaying }) => {

	const [song, setSong] = useState();

    useEffect(() => { 
		setSong(currentSong);
		console.log('ddd')
    },[currentSong])
      console.log('currentSong', currentSong)
      if(song){
		return (
			<aside>
					<h3>Now Playing</h3>
					<div className="d-flex bg-white p-2 text-dark trackList align-items-center mt-4 playGroup">
						<div className="img">
							<figure className="">
								<img src={AlbumImg} height="60"></img>
								{/* <i className="icon play-icon"></i> */}
							</figure>
						</div>
						<div className="info">
							<h6 dangerouslySetInnerHTML={{__html: song.songData.title}}></h6>
							<p dangerouslySetInnerHTML={{__html: song.songData.artist}}></p>
						</div>
						<div className="download-link d-flex action">
							<FavouriteButton song={song.songData} />
							<DownloadVersionButton song={song.songData} version={song.versionData} />
						</div>
					</div>
				</aside>
		)
    } else {
		return '';
	}
}



function mapStateToProps(state) {
    return {
      currentSong: state.song.currentSong,
      isPlaying: state.song.isPlaying,
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
	  setIsPlaying: (bool) => dispatch(songActions.setIsPlaying(bool)),
	  setCurrentSong: (song) => dispatch(songActions.setCurrentSong(song)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NowPlaying);