import React, { useEffect, useState } from "react";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/top20/actions';

//custom components
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import "./top20.scss";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";

const Top20Index = ({getTop20AlbumsForMainPage, albums, displayLoading, hideLoading }) => {

    const [ page, setPage ] = useState(1);

    useEffect(() => { 
		loadPageData();
	},[])

	const loadPageData = async() => {
		displayLoading();
		await getTop20AlbumsForMainPage({page: 1, limit: 30, pagination: true});
		hideLoading();
    }

    const handlePageChange = async (pageNumber) => {
		displayLoading();
		setPage(pageNumber);
		await getTop20AlbumsForMainPage({page: pageNumber, limit: 30, pagination: true});
		hideLoading();
	}
    
    const renderBoxContent = (i) => {
        if(albums.albums[i] && albums.albums[i].top20_track_id){
            return <div className="albumGroup" key={`top20-${albums.albums[i].top20_track_id}`}>
                        <Link to={`/top20/songs/${albums.albums[i].top20_track_id}`}><figure style={{backgroundImage: albums.albums[i].cover_image ? `url(${albums.albums[i].cover_image})` : ''}}></figure></Link>
                        <h6><Link to={`/top20/songs/${albums.albums[i].top20_track_id}`}>{albums.albums[i].title}</Link></h6>            
                    </div>
        } else {
            return <div className="albumGroup hide">&nbsp;</div>;
        }
	}

	const boxHtml = () => {
		let rows = [];
		for(var i = 0; i < 5; i++){
			let squares = [];
			for(var j = 0; j < 4; j++){
				squares.push(renderBoxContent(4*i+j));
			}
			rows.push(<div  className="d-flex mb-4">{squares}</div>);
		}
		return rows;
	}

    return (
    <MusicDashboard rightContent={<RightSide blocks={{ suggestedCollection: true }} />}>
        <div className="d-flex align-items-baseline sectionTitleHead mb-5">
            <h3 className="text-truncate text-uppercase">Top 20 Tracks</h3>
        </div>
        {
            albums && albums.albums && albums.albums.length > 0 ?
            <div>{boxHtml()}</div>
            : ''
        }
        <div className="pagin text-center mt-5 mb-5">
			{
			albums && albums.pagination ? 
				<Pagination
					activePage={albums.pagination.page}
					itemsCountPerPage={albums.pagination.perPageLimit}
					totalItemsCount={albums.pagination.totalCount}
					// pageRangeDisplayed={pageRangeDisplayed}
					onChange={handlePageChange}
					itemClass="page-item"
					linkClass="page-link"
			/> : ''
			}
        </div>
    </MusicDashboard>
    )

}
function mapStateToProps(state) {
    return {
        albums: state.top20.albums
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        getTop20AlbumsForMainPage: (data) => dispatch(actions.getTop20AlbumsForMainPage(data)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(Top20Index);