import React, { useState, useEffect } from "react";

import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as songActions from '../../store/song/actions';

const CrateButton = ({addSongToCrate, getCustomerCrates, song, crateSongs, version }) => {

    const [ downloadInProcess, setDownloadInProcess ] = useState(false);

    const addRemoveSongToCrate = async(song, version) => {
        
        setDownloadInProcess(true);
        await addSongToCrate(song.entity_id, version.song_version_id);
        await getCustomerCrates();
        setDownloadInProcess(false)
    }

    

    if(downloadInProcess){
        return <span className="pointer"><Spinner animation="border" size="sm" variant="warning" /></span>
    } else {
        return (
            // <span onClick={() => addSongToFavourite(song)} className={ crateSongs && crateSongs.song && crateSongs.song.length > 0 && ((crateSongs.song.indexOf(song.entity_id.toString()) > -1) ) ? 'active pointer' : 'pointer'}>
            //     <i className="lnir lnir-heart"></i>
            // </span>
            <span className="ml-3" onClick={() => addRemoveSongToCrate( song,  version)}>
                <i className={crateSongs && crateSongs.version && crateSongs.version.length > 0 && ((crateSongs.version.indexOf(version.song_version_id.toString()) > -1) ) ? 'active lnir lnir-package' : 'lnir lnir-package'}></i>
            </span>
        )
    }

}

function mapStateToProps(state) {
    return {
        crateSongs: state.song.customerCurates,
    };
}
   
function mapDispatchToProps(dispatch) {
    return {
        addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
        getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(CrateButton);
  