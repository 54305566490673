import React, { useEffect,useState} from "react";
import PlayGroup from "../../elements/playgroup/playgroup";
import Pagination from "react-js-pagination";
import Announcement from "../../elements/Announcement/announcement";
import { useLocation, useRouteMatch } from "react-router-dom";
import { Link } from "react-router-dom";

import "./genres.scss";
import RightSide from "../../components/rightSide/rightSide";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import loadingImage from '../../assets/img/loading.gif'
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as genreActions from '../../store/genres/actions';

const  GenreView = ({getGenresWithCount, genresWithCount, getGenreSongs, genresData, displayLoading, hideLoading}) => {


    let match = useRouteMatch("/genres/view/:genre_id");
    const location = useLocation();

    const [genreId, setGenreId] = useState();
    const [ page, setPage ] = useState(0);
    const [ order, setOrder ] = useState('entity_id');
    const [ orderDir, setOrderDir ] = useState('DESC');
    const [ songSectionId, setSongSectionId ] = useState(1);
    const [dataProcessing, setDataProcessing] = useState();

    useEffect(() => { 
      displayLoading();
        setGenreId(match.params.genre_id)
        // if(match.params.genre_id == genreId){
            // console.log('call when genre id change in url', genreId)
            getGenreSongs({page: page, order: order, orderDir: orderDir, song_section_id: songSectionId, genre_id: match.params.genre_id}).then((res) => {
              hideLoading();
            })
        // }
    },[location]);

    useEffect(() => {
        
        getGenresWithCount();
    }, [])

    const handlePageChange = async (pageNumber) => 
    {
        displayLoading(); 
        setPage(pageNumber);
        await getGenreSongs({page: pageNumber, order: order, orderDir: orderDir, song_section_id: songSectionId, genre_id: genreId});
        hideLoading();
    }

  return (
    <MusicDashboard rightContent={<RightSide blocks={{mostDownloaded: true, trendingTracks: true }} />}>
      <Announcement page_name="mp3_genres" />
      <div>
        <div className="d-flex align-items-baseline sectionTitleHead mb-3">
          <h3 className="text-truncate text-uppercase">Moods</h3>
        </div>
        <div className="d-flex flex-wrap mb-4">
        {
          genresWithCount && genresWithCount.length > 0 ?
            genresWithCount.map((genre, index) => {
              return <div className="albumGroup" key={`genresimage-${genre.genre_id}`} style={{width: '15%'}}>
                      <Link to={`/genres/view/${genre.genre_id}`}><figure style={{backgroundImage: `url(${genre.image})`, backgroundSize: 'cover'}}></figure></Link>
                      <h6>
                        <Link to={`/genres/view/${genre.genre_id}`} dangerouslySetInnerHTML={{__html: genre.name}}></Link>
                      </h6>
                    </div>
                    // {(index+1)%6 == 0 ? </div></div><div className="d-flex mb-4"> : ''}
            })
          : ''
          }
        </div>
        
        <div className="d-flex align-items-baseline sectionTitleHead mb-4 mt-5">
          <h3 className="text-truncate text-uppercase">Genres {genresData && genresData.current_genre ? <span dangerouslySetInnerHTML={{__html: genresData.current_genre.genre_name}}></span> : ''}</h3>
        </div>
        {
            genresData && genresData.songs && genresData.songs.length > 0 ?
                <PlayGroup songs={genresData.songs}   /> 
            : ''
        }
        <div className="pagin text-center mt-5 mb-5">
          
          {
          genresData && genresData.pagination ? 
            <Pagination
                  activePage={genresData.pagination.page}
                  itemsCountPerPage={genresData.pagination.perPageLimit}
                  totalItemsCount={genresData.pagination.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>
      </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    genresWithCount: state.genre.genresWithCount,
    genresData: state.genre.genresData
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    getGenreSongs: (data) => dispatch(genreActions.getGenreSongs(data)),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(GenreView);