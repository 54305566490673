import React, { Component } from "react";
import "./contentFooter.scss";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import FormControl from 'react-bootstrap/FormControl';
import Button from 'react-bootstrap/Button'
import Dropdown from "react-bootstrap/Dropdown";

import logo from "../../assets/img/club_killer_log.png";

class ContentFooter extends Component {
  render() {
    return (
      <div className="content-footer pt-5 mt-5">
        <div className="container">
          <div className="row">
            <aside className="col-md-4">
              <p>Club Killers has been one of the leading DJ record pools in the world for some time now. Our musics are exclusive and always full of energy. This energy created by our team of super-productive editors.</p>
            </aside>
            <aside className="col-md-3 ml-auto">
              <div className="row">
                <aside className="col-md-6">
                  <ul className="nav flex-column">
                    <li><a href="#">Store</a></li>
                    <li><a href="#">Blog</a></li>
                    <li><a href="#">CK Radio</a></li>
                    <li><a href="#">Career</a></li>
                    <li><a href="#">Contact</a></li>
                  </ul>
                </aside>
                <aside className="col-md-6">
                  <ul className="nav flex-column">
                    <li><a href="#">Term &amp; Conditions</a></li>
                    <li><a href="#">Privay</a></li>
                    <li><a href="#">PolicyDJ Mixed</a></li>
                  </ul>
                </aside>
              </div>
            </aside>
            <aside className="col-md-3">
              <p>Subscribe our newsletter to get the latest news and insights</p>
              <InputGroup className="mb-3 inputTxt">
              <InputGroup.Text id="basic-addon1"><i className="lnir lnir-envelope"></i></InputGroup.Text>
                <FormControl
                  placeholder="Email address"
                  aria-label="Email address"
                  aria-describedby="basic-addon2"
                />
                <Button variant="outline-secondary" id="button-addon2">
                subscribe
                </Button>
              </InputGroup>
              <p><small>By subscribing, you accept the <a href=""><span>Privacy Policy</span></a></small></p>              
            </aside>
          </div>
          <div className="row justify-content-between pt-4 pb-4">
            <aside className="col-md-5">
              &copy; 2021 <b>Club Killers</b>. All Rights Reserved. Design by <a href="#">Logan Cee</a>              
            </aside>
            <aside className="col-md-3 fsocilLink">
              <a href="#"><i className="lnir lnir-twitter-filled"></i></a>
              <a href="#"><i className="lnir lnir-github-original"></i></a>
              <a href="#"><i className="lnir lnir-facebook-filled"></i></a>
              <a href="#"><i className="lnir lnir-dribbble"></i></a>
              <a href="#"><i className="lnir lnir-instagram"></i></a>
            </aside>
          </div>
        </div>
      </div>
    );
  }
}

export default ContentFooter;
