import React, { Component } from "react";
import "./contentHeader.scss";

import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";

import logo from "../../assets/img/club_killer_log.png";

class ContentHeader extends Component {
  render() {
    return (
      <div className="container d-flex justify-content-between align-items-center content-header pt-3 pb-4">
        <div>
          <img src={logo} />
        </div>
        <aside>
          <ul className="nav">
            <li><a href="#">Store</a></li>
            <li><a href="#">Blog</a></li>
            <li><a href="#">CK Radio</a></li>
            <li><a href="#">Career</a></li>
            <li><a href="#">Contact</a></li>
            <li className="ml-5"><a href="#"><i class="lnir lnir-user"></i> Login</a></li>
          </ul>
        </aside>
      </div>
    );
  }
}

export default ContentHeader;
