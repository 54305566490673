import React, { Component } from "react";
import "./navBar.scss";
import logo from '../../assets/img/club_killer_log.png'

import Nav from "react-bootstrap/Nav";
import Button from 'react-bootstrap/Button';


import {
Link
} from "react-router-dom";



class NavBar extends Component {
  render() {
    return (
      <div className="navSide border-right">
        <figure className="logo mb-5">
          <img src={logo} />
        </figure>
        <Nav defaultActiveKey="/" activeKey="/" className="flex-column">
        <Nav.Item>
            {/* <Link  to="/"><i className="lnir lnir-record"></i> Explore</Link>
            <Link  to="/charts"><i className="lnir lnir-graph-alt-3"></i> Charts</Link> */}
            <Nav.Link as={Link} to="/"><i className="lnir lnir-record"></i> Explore</Nav.Link>
            <Nav.Link as={Link} to="/charts"><i className="lnir lnir-graph-alt-3"></i> Charts</Nav.Link>
            <Nav.Link as={Link} to="/newRelease"><i className="lnir lnir-music"></i> New Releases</Nav.Link>
            <Nav.Link as={Link} to="/genres/index"><i className="lnir lnir-slideshow"></i> Genres</Nav.Link>
            <Nav.Link as={Link} to="/spotlight/index"><i className="lnir lnir-star"></i> Curated Spotlights</Nav.Link>
            <Nav.Link as={Link} to="/playlist"><i className="lnir lnir-playlist"></i> Playlists</Nav.Link>
            <div className="mt-5 navTitle">your library</div>
            <Nav.Link as={Link} to="/recentPlayed"><i className="lnir lnir-timer"></i> Recently Played</Nav.Link>
            <Nav.Link as={Link} to="/favourite-songs"><i className="lnir lnir-heart"></i> Favourite Songs</Nav.Link>
            <div className="mt-5 navTitle">others</div>
            <Nav.Link as={Link} to=""><i className="lnir lnir-page"></i> What’s News</Nav.Link>
            <Nav.Link as={Link} to=""><i className="lnir lnir-tshirt-alt"></i> CK’s Store</Nav.Link>
          </Nav.Item>
      </Nav>
      <div className="mt-5 btn-premium-contain">
        <Button variant="primary" size="lg" href="#" block className="premium">upgrade premium</Button>
      </div>
    </div>
    );
  }
}

export default NavBar;
