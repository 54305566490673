import React, { useEffect, useState } from "react";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';
import Announcement from "../../elements/Announcement/announcement";
//custom components
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import "./spotlight";
import loadingImage from '../../assets/img/loading.gif'
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";

const Spotlight = ({getCuratedAlbums, curatedAlbums, displayLoading, hideLoading }) => {

	const [ page, setPage ] = useState(1);
	const [dataProcessing, setDataProcessing] = useState();

	useEffect(() => { 
		loadPageData();
	},[])

	const loadPageData = async() => {
		displayLoading();
		await getCuratedAlbums({page: page, pageLimit: 36});
		hideLoading();
	}

	const handlePageChange = async (pageNumber) => {
		displayLoading();
		setPage(pageNumber);
		await getCuratedAlbums({page: pageNumber, pageLimit: 36});
		hideLoading();
	}
	
	
	const renderBoxContent = (i) => { 
		if(curatedAlbums && curatedAlbums.curatedlist &&  curatedAlbums.curatedlist[i]){
		return <div className="albumGroup" key={`curated-${curatedAlbums.curatedlist[i].curated_list_id}`}>
					<Link to={`/spotlight/view/${curatedAlbums.curatedlist[i].curated_list_id}`}><figure style={{backgroundImage: curatedAlbums.curatedlist[i].image ? `url(${curatedAlbums.curatedlist[i].image})` : ''}}></figure></Link>
					<h6><Link to={`/spotlight/view/${curatedAlbums.curatedlist[i].curated_list_id}`}>{curatedAlbums.curatedlist[i].title}</Link></h6>            
				</div>
		} else {
            return <div className="albumGroup hide">&nbsp;</div>;
        }
	}

	const boxHtml = () => {
		let rows = [];
		for(var i = 0; i < 9; i++){
			let squares = [];
			for(var j = 0; j < 4; j++){
				squares.push(renderBoxContent(4*i+j));
			}
			rows.push(<div className="d-flex mb-4">{squares}</div>);
		}
		return rows;
	}



  return (
    <MusicDashboard rightContent={<RightSide blocks={{ curatedFilter: true, suggestedCollection: true }} />}>
		<Announcement page_name="spoitlight_page" />
		<div>
			<div className="d-flex align-items-baseline sectionTitleHead mb-5">
				<h3 className="text-truncate text-uppercase">latest</h3>
			</div>
			{
				curatedAlbums && curatedAlbums.curatedlist && curatedAlbums.curatedlist.length > 0 ?
				<div>{boxHtml()}</div>
				: ''
			}
			<div className="pagin text-center mt-5 mb-5">
			{
			curatedAlbums && curatedAlbums.pagination ? 
				<Pagination
					activePage={curatedAlbums.pagination.page}
					itemsCountPerPage={curatedAlbums.pagination.perPageLimit}
					totalItemsCount={curatedAlbums.pagination.totalCount}
					// pageRangeDisplayed={pageRangeDisplayed}
					onChange={handlePageChange}
					itemClass="page-item"
					linkClass="page-link"
			/> : ''
			}
        </div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
   		curatedAlbums: state.curated.curatedAlbums
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
	getCuratedAlbums: (data) => dispatch(actions.getCuratedAlbums(data)),
	displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Spotlight);