import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';

const SuggestedCollection = ({getSuggestedList, suggestedList}) => {
    useEffect(() => { 
        getSuggestedList();
      },[])

	  console.log('suggestedLis', suggestedList)
	if(suggestedList){
		return (
			<aside className="mb-4">
				<h3>Suggested Collection</h3>
				{
					suggestedList && suggestedList.length ? 
						suggestedList.map((list, index) => {
							return <div className="d-flex border-bottom pt-3 pb-3 border-grey text-dark trackList ofAlbum" key={`suggestedList-${list.curated_list_id}`}>
										<Link to={`/spotlight/view/${list.curated_list_id}`}>
											<div className="img">
												<figure className="bg-dark" style={{backgroundImage: list.image ? `url(${list.image})` : '', backgroundSize: 'cover'}}>
													<i className="icon play-icon"></i>
												</figure>
											</div>
										</Link>
											<div className="info">
												<h6>{list.title}</h6>
												<p className="txt-muted">{list.created_by}</p>
											</div>
										
										{/* <div className="download-link">
											<a href=""><i className="lnir lnir-more"></i></a>
										</div> */}
									</div>
						})
						
					: ''
				}
				{/* <div className="d-flex border-bottom pt-3 pb-3 border-grey text-dark trackList ofAlbum">
				<div className="img">
					<figure className="bg-dark">
					<i className="icon pause-icon"></i>
					</figure>
				</div>
				<div className="info">
					<h6>Badbees (Smassh’ SayMy Name’ Blend</h6>
					<p>Lil Dicky ft Chris Brown</p>
				</div>
				<div className="download-link">
					<a href=""><i className="lnir lnir-more"></i></a>
				</div>
				</div> */}
			</aside>
		)
	} else {
		return '';
	}
}



function mapStateToProps(state) {
    return {
        suggestedList: state.curated.suggestedList
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getSuggestedList: () => dispatch(actions.getSuggestedList())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SuggestedCollection);