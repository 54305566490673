import React, { useState, useEffect } from "react";
import "./playgroup.scss";

import AlbumImg from '../../assets/img/album-img.png'

import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import config from '../../config/config';

import { showLoading, hideLoading } from 'react-redux-loading-bar'

const PlayGroupDownloadRescue = ({ songs, downloadSongVersion, displayLoading, hideLoading}) =>  {
  
	

  const downloadRescueSong = (version, songData) => {
      displayLoading();
      downloadSongVersion({ version: version, recordHistory: false}).then(res => {
          if(res.payload.response && res.payload.response.status === 403){
            alert(res.payload.response.data.message);
          } else if(res.payload.data) {
            const versionData = res.payload.data;
            const downloadFilename = versionData.uploaded_mp3_name;
            const url = songData.song_section_id == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
            var downloadurl = `https://www.clubkillers.com/neverbeendownload.php?url=${url}&file=${downloadFilename}`;
            window.location.assign(downloadurl);
            // CAll parent function
          }
      });
      hideLoading();
  }

  return(
    <div>
        {
          songs && songs.length > 0 ? 
          songs.map((song, index)=>{
        return <div className="playGroup" key={`homesongs${song.entity_id}${song.version.song_version_id}`} >
          <div className="d-flex align-items-center topView flex-wrap">
            <div className="count">{index+1}</div>
            <div className="info d-flex align-items-center">
              <div className="img">
                <figure>
                  <img src={AlbumImg}  />
                </figure>
              </div>
              <aside>
                <div className="musicTitle text-truncate">{song.title}</div>
                <div className="musicCreator">{song.artist}</div>
              </aside>
            </div>
            <div className="action ml-auto text-nowrap">
              
              <span  onClick={() => downloadRescueSong(song.version, song)}><i className="lnir lnir-download pointer"></i></span>
              
            </div>
            <div className="d-flex align-items-baseline justify-content-around infoMid">
              <div className="songType">
                <div className="text-nowrap"><span>BPM:</span> {song.min_bpm} - {song.max_bpm}</div>
                <div className="text-nowrap"><span>Key:</span> {song.song_key}</div>
              </div>
              <div className="createDate">
                <span>Date</span>
                <div className="text-nowrap">{song.created_at}</div>
              </div>
              {/* <div className="genre">
                <span>Genre</span>
                <div>Mainstream / hip hop 
                </div>
              </div> */}
              <div className="version">
                <span>Version</span>
                <div className="text-nowrap">{song.version.version_label} </div>
              </div>
            </div>
          </div>
          
        </div>
          })
          : ''
        }
    </div>
      )
  
}

function mapStateToProps(state) {
  return {
    crateSongs: state.song.customerCurates,
    playLists: state.playlist.playListSongs,
    currentSong: state.song.currentSong,
    userProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlayGroupDownloadRescue);
