import React, { useEffect, useState } from "react";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';
import Announcement from "../../elements/Announcement/announcement";
//custom components
import PlayGroup from "../../elements/playgroup/playgroup";
import { Link } from "react-router-dom";
import { useLocation, useRouteMatch } from "react-router-dom";
import "./spotlight";
import loadingImage from '../../assets/img/loading.gif'
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";

const SpotlightSongs = ({getCuratedSongs, curatedSongs, displayLoading, hideLoading}) => {
    
  let match = useRouteMatch("/spotlight/view/:id");
  const location = useLocation();

	const [ page, setPage ] = useState(0);
	const [dataProcessing, setDataProcessing] = useState();
	
	useEffect(() => { 
		displayLoading();
		getCuratedSongs({page: page, curated_list_id: match.params.id}).then((res) => {
			hideLoading();
		});
	},[location])
	
	
	



  return (
    <MusicDashboard rightContent={<RightSide blocks={{ curatedFilter: true, suggestedCollection: true  }}/>}>
			<Announcement page_name="spoitlight_page" />
			<div>
				<div className="d-flex align-items-baseline sectionTitleHead mb-5">
					<h3 className="text-truncate text-uppercase">{ curatedSongs ? curatedSongs.title : ''}</h3>
				</div>
				{
					curatedSongs && curatedSongs.songs && curatedSongs.songs.length > 0 ?
					<PlayGroup songs={curatedSongs.songs}   /> 
					: ''
				}
				
			</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    curatedSongs: state.curated.curatedSongs
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
		getCuratedSongs: (data) => dispatch(actions.getCuratedSongs(data)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(SpotlightSongs);