import React, { useState, useEffect } from "react";

import AlbumImg from '../../assets/img/album-img.png';
import DownloadVersionButton from '../../elements/actionBtn/downloadVersion';
import FavouriteButton from '../actionBtn/addToFavourite'

import {connect} from 'react-redux';
import * as actions from '../../store/playlist/actions';

const RecentPlayedSong = ({getPlayList, playLists}) => {
    useEffect(() => { 
        
      },[])
	
	if(playLists && playLists.length> 0){
		return (
			<aside className="mb-4">
				<h3>Song Just Played</h3>
				{
					playLists.map((song, songIndex) => {
						return <div className="d-flex border-bottom pt-3 pb-3 border-grey text-dark trackList align-items-center playGroup" key={`recentlyplayedsidebar-${song.id}-${song.song_version_id}`}>
									<div className="img">
										<figure className="">
											<img src={AlbumImg} height="60"></img>
										</figure>
									</div>
									<div className="info">
										<h6 dangerouslySetInnerHTML={{__html: song.songData.title}}></h6>
										<p dangerouslySetInnerHTML={{__html: song.songData.artist}}></p>
									</div>
									<div className="download-link d-flex action">
										<FavouriteButton song={song.songData} />
										<DownloadVersionButton song={song.songData} version={song.versionData} />
									</div>
								</div>
					})
				}
				
			</aside>
		)
	} else {
		return '';
	}
}



function mapStateToProps(state) {
    return {
		playLists: state.playlist.playListSongs,
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getPlayList: () => dispatch(actions.getPlayList()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentPlayedSong);