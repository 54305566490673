import React from "react";
import "./carousellist.scss";

import Slider from "react-slick";
import { Link }  from "react-router-dom"

const settings = {
  className: "slider",
  dots: false,
  infinite: false,
  centerMode: false,
  slidesToShow: 5,
  slidesToScroll: 5,
  // variableWidth: true,
  arrows: true
};

const ProductCarouselList = (props) =>  {
  return(
    <div>
        <div className="d-flex align-items-baseline sectionTitleHead">
          <h3 className="text-truncate">{props.title}</h3>
          <div className="pl-5">
          <a href={props.sellAllLink} target="_blank" className="sellAll-link text-truncate">See All <i className="lnir lnir-chevron-right"></i></a>
          </div>
        </div>
        <div className="sliderBoxFixed">

        <Slider {...settings}>
        {
          props.items && props.items.length > 0 ?
            props.items.map((album, index) => {
              return <div className="albumGroup" key={`top20${album.id}`}>
                      <a href="//gear.clubkillers.com/collections/products" target="_blank"><figure style={{ backgroundImage: `url(${album.image.src})`}}></figure></a>
                      <h6><a href="//gear.clubkillers.com/collections/products" target="_blank">{album.title}</a></h6>
                      {/* {props.price != '' ? (<div className="price">{props.price}</div>) : ''}             */}
                    </div>
            })
          :
          ''
        }
          
        </Slider>
    </div>
    </div>
      )
  
}

export default ProductCarouselList;
