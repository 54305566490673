import React, {useEffect, useState} from "react";

//custom components
import Badge from "react-bootstrap/Badge";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import PlayGroup from "../../elements/playgroup/playgroup";
import RightSide from "../../components/rightSide/rightSide";
import Announcement from "../../elements/Announcement/announcement";
import CarouselList from "../../elements/carousellist/carousellist";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link }  from "react-router-dom"
import Pagination from "react-js-pagination";

import "./newRelease.scss";

import { connect } from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';


const  NewRelease = ({getLatestSong, newRelease, paginationData, displayLoading, hideLoading, genres, getGenresWithCount, top20Albums }) => {

  const [ page, setPage ] = useState(1);
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [ songSectionId, setSongSectionId ] = useState(1);
  const [currentGenreId, setCurrentGenreId] = useState();
  
  
  useEffect(() => { 
    // load Page Data
    loadPageData();
  },[]);
  
  // console.log('top20Albums', top20Albums)
  const loadPageData = async() => {
    setCurrentGenreId('all')
    displayLoading();
    await getGenresWithCount();
    await getLatestSong({page: page, order: order, orderDir, song_section_id: songSectionId});
    hideLoading();
    
		
  }
  const handlePageChange = (pageNumber) => {
      setPage(pageNumber);
      
      displayLoading();
      if(currentGenreId === 'all'){
        getLatestSong({page: pageNumber, order: order, orderDir, song_section_id: songSectionId}).then((res) => {
          hideLoading();
        })
      } else {
        getLatestSong({page: pageNumber, order: order, orderDir, song_section_id: songSectionId, genre_id: currentGenreId}).then((res) => {
          hideLoading();
        })
      }
      
  }

  const genresSong = (genre_id) => {
    setCurrentGenreId(genre_id)
    displayLoading();
    getLatestSong({page:1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId, genre_id: genre_id}).then((res) => {
      hideLoading();
    })
  }
  const getAllSong = () => {
    setCurrentGenreId('all')
    displayLoading();
    getLatestSong({page: 1, order: 'entity_id', orderDir: 'DESC', song_section_id: songSectionId}).then((res) => {
      hideLoading();
    })
  }

  return (
    <MusicDashboard rightContent={<RightSide blocks={{mostDownloaded: true, trendingTracks: true }} />}>
      <div>
      <Announcement page_name="mp3_latest" />
      <div className="d-flex align-items-baseline sectionTitleHead">
          <h3 className="text-truncate text-uppercase">New Releases</h3>
          
          <div className="pl-5">
            { newRelease && newRelease.length > 0 ? <div><span className="text-muted">Last update on</span> {newRelease[0].created_at} </div> : '' }
          </div>
        </div>

        { genres && genres.length > 0 ?
          <div className="badgeList d-none d-lg-block mb-4">
              <Badge variant={currentGenreId === 'all' ? 'active' : '' } onClick={() => getAllSong('0')}>All</Badge>
              {
                genres.map((genre, index)=>{
                  return <Badge variant={currentGenreId === genre.genre_id ? 'active' : '' } key={`genre-new-release-${genre.genre_id}`} onClick={() => genresSong(genre.genre_id)} dangerouslySetInnerHTML={{__html: genre.name}}></Badge>
                })
              }
          </div>
          : ''
        }
      {
        top20Albums && top20Albums.length > 0 ? 
        <div className="d-flex mb-5">
          {
            top20Albums.map((album, index)=>{
              return index <= 5 ?
                  <div className="albumGroup" key={`top20-${album.top20_track_id}`}>
                    <Link to={`/top20/songs/${album.top20_track_id}`}><figure style={{ backgroundImage: `url(${album.cover_image})` }}></figure></Link>
                    <h6><Link to={`/top20/songs/${album.top20_track_id}`}>{album.title}</Link></h6>            
                  </div>
                  : ''
              })
          }
        </div>
        : ''
      }

        <PlayGroup songs={newRelease}   /> 

        
        
        <div className="pagin text-center mt-5 mb-5">
          {
          paginationData ? 
            <Pagination
                  activePage={paginationData.page}
                  itemsCountPerPage={paginationData.perPageLimit}
                  totalItemsCount={paginationData.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>


      </div>
    </MusicDashboard>
  );
}


function mapStateToProps(state) {
  return {
    newRelease: (state.song.homeNewRelease && state.song.homeNewRelease.songs ) ? state.song.homeNewRelease.songs : [],
    paginationData:  (state.song.homeNewRelease && state.song.homeNewRelease.pagination) ? state.song.homeNewRelease.pagination : '',
    genres: state.genre.genresWithCount,
    top20Albums: state.song.top20Albums,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
    getGenresWithCount: () => dispatch(genreActions.getGenresWithCount()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	  hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(NewRelease);