import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const User = {
    signIn,
    getCustomerProfile,
    forgetPassword,
    resetPassword,
    updateProfile,
    changePassword,
    logout,
    signUp,
    submitDJApp
}

function signIn(post){
    const url = 'customer/sign-in.json?store_id=1&lang_code=en';
    return axiosReqOther(url, post, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * method getCustomerProfile
 * @param {JSON} token 
 * return array
 */
function getCustomerProfile(token){
    const url = 'customer/get-profile.json?store_id=1&lang_code=en';
    return axiosReqOther(url, token, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * forgetPassword
 * send email with reetToken to valid email address
 * @param {*string} email 
 */
function forgetPassword(data) {
    data.template = 'email_reset';
    data.websiteId = 1;
    const timestamp = new Date().getTime();
    const url = `customer/forget-password.json?store_id=1&lang_code=en&r=${timestamp}`;
   
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
* resetPassword
* send email with reetToken to valid password
* @param {*string} email 
*/
function resetPassword(data) {
   const timestamp = new Date().getTime();
   const url = `customer/reset-password.json?store_id=1&lang_code=en&r=${timestamp}`;
   return axiosReqOther(url, data, 'post').then(res => {              
       return res;       
   })
   .catch(err => { 
       return err; 
   });
}


/**
* changePassword
* send email with reetToken to valid password
* @param {*string} email 
*/
function changePassword(data) {
    const timestamp = new Date().getTime();
    data.token = USER_TOKEN;
    const url = `customer/change-password.json?store_id=1&lang_code=en`;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
 }
/**
 * method updateProfile
 * update customer profile and return customer latest data
 * @params post json
 */
function updateProfile(data){
    const timestamp = new Date().getTime();
    data.token = USER_TOKEN;
    const url = `customer/update-account.json?store_id=1&lang_code=en&r=${timestamp}`;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
 * method logout
 * logout the session
 * @params 
 */
function logout(){
    localStorage.removeItem('user') 
}

/**
 * method signUp
 * create customer
 * @params post json
 */
function signUp(data){
    const timestamp = new Date().getTime();
    const url = `customer/sign-up.json?store_id=1&lang_code=en&r=${timestamp}`;
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        return err; 
    });
}

/**
* method submitDJApp
* submit dj application for approval
* @params post json
*/
function submitDJApp(data){
   const url = `customer/submit-dj-application.json?store_id=1&lang_code=en`;
   return axiosReqOther(url, data, 'post').then(res => {              
       return res;       
   })
   .catch(err => { 
       return err; 
   });
}

export default User;