import React, { useEffect, useState } from "react";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/curated/actions';
import Announcement from "../../elements/Announcement/announcement";
//custom components
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import "./spotlight";
import { useLocation, useRouteMatch } from "react-router-dom";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";

const GenreSpotlight = ({getGenreCuratedAlbums, curatedGenres, displayLoading, hideLoading }) => {

	let match = useRouteMatch("/spotlight/filter/:genre_id");
	const location = useLocation();
	
	const [ page, setPage ] = useState(1);
	const [ dataProcessing, setDataProcessing ] = useState();

	useEffect(() => { 
		loadPageData();
	},[location])

	

	const loadPageData = async() => {
		displayLoading();
		await getGenreCuratedAlbums({ curated_genres_id: match.params.genre_id, page: page, pageLimit: 36 });
		hideLoading();
	}

	const handlePageChange = async (pageNumber) => {
		displayLoading();
		setPage(pageNumber);
		await getGenreCuratedAlbums({curated_genres_id: match.params.genre_id, page: pageNumber, pageLimit: 36});
		hideLoading();
	}
	
	
	const renderBoxContent = (i) => { 
		if(curatedGenres && curatedGenres.curatedlist &&  curatedGenres.curatedlist[i]){
		return <div className="albumGroup" key={`curated-${curatedGenres.curatedlist[i].curated_list_id}`}>
					<Link to={`/spotlight/view/${curatedGenres.curatedlist[i].curated_list_id}`}><figure style={{backgroundImage: curatedGenres.curatedlist[i].image ? `url(${curatedGenres.curatedlist[i].image})` : ''}}></figure></Link>
					<h6><Link to={`/spotlight/view/${curatedGenres.curatedlist[i].curated_list_id}`}>{curatedGenres.curatedlist[i].title}</Link></h6>            
				</div>
		} else {
            return <div className="albumGroup hide">&nbsp;</div>;
        }
	}

	const boxHtml = () => {
		let rows = [];
		for(var i = 0; i < 9; i++){
			let squares = [];
			for(var j = 0; j < 4; j++){
				squares.push(renderBoxContent(4*i+j));
			}
			rows.push(<div className="d-flex mb-4">{squares}</div>);
		}
		return rows;
	}



  return (
    <MusicDashboard rightContent={<RightSide blocks={{ curatedFilter: true, suggestedCollection: true  }} />}>
		<Announcement page_name="spoitlight_page" />
		<div>
			<div className="d-flex align-items-baseline sectionTitleHead mb-5">
				<h3 className="text-truncate text-uppercase">{curatedGenres && curatedGenres.current_genre ? curatedGenres.current_genre.title : '' }</h3>
			</div>
			{
				curatedGenres && curatedGenres.curatedlist && curatedGenres.curatedlist.length > 0 ?
				<div>{boxHtml()}</div>
				: ''
			}
			<div className="pagin text-center mt-5 mb-5">
			{
				curatedGenres && curatedGenres.pagination ? 
				<Pagination
					activePage={curatedGenres.pagination.page}
					itemsCountPerPage={curatedGenres.pagination.perPageLimit}
					totalItemsCount={curatedGenres.pagination.totalCount}
					// pageRangeDisplayed={pageRangeDisplayed}
					onChange={handlePageChange}
					itemClass="page-item"
					linkClass="page-link"
			/> : ''
			}
        	</div>
		</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
	curatedGenres: state.curated.curatedGenres
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
	getGenreCuratedAlbums: (data) => dispatch(actions.getGenreCuratedAlbums(data)),
	displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(GenreSpotlight);