import React, { useState, useEffect } from "react";

import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import { useHistory } from "react-router-dom";

const DownloadVersionButton = ({downloadSongVersion, userProfile, song, version, getCustomerDownloads }) => {
    const history = useHistory();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);
    console.log('userProfile', userProfile)
    const downloadSong = (version, songData) => {
        downloadSongVersion({ version: version}).then(res => {
            setDownloadInProcess(false)
            if(res.payload.response && res.payload.response.status === 403){
              alert(res.payload.response.data.message);
            } else if(res.payload.data) {
                getCustomerDownloads();
                const versionData = res.payload.data;
                const downloadFilename = versionData.uploaded_mp3_name;
                const url = songData.song_section_id == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
                var downloadurl = `https://www.clubkillers.com/neverbeendownload.php?url=${url}&file=${downloadFilename}`;
                window.location.assign(downloadurl);
              // CAll parent function
            }
        })
    }

    const downloadVersion = (song, version) => {
        setDownloadInProcess(true);
        if(userProfile && userProfile.group_id == 2){
            if(userProfile.payment_profile_status == 'paid'){
                downloadSong(version, song)
            } else if(userProfile.payment_profile_status == 'unpaid'){
                setDownloadInProcess(false);
                history.push('/subscription/plans')
            }
        }
    }

    if(downloadInProcess){
        return <span className="pointer"><Spinner animation="border" size="sm" variant="warning" /></span>
    } else {
        return (
            <span onClick={() => downloadVersion(song, version)} className="pointer"><i className="lnir lnir-download"></i></span>
        )
    }

}
function mapStateToProps(state) {
    return {
      userProfile: state.customer.customerProfile
    };
   }
   
   function mapDispatchToProps(dispatch) {
    return {
      downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
      getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DownloadVersionButton);
  