import React from "react";



import ContentPage from "../../Layout/ContentPage/ContentPage";

import CareerImg from "../../assets/img/career-img.jpg";

import "./career.scss";

import {connect} from 'react-redux';

const Career = () => {
  return (
    <ContentPage>
            <div>
        <div className="container">
            <h1 className="h1">Careers with <br/>Club Killers</h1>
            <div className="row mt-5">
              <p className="hightLightTxt col-md-8">Club Killer is always looking for talented and creative individuals to join our team of DJs, designers, developers, mix-masters and marketers.</p>
            </div>
            <div className="mt-4 mb-4">
              <img src={CareerImg} className="w-100"/>
            </div>
            <div className="row">
            <p className="hightLightTxt col-md-8">As part of Club Killers, we operate offices across the world. We’re always looking to connect with individuals who want to make the best work of their lives with the world’s greatest brands. If you’re interested in working with us or learning more, drop us a note, portfolio link, or résumé. We’ll take anything you’ve got.</p>
            </div>
            <h3 class="mt-5 mb-5"><a href="http://" className="text-uppercase applyBtn">Apply Now <i class="lnir lnir-arrow-top-right"></i></a></h3>
            <hr/>
            <div className="row justify-content-between">
              <aside className="col-md-4">
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
                <div className="jobList pb-3 mb-4">
                  <h3>Production Manager</h3>
                  <div className="row info">
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">location</div>
                      <h6>Las Vegas, NV</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">Position</div>
                      <h6>Production</h6>
                    </aside>
                    <aside className="col-md-4 mt-3">
                      <div className="text-uppercase label">type</div>
                      <h6>Production</h6>
                    </aside>
                  </div>
                </div>
              </aside>
              <aside className="col-md-8 pl-5">
                <article>
                  <h2>Photographer</h2>
                  <h6>Intership</h6>
                  <br/>
                  <p>Club Killers is a nightlife company founded in 2013 in Las Vegas. We are a fast growing company and are always looking for people who are driving for more ideas and concept. The people we choose for the internship need to be ready take on responsibility within their department. We are a small group of highly motivated people and we do not accept lazy people or people with no motivation.</p>
                  <h5>How The Role Will Develop:</h5>
                  <p>Working closely with a small team, you will be responsible for structuring our editorial photography team, covering the execution of editorial and sales photography, e-commerce marketing photography, as well as social media content creation.In addition to having a hands on expertise with photography and the visual aesthetic of Club Killers, the role will also involve the production planning and delegation of the duties in your team.</p>
                  <h5>Job Description:</h5>
                  <p>You will work with in-house and freelance photographers on photography content so that it is delivered in a timely manner of the most compelling photo quality. With a focus on Los Angeles culuture, the Photographer should also have a firm understanding of Club Killers aesthetics/branding and past/current content outside of the local landscape.</p>
                  <h6>Some of the roles/responsibilities will include:</h6>
                  <ul>
                    <li>Photograph in studio and out of studio</li>
                    <li>Develop the photography department’s infrastructure (including stored location databases, model database,      forms and documents, etc.)</li>
                    <li>Manage various departments' photography jobs across your team, elevating the editorial quality</li>
                    <li>Work internally and with external clients to understand their visual needs, and translate well</li>
                    <li>Scout different locations and work in different circumstances to maintain the desired aesthetic</li>
                    <li>Manage the shoot process from inception to completion</li>
                    <li>Arrange still life objects, products, scenes, props and backgrounds</li>
                    <li>Understand traditional film and digital photography and keep up to date with industry trends, developments      and new techniques </li>
                    <li>Train and mentor Staff Photographers established processes and workflows</li>
                  </ul>
                  <h6>Requirements:</h6>
                  <ul>
                    <li>Photograph in studio and out of studio</li>
                    <li>Develop the photography department’s infrastructure (including stored location databases, model database,      forms and documents, etc.)</li>
                    <li>Manage various departments' photography jobs across your team, elevating the editorial quality</li>
                    <li>Work internally and with external clients to understand their visual needs, and translate well</li>
                    <li>Scout different locations and work in different circumstances to maintain the desired aesthetic</li>
                    <li>Manage the shoot process from inception to completion</li>
                    <li>Arrange still life objects, products, scenes, props and backgrounds</li>
                    <li>Understand traditional film and digital photography and keep up to date with industry trends, developments      and new techniques </li>
                    <li>Train and mentor Staff Photographers established processes and workflows</li>
                  </ul>
                  <h6>To Apply</h6>
                  <p>We’re looking for people with an optimistic attitude and the willingness to succeed in a creative & open environment!
                  If you think you’ve got what it takes, please provide your cover letter.</p>
                  <p><i>Personal data collected is for recruitment purpose only.</i></p> 
                  <a class="text-uppercase applyBtn" href="">Apply Now <i class="lnir lnir-arrow-top-right"></i></a>
                </article>
              </aside>
            </div>
        </div>
      </div>
    </ContentPage>
  );
}
function mapStateToProps(state) {
  return {
      
  };
}

function mapDispatchToProps(dispatch) {
  return {
      // getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Career);