import React, { useState, useEffect } from "react";
import "./rightSide.scss";

import Albumtracks from '../../elements/albumtracks/albumtracks'
import Titletracks from '../../elements/titletracks/titletracks';
import NowPlaying from '../../elements/sidebar/nowPlaying';
import CuratedGenres from '../../elements/sidebar/curatedGenres';
import RecentPlayedSong from '../../elements/sidebar/recentPlayedSong';
import RecentPlayedPlayList from '../../elements/sidebar/recentPlayedPlaylist';
import SuggestedCollection from '../../elements/sidebar/suggestedCollection';
import AlbumImg from '../../assets/img/album-img.png';


import {connect} from 'react-redux';
import * as trendingActions from '../../store/trending/actions';
import * as downloadActions from '../../store/download/actions';

const RightSide = ({getTrendingSongs, currentTrending, getMostDownloadedToday, mostDownloadedToday, blocks}) => {
  
  useEffect(() => { 
    getTrendingSongs();
    getMostDownloadedToday({});
  },[])

  return (
		<div className="RightBar">
				<div className="d-flex">
					{ blocks && blocks.nowPlaying ?  <NowPlaying /> : '' }
					{ blocks && blocks.recentPlayedPlayList ?  <RecentPlayedPlayList /> : '' }
					{ blocks && blocks.recentPlayedSong ?  <RecentPlayedSong /> : '' }
					{ blocks && blocks.curatedFilter ?  <CuratedGenres /> : '' }
					{ blocks && blocks.suggestedCollection ?  <SuggestedCollection /> : '' }
					{ blocks && blocks.trendingTracks ? <aside>
															<h3>Top 30 Trending Tracks</h3>
															<Albumtracks songs={currentTrending} type="top20" />
														</aside>
					: ''
					}
					{
						blocks && blocks.mostDownloaded && mostDownloadedToday && mostDownloadedToday.length > 0 ?
							<aside>
								<h3>Most Downloaded Today</h3>
								<Titletracks type="mostdownloaded" songs={mostDownloadedToday} />
							</aside>
						: ''
					}
				</div>
		</div>
  );
  
}



function mapStateToProps(state) {
  return {
    currentTrending: state.trending.currentTrending,
    mostDownloadedToday: state.download.mostDownloadedToday
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getTrendingSongs: () => dispatch(trendingActions.getLatestTrendingSong()),
    getMostDownloadedToday: (post) => dispatch(downloadActions.getMostDownloadedToday(post))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(RightSide);
