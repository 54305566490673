import React, { useState, useEffect } from "react";


import Spotlight from "../../components/spotlight/spotlight";
import CarouselList from "../../elements/carousellist/carousellist";
import ProductCarouselList from "../../elements/carousellist/productCarouseLlist";
import Announcement from "../../elements/Announcement/announcement";
import PlayGroup from "../../elements/playgroup/playgroup";
import Badge from 'react-bootstrap/Badge'
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import RightSide from "../../components/rightSide/rightSide";
import { Link }  from "react-router-dom"
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';
import * as productActions from '../../store/product/actions';
import * as downloadActions from '../../store/download/actions';

import LoadingBar from 'react-redux-loading-bar'
import { showLoading, hideLoading } from 'react-redux-loading-bar'


const  Home = ({ displayLoading, hideLoading, getGenreSongs, homeGenreData, getHomeGenres, homeGenresList, getLatestSong, homeNewRelease, getTop20Tracks, top20Albums, officialMerchandize, getOfficialMerchandise, getCustomerDownloads}) => {

	const [activeCategoryGenre, setActiveCategoryGenre] = useState('31');
	const [activeNewReleaseGenre, setActiveNewReleaseGenre] = useState('all');
	const [dataProcessing, setDataProcessing] = useState();
  	useEffect(() => { 
		loadData();
   	},[])

	// console.log('customerDownloads', customerDownloads)
	const loadData = async () => {
		displayLoading();
		setDataProcessing(true);
		const data = {
			pageName: 'home'
		};
		await getCustomerDownloads();

		// Get Genres Song
		await getGenreSongs(data);

		// Get genres filter to display on homepage
		await getHomeGenres();

		// Get Latest Songs
		await getLatestSong(data);

		await getTop20Tracks({page: 1, limit: 15});

		await getOfficialMerchandise();
		setDataProcessing(false);
		hideLoading();
	}
	/**
	 * @method getNewReleaseSong
	 * @param 
	 */
	const getNewReleaseSong = () => {
		const data = {
			pageName: 'home'
		};
		setActiveNewReleaseGenre('all');
		getLatestSong(data).then((res) => {

		})
	}

	/**
	 * @method getNewReleaseSongByGenre
	 * @param {*} value array
	 */
	const getNewReleaseSongByGenre = (value) => {
		displayLoading();
		const data = {
			pageName: 'home',
			genre_id: value.genre_id
		};
		
		getLatestSong(data).then((res) => {
			setActiveNewReleaseGenre(value.name);
			setDataProcessing(false);
			hideLoading();
		})
	}

   /**
	* @method 
	* @param {*} value array 
	*/
   const getCategoryGenreSong = (value) => {
		displayLoading();
		setDataProcessing(true);
		const data = {
			// token: '8k7ZVi6NEBFKNW88CYBIDi-zRM1OvPzo',
			pageName: 'home',
			genre_id: value.genre_id
		};
		
    	getGenreSongs(data).then((res) => {
			setActiveCategoryGenre(value.genre_id);
			setDataProcessing(false);
			hideLoading();
		});
		
	   }
	   
	
	   
    return (
        <MusicDashboard rightContent={<RightSide blocks={{ mostDownloaded: true, trendingTracks: true}} />}>
			
			<Announcement page_name="mp3_home" />
			
            <Spotlight />
            <div className="mt-5">
				<LoadingBar scope="IndexBar" className="loading" style={{ backgroundColor: '#ffa800', height: '2px', width: '100%', position: 'absolute' }} updateTime={100} maxProgress={95} progressIncrease={10} />
				{
					homeGenreData && homeGenresList && homeGenreData != ''  ? 
						<div>
							<div className="d-flex align-items-center sectionTitleHead">
								<h3 className="text-nowrap mb-0">{homeGenreData.genre.name}</h3>
								<div className="pl-5 pr-4">
									<Link to={`/genres/view/${activeCategoryGenre}`} className="sellAll-link text-nowrap">See All <i className="lnir lnir-chevron-right"></i></Link>
								</div>
								<div className="ml-auto badgeList d-none d-lg-block">
								{
									homeGenresList && homeGenresList.length > 0 ? 
									homeGenresList.map((genre, index)=>{ 
										return <Badge variant={activeCategoryGenre === genre.genre_id ? "active" : ''}  key={`genrehome${genre.genre_id}`} onClick={() => getCategoryGenreSong(genre)}>{genre.name}</Badge>
									})
									: ''
								}
								</div>
							</div>
							<PlayGroup songs={homeGenreData.songs}   /> 
						</div> 
					: 
					''
				}
            </div>
            <div className="mt-5">
				{
					homeGenreData && homeNewRelease && homeGenreData != ''  ? 
						<div>
							<div className="d-flex align-items-center sectionTitleHead">
								<h3 className="text-nowrap mb-0">New Releases</h3>
								<div className="pl-5 pr-4">
									<Link to={`/newRelease`} className="sellAll-link text-nowrap">See All <i className="lnir lnir-chevron-right"></i></Link>
								</div>
								<div className="ml-auto badgeList d-none d-lg-block">
									<Badge variant={activeNewReleaseGenre === 'all' ? "active" : ''}  onClick={() => getNewReleaseSong()}>All</Badge>
									{
										homeGenresList && homeGenresList.length > 0 ? 
										homeGenresList.map((genre, index)=>{ 
											return <Badge variant={activeNewReleaseGenre === genre.name ? "active" : ''}  key={`latestgenrehome${genre.genre_id}`} onClick={() => getNewReleaseSongByGenre(genre)}>{genre.name}</Badge>
										})
										: ''
									}
								</div>
							</div>
							<PlayGroup songs={homeNewRelease.songs}   /> 
						</div> 
					: 
					''
				}
            </div>
            <div className="mt-5">
              <CarouselList title="Monthly Top 20" sellAllLink="/top20/tracks" top20Albums={top20Albums} />
            </div>
            <div className="mt-5">
              <ProductCarouselList title="Official Merchandise" sellAllLink="https://gear.clubkillers.com/collections/products" items={officialMerchandize} />
            </div>
        </MusicDashboard>
    );
}

function mapStateToProps(state) {
  return {
    homeGenreData: state.song.homeGenreData,
	homeGenresList: state.genre.homeGenreList,
	homeNewRelease: state.song.homeNewRelease,
	top20Albums: state.song.top20Albums,
	officialMerchandize: state.product.products
  };
 }
 
 function mapDispatchToProps(dispatch) {
	
  return {
    getGenreSongs: (post) => dispatch(songActions.getHomePageGenreSong(post)),
	getHomeGenres: () => dispatch(genreActions.getHomeGenres()),
	getLatestSong: (post) => dispatch(songActions.getNewRelease(post)),
	getTop20Tracks: () => dispatch(songActions.getTop20Albums()),
	getOfficialMerchandise: () => dispatch(productActions.getProducts()),
	displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
	hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
	getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Home);
