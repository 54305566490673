import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import config from '../../config/config';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import { useHistory } from "react-router-dom";

const DownloadBundelSongButton = ({downloadBundleSong, userProfile, entity_id, getCustomerDownloads }) => {

    const history = useHistory();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);

    const downloadBundle = (entity_id) => { 
        setDownloadInProcess(true)
        if(userProfile && userProfile.group_id == 2){
          if(userProfile.payment_profile_status == 'paid'){
              downloadBundleSong({ entity_id: entity_id}).then(res => {
                setDownloadInProcess(false);
                if(res.payload.response && res.payload.response.status === 403){
                  alert(res.payload.response.data.message);
                } else if(res.payload.data) {
                    getCustomerDownloads();
                    const data = res.payload.data;
                    const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
                    window.location.assign(zipUrl);
                }
              });
          } else if(userProfile.payment_profile_status == 'unpaid'){
                setDownloadInProcess(false);
                history.push('/subscription/plans')
            }
        } 
      }

    
    if(downloadInProcess){
        return <span className="pointer"><Spinner animation="border" size="sm" variant="warning" /></span>
    } else {
        return (
            <span  onClick={() => downloadBundle(entity_id)}><i className="lnir lnir-download pointer"></i></span>
        )
    }

}
function mapStateToProps(state) {
    return {
      userProfile: state.customer.customerProfile
    };
   }
   
   function mapDispatchToProps(dispatch) {
    return {
        downloadBundleSong: (data) => dispatch(downloadActions.downloadBundleSong(data)),
        getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
    };
}
  
export default connect(mapStateToProps, mapDispatchToProps)(DownloadBundelSongButton);
  