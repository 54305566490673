import * as types from './type';

const initialState = {
  theme: ''
};



export default function themeReducer(state = initialState, action) {

  switch (action.type) {
      
    case types.CHNAGE_THEME: {
      if (action.payload) {
        return {
          ...state,
          theme: action.payload,
        }
      } else {
        return {
          ...state
        }
      }
    }
    default: {
      return {
        ...state
      };
    }
    
  }

}