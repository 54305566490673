
import React, {useEffect, useState} from "react";
import { showLoading, hideLoading } from 'react-redux-loading-bar';


import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import AccountNav from "../../elements/accountNav/accountNav";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import { connect } from 'react-redux';
import * as subscriptionAction from '../../store/subscription/actions'

import { CanAddApplePay } from './canAddApplePay';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
const stripePromise = loadStripe("pk_test_51H0KA8IY2jIY0hCM9nSxMhp2YlTAcGtZ2HGyPJtuAumWNDgiK8RSJpzqh3Gj1czhAbGcpV0eYhEIgT9lIUT37ItV00NJv7OCYC");

const MobileWallet = ({ displayLoading, hideLoading, getServices, plans, getCustomerAgreement, agreement, coupon, subscriptionDetail, processApplePayment }) => {

    let match = useRouteMatch("/subscription/mobile-wallet/:plan_id");
    const location = useLocation();
    const history = useHistory();

    const [ planId, setPlanId ] = useState();
    const [selectedPlan, setSelectedPlan] = useState();
    const [ subscriptionData, setSubscriptionData ] = useState({
        payment_method: 'Mobile Walltet',
        service_id: ''
    });

    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    useEffect(() => { 
        displayLoading();
		if(match.params.plan_id){
            setPlanId(match.params.plan_id)
        }else{
            history.push("/subscription/plans");
        }
        hideLoading();
    },[location]);

    if(agreement && agreement.profile_status == 'CREATED'){
        history.push('/account/manage-subscription');
    }

    const loadPageData = async() => {
        displayLoading();
        console.log(plans, agreement, coupon)
        if(plans){
            setSelectedPlan(plans.find(  service  => service.id ===  match.params.plan_id.toString()));
        } else {
            await getServices({subscription_type_id: 1}).then((res) => {
                if(res && res.payload && res.payload.data && res.payload.status == 'true'){
                    setSelectedPlan(res.payload.data.find(  service  => service.id ===  match.params.plan_id.toString()));
                }
            });
        }
        if(!agreement){
            await getCustomerAgreement();
        }
        
        hideLoading();
    }

    const processPayment = (event) => {
        processApplePayment({applePay: event, coupon: coupon, subscription: subscriptionDetail}, () => {
            event.complete('success');
        }, () => {
            event.complete('fail');
        });
    }

    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12} className="text-center mb-4">
                            <h2 className="mb-3">Mobile Payment</h2>
                            <p className="text-muted">
                                Please review your membership details and billing information to complete your order
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        
                        <Col lg={4}>
                            {
                                selectedPlan ?
                           
                                    <Card className="border-0">
                                        <Card.Body>
                                        <h5 className="plan-title text-uppercase mb-3 text-center"><b>{selectedPlan.description}</b></h5>
                                            <div className="payment-summary bg-light p-3 mb-4">
                                                <table class="w-100 table mb-0">
                                                    {
                                                        selectedPlan ? 
                                                            <tr>
                                                                <td className="border-0">{selectedPlan.title}</td>
                                                                <td className="border-0">${selectedPlan && coupon && coupon.discount_amount && parseInt(coupon.discount_amount) > 0  ?( parseInt(coupon.discount_amount) +  parseInt(selectedPlan.price)).toFixed(2) :  parseInt(selectedPlan.price).toFixed(2) }</td>
                                                            </tr>
                                                        : '' 
                                                    }
                                                    {
                                                        coupon && coupon.discount_amount && parseInt(coupon.discount_amount) > 0  ? 
                                                        <tr>
                                                            <td className="border-0">Discount</td>
                                                            <td className="text-danger border-0">-${parseInt(coupon.discount_amount).toFixed(2)}</td>
                                                        </tr>
                                                        : ''
                                                    }
                                                    {
                                                        selectedPlan ? 
                                                            <tr className="border-grey border-top">
                                                                <td><h5 className="mb-0">Total</h5></td>
                                                                <td><h5 className="mb-0 text-success">${ parseInt(selectedPlan.price).toFixed(2) }</h5></td>
                                                            </tr>
                                                        : ''
                                                    }
                                                </table>
                                                
                                            </div>
                                            
                                                <div className="action mt-2">
                                                {
                                                    subscriptionDetail ? 
                                                        <Elements stripe={stripePromise}>
                                                            <CanAddApplePay subscriptionData={subscriptionDetail} onConfirmPayment={processPayment}/>
                                                        </Elements>
                                                    : '' 
                                                }
                                                </div>
                                        </Card.Body>
                                    </Card>
                                : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </MusicDashboard>
    )
}

function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        plans: state.subscription.plans,
        coupon: state.coupon.coupon,
        subscriptionDetail: state.subscription.subscriptionDetail
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        processApplePayment: (post) => dispatch(subscriptionAction.processApplePayment(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(MobileWallet);