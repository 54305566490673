import * as types from './type';

const initialState = {
    countryList: [],
    announcement: ''
};

export default function commonReducer(state = initialState, action) {

    switch (action.type) {
      
        case types.GET_COUNTRY_LIST: {
          if (action.payload && action.payload.data) {
            return {
              ...state,
              countryList: action.payload.data.data
            }
          } else {
            return {
              ...state
            }
          }
        }
        case types.GET_ANNOUNCEMENT: {
          if (action.payload && action.payload.data) {
            return {
              ...state,
              announcement: action.payload.data
            }
          } else {
            return {
              ...state
            }
          }
        }
        case types.HIDE_ANNOUNCEMENT: {
          if (action.payload && action.payload.data) {
            return {
              ...state,
              announcement: action.payload.data
            }
          } else {
            return {
              ...state
            }
          }
        }
        default: {
            return {
              ...state
            };
          }


    }
}