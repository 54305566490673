import React, { useState, useEffect } from "react";
import "./playgroup.scss";

import AlbumImg from '../../assets/img/album-img.png'
import DownloadCrateVersionButton from '../actionBtn/downloadCrateVersion';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import config from '../../config/config';

const PlayGroupCrate = ({ songs, crateSongs, getCustomerCrates, addSongToCrate, addSongVersionToPlayList, playLists, currentGenreId, perPageLimit, page, setPlayListIndex}) =>  {
  
	const [activeSong, setActiveSong] = useState();
	const [activeSongId, setActiveSongId] = useState(0);
	const [isListExpanded, setIsListExpanded] = useState(false);
	const [ firstVersionAdded, setFirstVersionAdded ] = useState(0);
	const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
	
	useEffect(() => { 

	},[crateSongs])
  
	const showSongVersion = (song) => {
		if(song.entity_id == activeSongId || activeSongId == 0){
		setIsListExpanded(!isListExpanded);
		}
		setActiveSongId(song.entity_id);
		setActiveSong(song);
	}

	const playAndToggleDropDown = (song) => {
		if(song.entity_id == activeSongId || activeSongId == 0){
		setIsListExpanded(!isListExpanded);
		}
		setActiveSongId(song.entity_id);
		setActiveSong(song);
		playSong(song, song.version[0]);
	}

  	const addSongToFavourite = async(song) => {
      await addSongToCrate(song.entity_id, 0);
      await getCustomerCrates();
  	}

	// const playSong = (song, version) => {
	// 	// console.log('playLists', playLists);
	// 	addSongVersionToPlayList(song, version);
	// 	getPlayList();
	// }

	const playSong = (song, version) => { 
		const songIndex = checkIfVersionAlreadyExist(version.song_version_id);
		if(playLists.length == 0){
		  setFirstVersionAdded(version.song_version_id);
		}
		if(!songIndex){
		  if(firstVersionAdded === version.song_version_id && playLists.length == 0){
			addSongVersionToPlayList(song, version);
		  } else if(firstVersionAdded !== version.song_version_id){
			addSongVersionToPlayList(song, version);
		  } else {
			setPlayListIndex(songIndex);
		  }
		  
		} else {
		  setPlayListIndex(songIndex);
		}
		
		// console.log(songIndex)
		setActiveAlbumSong(true);
	   
		// getPlayList();
	  }

  	const checkIfVersionAlreadyExist = (version) => {
		var hasMatch = 0;
		for (var index = 0; index < playLists.length; ++index) {
		var songs = playLists[index];
		if(songs.version == version){
			hasMatch = index;
			break;
		}
		}
		return hasMatch;
	}

 

  return(
    <div>
        {
          songs && songs.length > 0 ? 
          songs.map((song, index)=>{
        return <div className="playGroup" key={`homesongs${song.entity_id}-${song.version.song_version_id}`} >
          <div className="d-flex align-items-center topView flex-wrap">
            <div className="count">{index+1}</div>
            <div className="info d-flex align-items-center">
              <div className="img">
                <figure>
                  <img src={AlbumImg}  />
                </figure>
              </div>
              <aside>
                <div className="musicTitle text-truncate">{song.title}</div>
                <div className="musicCreator">{song.artist}</div>
              </aside>
            </div>
            <div className="action ml-auto text-nowrap">
              
              {/* <span  onClick={() => downloadBundle(song.entity_id)}><i className="lnir lnir-download pointer"></i></span> */}
              <DownloadCrateVersionButton song={song} version={song.version} currentGenreId={currentGenreId} perPageLimit={perPageLimit} page={page} />
              <span onClick={() => addSongToFavourite(song)} className={ crateSongs && crateSongs.song && crateSongs.song.length > 0 && ((crateSongs.song.indexOf(song.entity_id.toString()) > -1) ) ? 'active pointer' : 'pointer'}>
                <i className="lnir lnir-close"></i>
              </span>
            </div>
            <div className="d-flex align-items-baseline justify-content-around infoMid">
              <div className="songType">
                <div className="text-nowrap"><span>BPM:</span> {song.min_bpm} - {song.max_bpm}</div>
                <div className="text-nowrap"><span>Key:</span> {song.song_key}</div>
              </div>
              <div className="createDate">
                <span>Date</span>
                <div className="text-nowrap">{song.created_at}</div>
              </div>
              {/* <div className="genre">
                <span>Genre</span>
                <div>Mainstream / hip hop 
                </div>
              </div> */}
              <div className="version">
                <span>Version</span>
                <div className="text-nowrap">{song.version.version_label} versions</div>
              </div>
            </div>
          </div>
          
        </div>
          })
          : ''
        }
    </div>
      )
  
}

function mapStateToProps(state) {
  return {
    crateSongs: state.song.customerCurates,
    playLists: state.playlist.playListSongs,
    currentSong: state.song.currentSong,
    userProfile: state.customer.customerProfile
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
    addSongVersionToPlayList: (song, version) => dispatch(playlistActions.addSongVersionToPlayList(song, version)),
    getPlayList: () => dispatch(playlistActions.getPlayList()),
    downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
    downloadBundleSong: (data) => dispatch(downloadActions.downloadBundleSong(data)),
    setPlayListIndex: (index) => dispatch(playlistActions.setPlayListIndex(index)),
    getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlayGroupCrate);
