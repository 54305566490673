import React, {useState, useEffect} from "react";


import AlbumImg from "../../assets/img/album-img.png";
import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button'


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
// import RightSide from "../../components/rightSide/rightSide";
import "./crate.scss";

import config from '../../config/config';

import PlayGroupCrate from "../../elements/playgroup/playGroupCrate";
import {connect} from 'react-redux';
import * as crateActions from '../../store/crate/actions';
import * as songActions from '../../store/song/actions';
import { showLoading, hideLoading } from 'react-redux-loading-bar'
import { useHistory } from "react-router-dom";


const Crate = ({ getCrateList, crateSongs, crateGenres, getCustomerCrates, displayLoading, hideLoading, clearAllTrack, downloadBatchFiles, userProfile }) => {
	const history = useHistory();
	const [page, setPage] = useState(1);
	const perPageLimit =  config.itemsCountPerPage;
	const [currentGenreId, setCurrentGenreId] = useState(0);

	useEffect(() => { 
		loadPageData()
	},[]);
	
	const loadPageData = async () => {
		displayLoading()
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
			// this.setState({genres: this.props.song.latestSong});
		})
		await getCustomerCrates();
		hideLoading();
	}

	const getGenreCrate = async(genreID) => {
		displayLoading()
		setCurrentGenreId(genreID);
		await getCrateList({page: page, pageLimit: perPageLimit, genre_id: genreID}, () => {
		
		})
		hideLoading();
	}

	const downloadBatch = async () => {
		if(userProfile && userProfile.group_id == 2){
			if(userProfile.payment_profile_status == 'paid'){
				displayLoading();
				const data = {
				genre_id: currentGenreId
				}
				await downloadBatchFiles(data).then(res => {
				if(res.payload.response && res.payload.response.status === 403){
					alert(res.payload.response.data.message);
				} else if(res.payload.data) {
					const data = res.payload.data;
					const zipUrl = `${config.downloadSelfUrl}tmp/${data.zip}`;
					window.location.assign(zipUrl);
				}
				});
				await getCrateList({page: 1, pageLimit: perPageLimit, genre_id: 0}, () => {
				
				});
				setCurrentGenreId(0);
				hideLoading();
			} else if(userProfile.payment_profile_status == 'unpaid'){
				history.push('/subscription/plans')
			}
		}
	}

  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">your crate</h3>
        </div>
        <hr className="alignHr"/>
        <div className="d-flex justify-content-between mt-5 align-items-center mb-5">
          <aside className="">
          <div className="badgeList d-none d-lg-block">
            { crateGenres && crateGenres.length > 0 ? <Badge variant={currentGenreId == 0 ? 'active': ''} onClick={()=> getGenreCrate(0)}>All</Badge> : ''}
            {
              crateGenres && crateGenres.length > 0 ?
              crateGenres.map((crate, index) => {
                  return <Badge variant={ currentGenreId == crate.genre_id ? 'active' : ''} key={`crategenre${crate.genre_id}`} onClick={()=> getGenreCrate(crate.genre_id)}>{crate.name}</Badge>
              })
              : ''
            }
          </div>
          </aside>
          <aside>
          <Button variant="light" className="primary-bg" onClick={() => downloadBatch()}>Download batch</Button>
          <Button variant="light" className="ml-4" onClick={() => clearAllTrack()}>clear all tracks</Button>
          </aside>
        </div>

        
          {
              crateSongs && crateSongs.length > 0 ?
              <PlayGroupCrate songs={crateSongs} currentGenreId={currentGenreId} perPageLimit={perPageLimit} page={page}   /> 
              : ''
          }
        
        
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    crateGenres: state.crate.crateGenres,
	crateSongs: state.crate.crateSongs,
	userProfile: state.customer.customerProfile    
  };
}

function mapDispatchToProps(dispatch) {
  return {
      getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
      getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
      displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
      hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
      clearAllTrack: () => dispatch(crateActions.clearAllTrack()),
      downloadBatchFiles: (data) => dispatch(crateActions.downloadBatchFiles(data))

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Crate);