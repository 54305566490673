import React, {useState} from "react";
import "./resetPasswordModal.scss";


import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';

import {connect} from 'react-redux';



const ResetPasswordModal = ({showState, ShowFunction, showLogin, resetPasswordRequest, processing, resetErr}) =>  {
  const [formData, setFormData] = useState({
      password: '',
      confirm_password: ''
  });
  const [ errors, setErrors ] = useState({})

  // const [submited, setSubmited] = useState(false);

  const [validated, setValidated] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
        ...formData,
        [name]: value
    });

    
  }

  const findFormErrors = () => {
    const { password, confirm_password, } = formData
    const newErrors = {}
    
    // password errors
    if ( !password || password === '' ) newErrors.password = 'password cannot be blank!'
    // confirm password errors
    if ( !confirm_password || confirm_password === '' ) newErrors.confirm_password = 'confirm password cannot be blank!'
    else if ( confirm_password != password ) newErrors.confirm_password = 'password mismatch'

    return newErrors
  }

  const handleSubmit = (event) => {
    const newErrors = findFormErrors();
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();

    if ( Object.keys(newErrors).length > 0 ) {
      console.log(newErrors)
      // We got errors!
      setErrors(newErrors)
    } else {
      resetPasswordRequest(formData);
    }
    // console.log(form.checkValidity())
    // if (form.checkValidity() === false) {
      
    // } else {
    //   // setSubmited(true);
      
    // }
    // setValidated(true);
  }

  return(
    <Modal
    show={showState}
    onHide={ShowFunction}
    backdrop="static"
    keyboard={false}
    size="md"
  >
    <Modal.Header>
      <Modal.Title>Reset Password</Modal.Title>
    </Modal.Header>
    <Form noValidate validated={validated} onSubmit={handleSubmit} className="popup-form">
      <Modal.Body>
        {
          resetErr && resetErr !== '' ?
          <div className="alert alert-danger">{resetErr}</div>
          : ''
        }
        <InputGroup className="mt-3 lineInput"> 
          <InputGroup.Text>
            <i className="lnir lnir-lock-alt"></i>
          </InputGroup.Text>
          <Form.Control type="password" name="password" placeholder="Password *" onChange={handleChange} isInvalid={ !!errors.password } />
          <Form.Control.Feedback type="invalid">
          { errors.password }
          </Form.Control.Feedback>
          
        </InputGroup>
        <InputGroup className="mt-3 lineInput"> 
          <InputGroup.Text>
            <i className="lnir lnir-lock-alt"></i>
          </InputGroup.Text>
          <Form.Control type="password" name="confirm_password" placeholder="Confirm Password *" onChange={handleChange} isInvalid={ !!errors.confirm_password } />
          <Form.Control.Feedback type="invalid">
            { errors.confirm_password }
          </Form.Control.Feedback>
          
        </InputGroup>
        
      </Modal.Body>
      <Modal.Footer>
          <Button type="submit" className="text-uppercase" variant="primary" block disabled={processing}> { !processing ? 'Change Password' : 'Processing ...'}</Button>
          <Button className="text-uppercase" variant="light" block onClick={() =>{ window.location.href= "/"}}>Back</Button>
      </Modal.Footer>
    </Form>
  </Modal>
  )  
}

function mapStateToProps(state) {
  return {
    authentication: state.authentication
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordModal);