import React, {useState}  from "react";
import "./titletracks.scss";
import PlayPauseButton from '../actionBtn/playPauseBtn';
import DownloadVersionButton from '../../elements/actionBtn/downloadVersion';
import {connect} from 'react-redux';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';

const Titletracks = ({songs, type, currentSong, isPlaying}) =>  {

  const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
  const [ firstVersionAdded, setFirstVersionAdded ] = useState(0)

  
  return (
    <div>
    {
      songs && songs.length > 0 ? 
        songs.map((song, index) => {
          return <div className="d-flex trackList title align-items-center mt-4" key={`albumtracks-${type}-${index}`}>
          {/* {
              activeAlbumSong && currentSong && currentSong.songData && currentSong.version == song.version[0].song_version_id  && currentSong.id == song.entity_id ?
                <div className= "count active"><i className="pause-icon" onClick={() => pauseSong(song, song.version[0])}></i></div>
            : 
                <div className= "count"><span>{index+1}</span><i className="play-icon" onClick={() => playSong(song, song.version[0])}></i></div>
          } */}
          {
							isPlaying &&  currentSong && currentSong.songData  && currentSong.version == song.version[0].song_version_id  && currentSong.id == song.entity_id ?
								<div className= "count active">
									<PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
									</div>
							: 
								<div className= "count"><span>{index+1}</span>
									<PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
								</div>
						}
          <div className="info">
            <h6 dangerouslySetInnerHTML={{__html: song.title}} />
            <p dangerouslySetInnerHTML={{__html: song.artist}} />
          </div>
          <div className="download-link">
            <DownloadVersionButton song={song} version={song.version[0]} />
          </div>
        </div>
        })
        : ''
    }
    </div>
  )
  
}

function mapStateToProps(state) {
  return {
    isPlaying: state.song.isPlaying,
		currentSong: state.song.currentSong
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getPlayList: () => dispatch(playlistActions.getPlayList()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Titletracks)
