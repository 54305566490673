import React, {useState, useEffect} from "react";
import "./djAppModal.scss";

import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';

const DjAppModal = ({ showState, ShowFunction, processDjApp, responseError, processing, djApplicationSuccess }) =>  {

  const [formData, setFormData] = useState({
    dj_website: '',
    referred_by: '',
    comment: ''
  });
  const [ errors, setErrors ] = useState({});
  const [ validated, setValidated ] = useState(false);
  const [ formSubmitted, setFormSubmitted ] = useState(false);
  const [ memberRef, setMemberRef ] = useState(false);
  const [ selfData, setSelfData ] = useState(false);
  
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      }); 
      const newErrors = findFormErrors();   
      setErrors(newErrors);
      setFormSubmitted(false);
  }

  const findFormErrors = () => {
      const { dj_website, referred_by, ref_required, comment } = formData;
      const newErrors = {}
      if(memberRef){
        if ( !referred_by || referred_by === '' ) newErrors.referred_by = 'Member Refrence Detail cannot be blank!'
      }
      if(selfData){
        if ( !dj_website || dj_website === '' ) newErrors.dj_website = 'Refrence Detail cannot be blank!'
      }
      if(!memberRef && !selfData) newErrors.ref_required = 'Plase select one option from Member Refrence or Social link!'

      return newErrors
  }
  const handleSubmit = (event) => { 
      setFormSubmitted(true);
      const newErrors = findFormErrors();
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      // console.log(newErrors)
      if ( Object.keys(newErrors).length > 0 ) {
          console.log(newErrors)
          // We got errors!
          setErrors(newErrors)
      } else {
        processDjApp({referred_by: formData.referred_by, dj_website: formData.dj_website, comment: formData.comment});
      }
  }

  const toggleMemberRef = () => {
    setMemberRef(!memberRef);
  }
  const toggleSelfData = () => {
    setSelfData(!selfData);
  }


  return(
    <Modal
    show={showState}
    onHide={ShowFunction}
    backdrop="static"
    keyboard={false}
    size="lg"
  >
  <Form  validated={validated} onSubmit={handleSubmit}>
    <Modal.Header>
      <Modal.Title>Are you a DJ?</Modal.Title>
    </Modal.Header>
    {
      djApplicationSuccess ? 
        <Modal.Body>
          <div class="alert alert-success p-3">Each application will be reviewed carefully. One by one to ensure the integrity in our brand & service. If you are a serious DJ you have your own website and/or multiple social network accounts, such as twitter, MySpace, or facebook. We will be visiting your links and making sure you meet our standards to be an official clubkillers member. Once you have completed your application, it will go through a verification process and you will be notified if you are approved via email.</div>
        </Modal.Body>
      :
        <Modal.Body>
          
          <p className="mt-2 mb-4 infoInstuction alert-warning p-2">
          Are you a DJ looking to be part of the most cutting edge digital record pool in the industry? Submit an application if your serious about taking your music selection to another level with exclusive edits & promos handpicked by our elite team of official DJ's. If you are a serious DJ, you have your own multiple social network accounts, such as Twitter, Facebook, Soundcloud or your own personal website, please include as many as you can, We will be visiting your links and making sure you meet our standards to be an official ClubKillers.com member. Each application will be reviewed. Not all applications will be approved.
          </p>
          {responseError ? <div class="alert-danger p-1 mb-3" dangerouslySetInnerHTML={{__html: responseError}}></div> : ''}
          <div className="switch">
            <Form.Group className="mb-3 switch-lg" controlId="formBasicEmail">
              <Form.Check type="switch" id="switchEnabled"  name="member_ref" checked={memberRef} onChange={toggleMemberRef}  label="Have Member Reference?" />
            </Form.Group>
          </div>
          <div className="">
          <Form.Group className="mb-3 switch-lg" controlId="formBasicEmail">
          <Form.Check type="switch" id="switchEnabledRef" name="self_ref" checked={selfData} onChange={toggleSelfData}  label="Have Social link?"  />
          {errors.ref_required ? <div class="invalid">{errors.ref_required} </div>: ''}
          </Form.Group>
          </div>
          { memberRef ? 
            <InputGroup className="mt-3 lineInput">
              
              <Form.Control type="text" placeholder="Enter refrence member email or refrence member social link url" name="referred_by" onChange={handleChange} isInvalid={ !!errors.referred_by } />
              <Form.Control.Feedback type="invalid">
                {errors.referred_by}
              </Form.Control.Feedback>
            </InputGroup>
            : ''
          }
          
          { selfData ? 
            <InputGroup className="mt-3 lineInput">
              
              <Form.Control type="text" placeholder="Enter your social link url" name="dj_website" onChange={handleChange} isInvalid={ !!errors.dj_website } />
              <Form.Control.Feedback type="invalid">
              {errors.dj_website}
              </Form.Control.Feedback>
            </InputGroup>
            : ''
          }
          <InputGroup className="mt-3 lineInput">
              
              <Form.Control as="textarea" rows="3" placeholder="Additional Comment" name="comment" onChange={handleChange} isInvalid={ !!errors.comment } />
              <Form.Control.Feedback type="invalid">
              {errors.comment}
              </Form.Control.Feedback>
            </InputGroup>

        </Modal.Body>
      }
    {
      !djApplicationSuccess ?
      <Modal.Footer>
          {processing ?<Button className="text-uppercase" type="submit" variant="light" className="primary-bg" disabled block> Processing....</Button>  : <Button className="text-uppercase" type="submit" variant="light" className="primary-bg" block> Submit</Button>}
      </Modal.Footer>
      : ''
    }
    </Form>
  </Modal>
  )  
}

export default DjAppModal;
