import React, {useEffect, useState} from "react";

//custom components


// import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-daterangepicker/daterangepicker.css';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';

import Badge from "react-bootstrap/Badge";
import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";

import "./billing.scss";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import config from '../../config/config';

import * as billingAction from '../../store/billing/actions'

const Billing = ({billing, getBillingHistory, displayLoading, hideLoading, prepareInvoice, downloadBillingEntry}) => {

  const [ page, setPage ] = useState(0);
  const [ order, setOrder ] = useState('history_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [ startDate, setStartDate ] = useState();
  const [ endDate, setEndDate ] = useState();
  const [ activeBadge, setActiveBadge ] = useState('all');
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState();

	useEffect(() => { 
		// load Page Data
		loadPageData();
	},[]);
  
	const loadPageData = async() => {
    var yearArray = []
    for (var i=currentYear; i > (currentYear-3); i--) {
      yearArray.push(i);
    }
    setYears(yearArray)
		displayLoading();
		await getBillingHistory({page: page, order: order, orderDir});
		hideLoading();
	}

  const handlePageChange = async (pageNumber) => {
      setPage(pageNumber);
      displayLoading();
      await getBillingHistory({page: pageNumber, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
      hideLoading();
  }
  
  const filterBillingHistory = async() => {
      displayLoading();
      await getBillingHistory({page: 1, order: order, orderDir, start_date: startDate, end_date: endDate}).then((res) => {});
      hideLoading();
  }

  const filterBillingHistoryByYear = async(YY) => {
      setActiveBadge(YY);
      displayLoading();
      if(YY === 'all'){
        await getBillingHistory({page: page, order: order, orderDir});
      } else {
        const start_date = {"day":1, "month":1, "year": YY};
        setStartDate(start_date);
        const end_date = {"day":31, "month":12, "year": YY}
        await getBillingHistory({page: 1, order: order, orderDir, start_date: start_date, end_date: end_date}).then((res) => {});
        setEndDate(end_date);
      }
      hideLoading();
  }

	const downloadBillingById = async (historyId, format='') => {
        displayLoading();
        await downloadBillingEntry({ history_id: historyId, format:format }).then((res) => {
          if(res.payload.data && res.payload.data.url){
            downloadFile(res.payload.data)
          }
		    });
		    hideLoading();
    }

	const dowloadInvoice = async(format) => {
		
		displayLoading();
		await prepareInvoice({format: format, startDate: startDate, endDate: endDate}).then((res) => {
			if(res.payload.data && res.payload.data.url){
				downloadFile(res.payload.data)
			}
		});
		hideLoading();
	}

	const downloadFile = (data) => {
      var downloadurl = `${config.uploadUrl}site/download?url=${data.url}&file=${data.file}`;
      // const downloadurl = data.url;
      window.location.assign(downloadurl);
  }


//   console.log('billing', billing)

  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Subscription</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
        <Card className="border-0">
              <Card.Body>
                <div className="cardMark mb-3">Billing history</div>

                <Container fluid className="p-0 mb-4">
                  <Row className="align-items-center">
                    <div>
                      <div className="badgeList d-none d-lg-block">
                        <Badge variant={activeBadge === 'all' ? 'active' : ''} onClick={() => filterBillingHistoryByYear('all')}>All</Badge>
                        {
                          years && years.length > 0 ? 
                            years.map((year, index) => {
                              return <Badge variant={activeBadge === year ? 'active' : ''} key={`year${year}`} onClick={() => filterBillingHistoryByYear(year)}>{year}</Badge>
                            })
                          : ''
                        }
                      </div>
                    </div>
                    <Col>
                    <DatePicker
                        value={startDate}
                        onChange={setStartDate}
                        inputPlaceholder="From"
                        shouldHighlightWeekends
                     /> &nbsp;
                     <DatePicker
                        value={endDate}
                        onChange={setEndDate}
                        inputPlaceholder="To"
                        shouldHighlightWeekends
                     /> &nbsp;
                     <Button variant="light" className="primary-bg" size="sm" onClick={()=>filterBillingHistory()}><i className="lnir lnir-search"></i></Button>
                    </Col>
                    <Col className="text-right">
                      <Button variant="light" size="sm" onClick={()=> dowloadInvoice('pdf')}><i className="lnir lnir-download mr-1"></i> All Invoices (PDF)</Button>
                      <Button variant="light" className="ml-4" size="sm" onClick={()=> dowloadInvoice('excel')}><i className="lnir lnir-download mr-1"></i> All Invoices (Excel)</Button>
                    </Col>
                  </Row>
                </Container>

                <Table hover responsive>
					<thead>
						<tr>
						<th>Date</th>
						<th>Type</th>
						<th>Payment Method</th>
						<th>Amount</th>
						<th>Invoice</th>
						</tr>
					</thead>
					{
						billing && billing.billing_history && billing.billing_history.length > 0 ?
						<tbody>
							{
								billing.billing_history.map((history, index)=>{
									return <tr key={`billing${index}`}>
											<td>{history.payment_date}</td>
											<td>{history.plan_name}</td>
											<td>{history.payment_method}</td>
											<td>{history.amount}</td>
											<td className="text-uppercase">	
												<span  onClick={()=> downloadBillingById(history.history_id, 'pdf')}>PDF</span> &nbsp;
												<span onClick={()=> downloadBillingById(history.history_id, 'excel')}>Excel</span>
											</td>
									</tr>
								})
							}	
							
						</tbody>
						: ''
					}
				</Table>
				<div className="pagin text-center mt-5 mb-5">
          {
          billing && billing.pagination ? 
            <Pagination
                  activePage={billing.pagination.page}
                  itemsCountPerPage={billing.pagination.perPageLimit}
                  totalItemsCount={billing.pagination.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>
              </Card.Body>
            </Card>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
  return {
      billing: state.billing.billing
  };
}

function mapDispatchToProps(dispatch) {
  return {
		getBillingHistory: (post) => dispatch(billingAction.getBilling(post)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
		prepareInvoice: (post) => dispatch(billingAction.prepareInvoice(post)),
		downloadBillingEntry: (post) => dispatch(billingAction.downloadBillingEntry(post)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Billing);