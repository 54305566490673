import * as types from './type';

import { FileContainer }  from  '../../services/filecontainer';

export function getContainerSongsList() {
    return {
      type: types.GET_CONTAINER_SONG,
      payload: FileContainer.getContainerSongsList(),
    };
};

 
export function removeUploadedSong(data) {
  return {
    type: types.REMOVE_UPLOADED_SONG,
    payload: FileContainer.removeUploadedSong(data),
  };
};


// export function getContainerVideosList(data) {
//   return {
//     type: types.GET_CONTAINER_VIDEO,
//     payload: FileContainer.getContainerVideosList(data),
//   };
// };


// export function removeUploadedVideo(data) {
//   return {
//     type: types.REMOVE_UPLOADED_VIDEO,
//     payload: FileContainer.removeUploadedVideo(data),
//   };
// };