import React, { useState, useEffect  } from "react";
import "./playgroup.scss";

import AlbumImg from '../../assets/img/album-img.png'
import DownloadVersionButton from '../actionBtn/downloadVersion';
import DownloadBundelSongButton from '../actionBtn/downloadBundleSong';
import FavouriteButton from '../actionBtn/addToFavourite';
import CrateButton from '../actionBtn/addToCrate';
import PlayPauseButton from '../actionBtn/playPauseBtn';
import {connect} from 'react-redux';
import * as actions from '../../store/song/actions';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import { useHistory } from "react-router-dom";


const PlayGroup = ({ songs, isPlaying, currentSong, customerDownloads }) =>  {
  const history = useHistory();
	const [activeSong, setActiveSong] = useState();
	const [activeSongId, setActiveSongId] = useState(0);
	const [isListExpanded, setIsListExpanded] = useState(false);
	const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
	
	
	
  
	const showSongVersion = (song) => {
		if(song.entity_id == activeSongId || activeSongId == 0){
			setIsListExpanded(!isListExpanded);
		}
		setActiveSongId(song.entity_id);
		setActiveSong(song);
	}

	const markActiveAlbumSong = (bool) => {
		setActiveAlbumSong(bool)
	}

  console.log('customerDownloads', customerDownloads)

  return(
    <div>
        {
          songs && songs.length > 0 ? 
          songs.map((song, index)=>{
        return <div className="playGroup" key={`homesongs${song.entity_id}`}>
					<div className="d-flex align-items-center topView flex-wrap">
						
						{
							isPlaying &&  currentSong && currentSong.songData  && currentSong.id == song.entity_id ?
								<div className= "count active">
									<PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
								</div>
							: 
								<div className= "count"><span>{index+1}</span>
									<PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
								</div>
						}
						<div className="info d-flex align-items-center">
							<div className="img">
								<figure>
								<img src={AlbumImg}  />
								</figure>
							</div>
							<aside>
								<div className="musicTitle text-truncate" dangerouslySetInnerHTML={{__html: song.title}}></div>
								<div className="musicCreator" dangerouslySetInnerHTML={{__html: song.artist}}></div>
							</aside>
						</div>
						<div className="action ml-auto text-nowrap">
							<FavouriteButton song={song} />
							<DownloadBundelSongButton entity_id={song.entity_id} />
							<span className="ml-3 pointer" onClick={()=> showSongVersion(song)}><i className="lnir lnir-chevron-down"></i></span>
						</div>
						<div className="d-flex align-items-center justify-content-around infoMid">
							<div className="songType">
								<div className="text-nowrap"><span>BPM:</span> { song.min_bpm === song.max_bpm ? <span>{song.min_bpm}</span> : <span>{song.min_bpm} - {song.max_bpm}</span>}</div>
								<div className="text-nowrap"><span>Key:</span> {song.song_key}</div>
							</div>
							<div className="createDate">
								<span>Date</span>
								<div className="text-nowrap">{song.created_at}</div>
							</div>
							<div className="genre">
								<span>Genre</span>
								<div dangerouslySetInnerHTML={{__html: song.genres}}></div>
							</div>
							<div className="version">
								<span>Version</span>
								<div className="text-nowrap">{song.version.length} versions</div>
							</div>
						</div>
					</div>
					<div className={ activeSongId == song.entity_id && isListExpanded ? "expandPlaylist" : "expandPlaylist d-none"}>
						{
							song.version.map((version, index) => {
								return 	<div className="d-flex align-items-center song" key={`song${version.song_version_id}`}>
											<PlayPauseButton version={version} song={song} markActiveAlbumSong={setActiveAlbumSong} />
											<div className="titleName">
												<span>{version.version_label}</span>
											</div>
											<div className="ml-auto downloadCheck d-flex">
													{
														version.download.map((download, downloadIndex) => {
														return <i key={`download${version}-${downloadIndex}`} id={downloadIndex} className={ customerDownloads && customerDownloads.hasOwnProperty(version.song_version_id.toString()) && parseInt(customerDownloads[version.song_version_id.toString()]) > (downloadIndex) ? "lnir lnir-checkmark-circle active" : "lnir lnir-checkmark-circle"}></i>
														})
													}
											</div>
											<div className="action ml-3 d-flex"> 
												<DownloadVersionButton song={song} version={version} />
												<CrateButton song={song} version={version} />
											</div>
										</div>
							})
						}
					</div>
				</div>
			})
			: ''
		}
	</div>
    )
  
}

function mapStateToProps(state) {
  	return {
		crateSongs: state.song.customerCurates,
		playLists: state.playlist.playListSongs,
		currentSong: state.song.currentSong,
		userProfile: state.customer.customerProfile,
		isPlaying: state.song.isPlaying,
		currentSong: state.song.currentSong,
		customerDownloads: state.download.customerDownloads
  	};
 }
 
 function mapDispatchToProps(dispatch) {
  	return {
		addSongToCrate: (songId, versionId) => dispatch(actions.addSongToCrate(songId, versionId)),
		getPlayList: () => dispatch(playlistActions.getPlayList()),
		downloadSongVersion: (version) => dispatch(downloadActions.downloadSongVersion(version)),
		getCustomerCrates: () => dispatch(songActions.getCustomerCrates())
	};
 }

export default connect(mapStateToProps, mapDispatchToProps)(PlayGroup);
