import React , { useEffect } from "react";
import "./announcement.scss";

import { NavLink } from 'react-router-dom'
import {connect} from 'react-redux';
import * as commonActions from '../../store/common/actions';

const Announcement = ({page_name, getAnnouncement, announcement, hideAnnouncement}) =>  {

  
 
  useEffect(() => { 
    // Get Announcement
    getAnnouncement({page_name: page_name});
    
  },[])
     
  const markReadMessage = (messageId) => {
		hideAnnouncement({message_id: messageId, page_name: 'mp3_home'});
	}
  
  return(
          announcement && announcement.length > 0 ? 
            announcement.map((item, index) => {
              return <div className="alert alert-warning position-relative" key={`announcement-${index}`}> <div dangerouslySetInnerHTML={{__html: item.message}}></div><span className="lnir lnir-close gm-close" onClick={()=> markReadMessage(item.message_id)}></span></div>
            })
          : ''
      )
  
}


function mapStateToProps(state) {
  return {
    announcement: state.common.announcement
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getAnnouncement:(data) => dispatch(commonActions.getAnnouncement(data)),
	  hideAnnouncement:(data) => dispatch(commonActions.hideAnnouncement(data))
  };
 }
export default connect(mapStateToProps, mapDispatchToProps)(Announcement);