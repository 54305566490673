import * as types from './type';

const initialState = {
    playListSongs: [],
    playListIndex: ''
};

export default function playlistReducer(state = initialState, action) {
    switch (action.type) {
        case types.ADD_SONG_TO_PLAYLIST: {

            if (action.payload && action.payload) {
                return {
                    ...state,
                    playListSongs: [...state.playListSongs, action.payload]
                }
            } else {
                return {
                    ...state
                }
            }
        
        }
        case types.GET_CUSTOMER_PLAYLIST: {
            return {
                ...state,
                playListSongs: [...state.playListSongs]
            }
        }
        case types.SET_PLAYLIST_INDEX: {
            return {
                ...state,
                playListIndex: action.payload
            }
        }
        
        default: {
            return {
              ...state
            };
          }
    }
}