import React, {useEffect, useState} from "react";


import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";


import payPal from "../../assets/img/paypal.svg";
import creditCard from "../../assets/img/credit-card.svg";
import appleGooglePayIcon from "../../assets/img/apple-google-icon-png.png";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import Form from 'react-bootstrap/Form'

import "./subscription.scss";

import FormControl from 'react-bootstrap/FormControl';
import InputGroup from 'react-bootstrap/InputGroup';

import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions'
import { useLocation, useRouteMatch, useHistory } from "react-router-dom";
import * as couponAction from '../../store/coupon/actions'
import { statement } from "@babel/template";

const ConfirmPayment = ({displayLoading, hideLoading, getCustomerPlan, customerPlan, confirmPaypalPayment, confirmPayment}) => {

    let match = useRouteMatch("/subscription/confirm-payment/:customerplan");
    const location = useLocation();
    const history = useHistory();
    const [ coupon, setCoupon ] = useState();
    const [subscription, setSubscription] = useState({
        payment_method: 'paypal',
        service_id: '',
        customer_plan_id: match.params.customerplan,
        token: ''
    });
    const [ paymentToken, setPaymentToken ] = useState();
    
    
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);
    
    
    
	const loadPageData = async() => {
        const queryString = require('query-string');
        var parsed = queryString.parse(location.search);
        setPaymentToken(parsed.token);
        let subscriptionData = subscription;
        subscriptionData.token = parsed.token
        setSubscription(subscriptionData);
   		displayLoading();
        await getCustomerPlan(subscription);
		hideLoading();
    }
    
    const processPayment = async() => {
        let postData = customerPlan;
        postData.payment_token = paymentToken
        confirmPaypalPayment(postData).then(res=> {
            if(res && res.payload && res.payload.data){
                if(res.payload.data.status){
                    history.push('/subscription/success')
                } else {
                    history.push('/subscription/failed')
                }
            }
        })
    }

    
    
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12} className="text-center mb-4">
                            <h2 className="mb-3">Confirm Payment</h2>
                            <p className="text-muted">
                                Please review your membership details and billing information to complete your order
                            </p>
                        </Col>
                    </Row>
                    
                    <Row className="justify-content-center">
                        
                        <Col lg={4}>
                            {
                                customerPlan ?
                           
                                    <Card className="border-0">
                                        <Card.Body>
                                        <h5 className="plan-title text-uppercase mb-3 text-center"><b>{customerPlan.plan_description}</b></h5>
                                            <div className="payment-summary bg-light p-3 mb-4">
                                                <table class="w-100 table mb-0">
                                                    {
                                                        customerPlan ? 
                                                            <tr>
                                                                <td className="border-0">{customerPlan.plan_name}</td>
                                                                <td className="border-0">${customerPlan && customerPlan.discount_amount && parseInt(customerPlan.discount_amount) > 0  ?( parseInt(customerPlan.discount_amount) +  parseInt(customerPlan.amount)).toFixed(2) :  parseInt(customerPlan.amount).toFixed(2) }</td>
                                                            </tr>
                                                        : '' 
                                                    }
                                                    {
                                                        customerPlan && customerPlan.discount_amount && parseInt(customerPlan.discount_amount) > 0  ? 
                                                        <tr>
                                                            <td className="border-0">Discount</td>
                                                            <td className="text-danger border-0">-${parseInt(customerPlan.discount_amount).toFixed(2)}</td>
                                                        </tr>
                                                        : ''
                                                    }
                                                    {
                                                        customerPlan ? 
                                                            <tr className="border-grey border-top">
                                                                <td><h5 className="mb-0">Total</h5></td>
                                                                <td><h5 className="mb-0 text-success">${ parseInt(customerPlan.amount).toFixed(2) }</h5></td>
                                                            </tr>
                                                        : ''
                                                    }
                                                </table>
                                                
                                            </div>
                                            
                                                <div className="action mt-2">
                                                    <Button variant="light" onClick={() => processPayment()} className="btn btn-light primary-bg btn-block p-3">Proceed to Payment</Button>
                                                </div>
                                        </Card.Body>
                                    </Card>
                                : ''
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
    return {
        agreement: state.subscription.agreement,
        customerPlan: state.subscription.customerPlan,
        confirmPayment: state.subscription.confirmPayment
    };
}

function mapDispatchToProps(dispatch) {
  	return {
        // getServices: (post) => dispatch(subscriptionAction.getServices(post)),
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        // validateCoupon: (post) => dispatch(couponAction.validateCoupon(post)),
        // getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
        confirmPaypalPayment: (post) => dispatch(subscriptionAction.confirmPaypalPayment(post)),
        getCustomerPlan: (post) => dispatch(subscriptionAction.getCustomerPlan(post))
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPayment);