import {axiosReqGet, axiosReqOther} from "./config";
import { store } from '../store/store';

const USER_TOKEN = store.getState().authentication.user;

export const Trending = {
    getLatestTrendingSong
}

function getLatestTrendingSong(){
    const url = 'trending-track/get-trending-tracks.json?store_id=1&lang_code=en';
    const data = {token:USER_TOKEN };
    return axiosReqOther(url, data, 'post').then(res => {              
        return res;       
    })
    .catch(err => { 
        // alert(err.response.data.message)
        return err; 
    });
}
    

export default Trending;