import * as types from './type';

import { Common }  from  '../../services/common';

export function getCountryList() {
    return {
      type: types.GET_COUNTRY_LIST,
      payload: Common.getCountryList(),
    };
};

export function getAnnouncement(data) {
  return {
    type: types.GET_ANNOUNCEMENT,
    payload: Common.getAnnouncement(data),
  };
};


export function hideAnnouncement(data) {
  return {
    type: types.HIDE_ANNOUNCEMENT,
    payload: Common.hideAnnouncement(data),
  };
};