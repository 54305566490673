import React, {useEffect, useState} from "react";

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Card from 'react-bootstrap/Card';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as helpdeskAction from '../../store/helpdesk/actions';
import Spinner from 'react-bootstrap/Spinner';

const SubmitTicket = ({ displayLoading, hideLoading, departments,  priorities, submitTicket, getRecentTickets, getDepartments, getPriorities}) => {
    const [formData, setFormData] = useState({
        department_id: '',
        priority_id: '',
        details: '',
        subject: '',
        ip: ''
    });

    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const [ successMessage, setSuccessMessage ] = useState();
    const [ isSubmitted, setIsSubmitted ] = useState(false);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
    }

    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);

    const loadPageData = async() => {
		displayLoading();
        await getDepartments();
        await getPriorities();
		hideLoading();
  }

    const findFormErrors = () => {
        const { department_id, priority_id, details, subject } = formData
        const newErrors = {}
        // password errors
        if ( !department_id || department_id === '' ) newErrors.department_id = 'Please select the department'
        if ( !priority_id || priority_id === '' ) newErrors.priority_id = 'Please select the priority'
        // confirm password errors
        if ( !details || details === '' ) newErrors.details = 'Description cannot be blank!'
        if ( !subject || subject === '' ) newErrors.subject = 'Subject cannot be blank!'

        return newErrors
    }

    const handleSubmit = (event) => {
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();

        if ( Object.keys(newErrors).length > 0 ) {
            // We got errors!
            setErrors(newErrors)
        } else {
            setErrors('')
            sendRequest(formData);
        }
    }
    const sendRequest = async(data) => {
        displayLoading();
        setIsSubmitted(true);
        await submitTicket({HelpdeskTickets:formData}).then(res =>{
            if(res && res.payload && res.payload.data){
                
                setSuccessMessage('Ticket has been submitted successfully');
                setFormData({
                    department_id: '',
                    priority_id: '',
                    details: '',
                    subject: '',
                    ip: ''
                });
                getRecentTickets().then(res => {
                    setSuccessMessage('');
                })
            }
        });
        setIsSubmitted(false);
        hideLoading();
    }
    return (
        <Card className="border-0">
            <Card.Body>
            {/* <div className="cardMark mb-3"><h5 className="card-heading text-uppercase">Submit a ticket</h5></div> */}
            <h5 className="text-uppercase"><b>Submit a ticket</b></h5>

            <p className="text-muted">Choose the correct department to submit your ticket. Ticket are answered as soon as possible. If you have not heard back in 24 hours please revisit the support as you may not have not received the email notification.</p>
            { successMessage && successMessage !== '' ? <div className="alert alert-success">{successMessage}</div> : ''}
            <Form  validated={validated} onSubmit={handleSubmit}>
                <div className="mb-3">
                {
                departments && departments.length > 0 ?
                <Form.Group className="mb-3" controlId="formDepartmentId" >
                    <Form.Label>Department</Form.Label> <br />
                    {
                    departments.map((department, index) => {
                        return <Form.Check 
                        inline
                        label={department.name}
                        name="department_id"
                        type="radio"
                        id={`inline-radio-${department.id}`} 
                        onChange={handleChange} 
                        key={`radio-${department.id}`} 
                        isInvalid={ !!errors.department_id }
                        value={department.id}
                        />
                    })
                    
                    }
                    <Form.Control.Feedback type="invalid">
                            { errors.department_id }
                    </Form.Control.Feedback>
                </Form.Group>
                : ''
                }
                <Form.Group className="mb-3" controlId="formSubject" >
                    <Form.Label>Subject</Form.Label>
                    <Form.Control  type="text" placeholder="Subject" name="subject"  onChange={handleChange} isInvalid={ !!errors.subject } />
                    <Form.Control.Feedback type="invalid">
                        { errors.subject }
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formPriorityId">
                    <Form.Label>Priority </Form.Label>
                    <Form.Control  as="select" type="select" name="priority_id" onChange={handleChange} isInvalid={ !!errors.priority_id }>
                        <option value="">Select Priorirty </option> 
                        {
                            priorities && priorities.length > 0 ?
                            priorities.map((priority, index)=>{
                                return <option value={priority.id} key={`country-${priority.id}`}>{priority.name}</option>
                            })
                            : ''
                        }
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                        { errors.priority_id }
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formDetails" >
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" placeholder="Please enter the details of your request. A member of our support staff will responde as soon as possible" style={{ height: '100px' }} name="details"  onChange={handleChange} isInvalid={ !!errors.details } />
                    <Form.Control.Feedback type="invalid">
                        { errors.details }
                    </Form.Control.Feedback>
                </Form.Group>
                </div>
                <div>
                    {
                        isSubmitted ? <Button variant="dark" className="btn-block p-3 text-uppercase"><Spinner animation="border" size="lg" variant="warning" /></Button>
                        : <Button type="submit" variant="light" className="primary-bg btn-block p-3 text-uppercase">Submit your Ticket</Button>
                    }
                    
                </div>
            </Form>
            </Card.Body>
        </Card>
    )
}
function mapStateToProps(state) {
    return {
        departments: state.helpdesk.departments,
        priorities: state.helpdesk.priorities
    };
}

function mapDispatchToProps(dispatch) {
    return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        submitTicket: (post) => dispatch(helpdeskAction.submitTicket(post)),
        getRecentTickets: (post) => dispatch(helpdeskAction.getRecentTickets(post)),
        getDepartments: () => dispatch(helpdeskAction.getDepartments()),
        getPriorities: () => dispatch(helpdeskAction.getPriorities())
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(SubmitTicket);