import React, {useEffect, useState} from "react";

//custom components

import AccountNav from "../../elements/accountNav/accountNav";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";

import "./uploads.scss";

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import config from '../../config/config';

import * as uploadsAction from '../../store/uploads/actions';
import * as songAction from '../../store/song/actions'

const SongList = ({songLists, getUploadedSongs, displayLoading, hideLoading, renewSong, deleteSong}) => {

  const [ page, setPage ] = useState();
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');
  const [deleteEntityId, setDeleteEntityId ] = useState();
  const MySwal = withReactContent(Swal)

	useEffect(() => { 
		// load Page Data
		loadPageData();
	},[]);
  
	const loadPageData = async() => {
    
		displayLoading();
		await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
		hideLoading();
	}

  	const handlePageChange = async (pageNumber) => {
      setPage(pageNumber);
      displayLoading();
      await getUploadedSongs({song_sectoin_id: 1,  page: pageNumber, pageLimit: 20, orderDir: orderDir, order: order});
      hideLoading();
  	}

  	/**
     * Method: renewSong
     */
    const makeSongRenew = async (entity_id, event) => {
        const renewSongConfirm = window.confirm('Are you sure you want to renew this song?');
        if(renewSongConfirm){
            displayLoading();
            await renewSong({entity_id: entity_id, page: 0, pageLimit: 20});
            await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
            hideLoading();
        }
    }
  const confirmDelete = (entity_id) => {
     
      var entity_id = entity_id;
      setDeleteEntityId(entity_id);
      MySwal.fire({
          title: "Are you sure?",
          text: "You want to remove the song uploaded?",
          type: "info",
          showCancelButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false,
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
      }).then(res => {
        deleteSongEntity(entity_id)
      });
  }

  const deleteSongEntity = async(entity_id) => {
    displayLoading();
    await deleteSong({entity_id: entity_id}).then(res => {

    });
    await getUploadedSongs({song_sectoin_id: 1,  page: 1, pageLimit: 20, orderDir: orderDir, order: order});
    hideLoading();
  }
  
  

  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">Upload Song List</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
        <Card className="border-0">
              <Card.Body>
                <div className="cardMark mb-3 d-flex justify-content-between"><span>Song List</span> <span className=""><Link to={`/uploads/new`} className="btn btn-light primary-bg">Add New</Link></span></div>
                <Table hover responsive>
                  	<thead>
						<tr>
							<th>Title</th>
							<th>Artist</th>
							<th>Corrupt Versions</th>
							<th>Action</th>
						</tr>
                  	</thead>
                  {
                    songLists && songLists.data && songLists.data.length > 0 ?
                    <tbody>
                      {
                        songLists.data.map((item, index)=>{
                          return <tr key={`uploadlist${index}`}>
                              <td>{item.title}</td>
                              <td>{item.artist}</td>
                              <td>{item.corrupt_version}</td>
                              <td className="text-uppercase">	
                                <span  onClick={()=> makeSongRenew(item.entity_id)}>Renew</span> &nbsp;
								                <Link to={`/uploads/edit-song/${item.entity_id}`}>Edit</Link> &nbsp;
                                <span onClick={()=> confirmDelete(item.entity_id)}>Delete</span>
                              </td>
                          </tr>
                        })
                      }	
                      
                    </tbody>
                    : ''
                  }
				        </Table>
				<div className="pagin text-center mt-5 mb-5">
				{
				songLists && songLists.pagination ? 
					<Pagination
						activePage={songLists.pagination.page}
						itemsCountPerPage={songLists.pagination.perPageLimit}
						totalItemsCount={songLists.pagination.totalCount}
						// pageRangeDisplayed={pageRangeDisplayed}
						onChange={handlePageChange}
						itemClass="page-item"
						linkClass="page-link"
				/> : ''
				}
				</div>
              </Card.Body>
            </Card>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
  return {
    songLists: state.uploads.songList
  };
}

function mapDispatchToProps(dispatch) {
  return {
		getUploadedSongs: (post) => dispatch(uploadsAction.getUploadedSongs(post)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    renewSong: (post) => dispatch(songAction.renewSong(post)),
    deleteSong: (post) => dispatch(songAction.deleteSong(post))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SongList);