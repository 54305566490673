import React, {useEffect, useState} from "react";


import Card from 'react-bootstrap/Card';
import { showLoading, hideLoading } from 'react-redux-loading-bar';

import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import * as helpdeskAction from '../../store/helpdesk/actions'

const RecentTickets = ({ displayLoading, hideLoading, getRecentTickets, recentTickets}) => {

  

  useEffect(() => { 
		// load Page Data
		loadPageData();
  },[]);

  const loadPageData = async() => {
		displayLoading();
        await getRecentTickets({page: 1, pageLimit: 5})
		hideLoading();
  }
  
  

// const [submited, setSubmited] = useState(false);




  
  return (
    <Card className="border-0">
        <Card.Body>
        <div className="d-flex justify-content-between">
            <div>
                <h5 className="text-uppercase"><b>Your Tickets</b></h5>
            </div>
            <div>
                <Link to={`/tickets/all`}>See All Ticket</Link>
            </div>
        </div>

        
        {recentTickets && recentTickets.tickets && recentTickets.tickets.length > 0 ? 
            recentTickets.tickets.map((ticket, index) => {
                return <div className="recentTicket" key={`recentTicket${index}`}>
                    <div className="t-sub">{ticket.subject}</div>
                    <div className="d-flex justify-content-between">
                        <div className="text-muted mr-2 ticket-number">#{ticket.ticket_number}</div>
                        <div className="mr-2">{ticket.modified_at}</div>
                        <div className="action">
                            <Link to={`/ticket/details/${ticket.ticket_number}`} className={`status-${ticket.status}`}>{ticket.status}</Link>
                        </div>
                    </div>
                </div>
            })
            : 'You have no tickets'
        }
        
        </Card.Body>
    </Card>
  );
}
function mapStateToProps(state) {
    return {
        recentTickets: state.helpdesk.recentTickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getRecentTickets: (post) => dispatch(helpdeskAction.getRecentTickets(post)),
        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecentTickets);