import logo from "../logo.svg";
import "./App.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import { Provider } from 'react-redux';
import { store } from '../store/store';


//custom components ----------------------------------------------------------------

import Player from "../components/player/player";



// Page Imports ----------------------------------------------------------------
import Home from "../pages/home/home";
import Charts from "../pages/charts/charts";
import Playlist from "../pages/playlist/playlist";
import RecentPlayed from "../pages/recentPlayed/recentPlayed";
import NewRelease from "../pages/newRelease/newRelease";
import Spotlight from "../pages/spotlight/spotlight";
import GenreSpotlight from "../pages/spotlight/genreSpotlight";
import SpotlightSongs from "../pages/spotlight/spotlightSongs"
import Genres from "../pages/genres/genres";
import GenreView from "../pages/genres/genreView";
import Profile from "../pages/profile/profile";
import Crate from "../pages/crate/crate";
import FavouriteSongs from "../pages/favourite/favourite";
import Subscription from "../pages/subscription/subscription";
import Billing from "../pages/billing/billing";
import Career from "../pages/career/career";
import ChangePassword from "../pages/changePassword/changePassword";
import Helpdesk from "../pages/helpdesk/helpdesk";
import Tickets from "../pages/helpdesk/tickets";
import ViewTicket from "../pages/helpdesk/viewTicket";
import SubscriptionPlans from '../pages/subscription/plans';
import PaymentMethod from '../pages/subscription/paymentMethod';
import DownloadRescue from '../pages/download/downloadRescue';
import ConfirmPayment from "../pages/subscription/confirmPayment";
import FailedPayment from "../pages/subscription/failedPayment";
import SuccessPayment from "../pages/subscription/successPayment";
import MobileWallet from "../pages/subscription/mobileWallet";
import SongList from "../pages/uploads/songList";
import AddSong from "../pages/uploads/addSong";
import EditSong from "../pages/uploads/editSong";
import SearchResultMp3 from "../pages/mp3SearchResult/searchResultMp3";
import Logout from "../pages/profile/logout";
import Top20Index from "../pages/top20/index";
import Top20Songs from "../pages/top20/top20Songs";

function App() {
  return (
    <Provider store={store}>
    <Player/>
      <Router>
        <Switch>
          <Route path="/logout">
            <Logout />
          </Route>
          <Route path="/genres/index">
            <Genres />
          </Route>
          <Route path="/genres/view/:genre_id">
            <GenreView />
          </Route>
          <Route path="/search/result/">
            <SearchResultMp3 />
          </Route>
          <Route path="/search/result/:searchTerm">
            <SearchResultMp3 />
          </Route>
          <Route path="/spotlight/index">
            <Spotlight />
          </Route>
          <Route path="/spotlight/filter/:genre_id">
            <GenreSpotlight />
          </Route>
          <Route path="/top20/tracks">
            <Top20Index />
          </Route>
          <Route path="/top20/songs/:id">
            <Top20Songs />
          </Route>
          <Route path="/spotlight/view/:id">
            <SpotlightSongs />
          </Route>
          <Route path="/newRelease">
            <NewRelease />
          </Route>
          <Route path="/recentPlayed">
            <RecentPlayed />
          </Route>
          <Route path="/playlist">
            <Playlist />
          </Route>
          <Route path="/charts">
            <Charts />
          </Route>
          <Route path="/account/update">
            <Profile />
          </Route>
          <Route path="/account/manage-subscription">
            <Subscription />
          </Route>
          <Route path="/subscription/plans">
            <SubscriptionPlans />
          </Route>
          <Route path="/subscription/choose-payment/:planId">
            <PaymentMethod />
          </Route>
          <Route path="/subscription/confirm-payment/:customerplan">
            <ConfirmPayment />
          </Route>
          <Route path="/subscription/mobile-wallet/:planId">
            <MobileWallet />
          </Route>
          <Route path="/subscription/failed">
            <FailedPayment />
          </Route>
           <Route path="/subscription/success/:profileId">
            <SuccessPayment />
          </Route>
          <Route path="/uploads/index">
            <SongList />
          </Route>
          <Route path="/uploads/new">
            <AddSong />
          </Route>
          <Route path="/uploads/edit-song/:entity_id">
            <EditSong />
          </Route>
          <Route path="/account/billing">
            <Billing />
          </Route>
          <Route path="/account/change-password">
            <ChangePassword />
          </Route>
          <Route path="/crate">
            <Crate />
          </Route>
          <Route path="/favourite-songs">
            <FavouriteSongs />
          </Route>
          <Route path="/tickets/all">
            <Tickets />
          </Route>
          <Route path="/tickets/open">
            <Tickets />
          </Route>
          <Route path="/tickets/close">
            <Tickets />
          </Route>
          <Route path="/ticket/details/:ticketNumber">
            <ViewTicket />
          </Route>
          <Route path="/support/faqs">
            <Helpdesk />
          </Route>
          <Route path="/career/listing">
            <Career />
          </Route>
          <Route path="/download-rescue">
            <DownloadRescue />
          </Route>
          <Route path="/">
            <Home />
          </Route>
          <Route path="/reset-password/:email/:token">
            <Home />
          </Route>
        </Switch>
      </Router>
    </Provider>


  );
}

export default App;
