import React, { useState, useEffect } from "react";

import { Link } from 'react-router-dom';
import {  useRouteMatch } from "react-router-dom";

import {connect} from 'react-redux';
import * as genreActions from '../../store/genres/actions';

const CuratedGenres = ({getCuratedGenresWithResult, curatedFilterWithResult, curatedGenres}) => {

	let match = useRouteMatch("/spotlight/filter/:genre_id");

	const [ currentGenre, setCurrentGenre ] = useState();
	// const [ curatedGenres, setCuratedGenres ] = useState();

    useEffect(() => { 
        getCuratedGenresWithResult().then(res => {
			
			if(match && match.params && match.params.genre_id && curatedGenres && curatedGenres.current_genre){
				setCurrentGenre(curatedGenres.current_genre.parent_id);
			} else {
				const genres =  res.payload.data.genres;
				const activeGenre = genres.filter(genre => genre.isActive === true);
				setCurrentGenre(activeGenre[0].genre_id);
			}
			
		});
	},[])

	useEffect(() => { 
		if(curatedGenres && curatedGenres.current_genre){
			setCurrentGenre(curatedGenres.current_genre.parent_id);
		}
		
	},[curatedGenres])
	const genreAccordian = (generId) => {

	}

    // console.log(curatedFilterWithResult);

    return (
      	<aside>
			<h3>Curated Genres</h3>
			{
				curatedFilterWithResult && curatedFilterWithResult.genres && curatedFilterWithResult.genres.length > 0 ?
					curatedFilterWithResult.genres.map((genre, index) => {
						return <div className="playCategory" key={`curatedGenre-${genre.genre_id}`}>
									<div className={currentGenre == genre.genre_id ? "title d-flex active justify-content-between pt-2 pb-2 align-items-center" : "title d-flex justify-content-between pt-2 pb-2 align-items-center"} onClick={()=> setCurrentGenre(genre.genre_id)}>
										<div className="tile" dangerouslySetInnerHTML={{__html: genre.title}}></div>
										{/* <span>{genre.genre_id}</span> */}
										<i className={currentGenre == genre.genre_id ? "lnir lnir-chevron-up" : "lnir lnir-chevron-down" }></i>
									</div>
									{ 
										genre && genre.subgenres && genre.subgenres.length > 0 ?
											<div className={ currentGenre == genre.genre_id ? 'content' : 'content d-none'}>
												<ul>
													{
														genre.subgenres.map((subgenre, sIndex) => {
															return <li key={`curatedSubGener-${subgenre.genre_id}`}>
																
																<Link to={`/spotlight/filter/${subgenre.genre_id}`} >{match && match.params && match.params.genre_id && match.params.genre_id == subgenre.genre_id ? <i className="lnir lnir-checkmark mr-1" /> : ''} <span dangerouslySetInnerHTML={{__html: subgenre.name}} /></Link>
															</li>
														})
													}
												</ul>
											</div>
										: ''
									}
								</div>
					})
				: ''
			}
		</aside>
    )
}



function mapStateToProps(state) {
    return {
		curatedFilterWithResult: state.genre.curatedGenres,
		curatedGenres: state.curated.curatedGenres
    };
}
    
function mapDispatchToProps(dispatch) {
    return {
        getCuratedGenresWithResult: () => dispatch(genreActions.getCuratedGenresWithResult())
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CuratedGenres);