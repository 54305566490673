import React, {useState} from "react";
import "./mp3Modal.scss";


import Modal from 'react-bootstrap/Modal';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import {connect} from 'react-redux';



const SelectSongModal = ({showState, ShowFunction, fileLists, setSong}) =>  {
  
    const removeUploaderSong = (song) => {

    }

    const handleChange = (item) => {
        setSong(item);
    }

    if(fileLists && fileLists.length > 0){
        return(
            <Modal
            show={showState}
            onHide={ShowFunction}
            backdrop="static"
            keyboard={false}
            size="md"
            >
                <Modal.Header>
                <Modal.Title>Select Song</Modal.Title>
                </Modal.Header>
                <div className="p-4">
                        {   fileLists && fileLists && fileLists.length > 0 ? fileLists.map((item, i) => {
                                return <div key={i} className="d-flex justify-content-between border-bottom">
                                    <div className="p-2">
                                    
                                        <input type="radio" name="song" value={item.file} onChange={ () => handleChange(item)}  />{' '}
                                    
                                    </div>
                                    <div className="p-2">{item.file}</div>
                                    <div className="ml-auto p-2 d-flex">
                                        <i onClick={(e) => removeUploaderSong({songName: item})} className="lnir lnir-trash"  />
                                    </div>
                                </div>
                            }) : ''
                        }
                        
                        
                    </div>
        </Modal>
        )  
    } else {
        return null
    }
}

function mapStateToProps(state) {
  return {
  };
}
 
function mapDispatchToProps(dispatch) {
  return {
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectSongModal);