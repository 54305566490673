import React, {useEffect, useState} from "react";

//custom components
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import CarouselList from "../../elements/carousellist/carousellist";
import PlayGroup from "../../elements/playgroup/playgroup";
import Badge from 'react-bootstrap/Badge'
import Pagination from "react-js-pagination";
import RightSide from "../../components/rightSide/rightSide";
import Announcement from "../../elements/Announcement/announcement";
import "./charts.scss";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import {connect} from 'react-redux';
import * as songActions from '../../store/song/actions';
import * as genreActions from '../../store/genres/actions';

const  Charts = ({ displayLoading, hideLoading, homeGenresList, getHomeGenres, chartData, getChartSongs, getTop20Tracks, top20Albums}) => {

  

  const [ currentGenreId, setCurrentGenreId ] = useState('all');
  const [ chartType, setChartType ] = useState('daily');
  const [ page, setPage ] = useState(1);
  const [ order, setOrder ] = useState('entity_id');
  const [ orderDir, setOrderDir ] = useState('DESC');

  useEffect(() => { 
    // load Page Data
    loadPageData();
  },[]);

  const loadPageData = async() => {
    displayLoading();
    if(!chartData){
      if(currentGenreId !== 'all'){
        await getChartSongs({chart_type: chartType, page: page, genre_id: currentGenreId})
      } else {
        await getChartSongs({chart_type: chartType, page: page, order: order, orderDir: orderDir})
      }
      
    }
    if(!homeGenresList){
      await getHomeGenres();
    }
    await getTop20Tracks({page: 1, limit: 15});
    hideLoading();
  }

  const genresSong = async (genre_id) => {
    setCurrentGenreId(genre_id)
    displayLoading();
    if(genre_id === 'all'){
      await getChartSongs({chart_type: chartType, page: 1}).then((res) => {
        hideLoading();
      })
    } else {
      await getChartSongs({chart_type: chartType, page: 1, genre_id: genre_id}).then((res) => {
        hideLoading();
      })
    }
  }

  const getChartSongByChartType = async (type) => {
    displayLoading();
    setChartType(type);
    if(currentGenreId === 'all'){
      await getChartSongs({chart_type: type, page: 1}).then((res) => {
        hideLoading();
      })
    } else {
      await getChartSongs({chart_type: type, page: 1, genre_id: currentGenreId}).then((res) => {
        hideLoading();
      })
    }
  }

  const handlePageChange = async(pageNumber) => {
    displayLoading();
    setPage(pageNumber);
    if(currentGenreId === 'all'){
      await getChartSongs({chart_type: chartType, page: pageNumber}).then((res) => {
        hideLoading();
      })
    } else {
      await getChartSongs({chart_type: chartType, page: pageNumber, genre_id: currentGenreId}).then((res) => {
        hideLoading();
      })
    }
  }

  return (
    <MusicDashboard rightContent={<RightSide blocks={{mostDownloaded: true, trendingTracks: true }} />}>
      <div>
        <Announcement page_name="mp3_chart" />
        <div className="d-flex align-items-center sectionTitleHead">
          <h3 className="text-nowrap text-uppercase mb-0">Charts</h3>
          <div className="pl-5 pr-4 text-uppercase filterLink">
            <a href="#" className={chartType === 'daily' ? "active" : ''} onClick={() => getChartSongByChartType('daily')}>
              daily
            </a>
            <a href="#" className={chartType === 'weekly' ? "active" : ''} onClick={() => getChartSongByChartType('weekly')}>
              weekly
            </a>
            <a href="#" className={chartType === 'monthly' ? "active" : ''} onClick={() => getChartSongByChartType('monthly')}>
              monthly
            </a>
          </div>
          { homeGenresList && homeGenresList.length > 0 ?
          <div className="ml-auto badgeList d-none d-lg-block">
              <Badge variant={currentGenreId === 'all' ? 'active' : '' } onClick={() => genresSong('all')}>All</Badge>
              {
                homeGenresList.map((genre, index)=>{
                  return <Badge variant={currentGenreId === genre.genre_id ? 'active' : '' } key={`genre-new-release-${genre.genre_id}`} onClick={() => genresSong(genre.genre_id)}>{genre.name}</Badge>
                })
              }
              
          </div>
          : ''
          }
        </div>
        
        { chartData && chartData.songs && chartData.songs.length > 0 ? <PlayGroup songs={chartData.songs}   /> : ''} 

        <div className="pagin text-center mt-5 mb-5">
          {
          chartData && chartData.pagination ? 
            <Pagination
                  activePage={chartData.pagination.page}
                  itemsCountPerPage={chartData.pagination.perPageLimit}
                  totalItemsCount={chartData.pagination.totalCount}
                  // pageRangeDisplayed={pageRangeDisplayed}
                  onChange={handlePageChange}
                  itemClass="page-item"
                  linkClass="page-link"
          /> : ''
          }
        </div>

        <div>
          <CarouselList title="MONTHLY TOP 20" sellAllLink="/" top20Albums={top20Albums} />
        </div>


      </div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    homeGenresList: state.genre.homeGenreList,
    top20Albums: state.song.top20Albums,
    chartData: state.song.chartData,
  	top20Albums: state.song.top20Albums,
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
    getHomeGenres: () => dispatch(genreActions.getHomeGenres()),
    displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
    hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
    getChartSongs: (post) => dispatch(songActions.getChartSongs(post)),
  	getTop20Tracks: () => dispatch(songActions.getTop20Albums()),

  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Charts);