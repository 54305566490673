import * as types from './type';

import { Subscription }  from  '../../services/subscription';

export function changeTheme(data) {
    return {
      type: types.CHNAGE_THEME,
      payload: data,
    };
};
