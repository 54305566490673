import React, {useState, useEffect} from "react";
import "./signUpModal.scss";

import { Link } from "react-router-dom";

import Modal from 'react-bootstrap/Modal';
import ModalDialog from 'react-bootstrap/ModalDialog';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalFooter from 'react-bootstrap/ModalFooter';

import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup'

import Form from 'react-bootstrap/Form';

const SignUpModal = ({showState, ShowFunction, showLogin, processRegistration, responseError, processing}) =>  {

  const [formData, setFormData] = useState({
    dj_name: '',
    email: '',
    password_hash: ''
  });
  const [ errors, setErrors ] = useState({});
  const [validated, setValidated] = useState(false);
  const [ formSubmitted, setFormSubmitted ] = useState(false);
  
  const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData({
          ...formData,
          [name]: value
      }); 
      if(formSubmitted){
        const newErrors = findFormErrors();   
        setErrors(newErrors);
        setFormSubmitted(false);
      }
  }

  const findFormErrors = () => { console.log('find')
      const { dj_name, email, password_hash, confirm_password } = formData
      const newErrors = {}

      
      // password errors
      if ( !dj_name || dj_name === '' ) newErrors.dj_name = 'DJ Name cannot be blank!'
      if ( !email || email === '' ) newErrors.email = 'Email cannot be blank!'
      if ( !password_hash || password_hash === '' ) newErrors.password_hash = 'Password cannot be blank!'
      // if ( !confirm_password || confirm_password === '' ) newErrors.confirm_password = 'Repeat Passwrod cannot be blank!'
      // if ( formSubmitted && confirm_password !== '' && confirm_password !== password_hash ) newErrors.confirm_password = 'Passwrod mismatch!'

      return newErrors
  }
  const handleSubmit = (event) => { 
      setFormSubmitted(true);
      const newErrors = findFormErrors();
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      // console.log(newErrors)
      if ( Object.keys(newErrors).length > 0 ) {
          console.log(newErrors)
          // We got errors!
          setErrors(newErrors)
      } else {
          signUp(formData);
      }
  }

  const signUp = async(data) => {
    processRegistration(data)
  }

  return(
    <Modal
    show={showState}
    onHide={ShowFunction}
    backdrop="static"
    keyboard={false}
    size="md"
  >
  <Form  validated={validated} onSubmit={handleSubmit} className="popup-form">
    <Modal.Header>
      <Modal.Title>Create An Account</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {responseError ? <div class="alert-danger p-1 mb-3" dangerouslySetInnerHTML={{__html: responseError}}></div> : ''}
      <InputGroup className="lineInput">
        <InputGroup.Text>
          <i className="lnir lnir-user-alt-1"></i>
        </InputGroup.Text>
        <Form.Control type="text" placeholder="DJ Name" name="dj_name" onChange={handleChange} isInvalid={ !!errors.dj_name } />
        <Form.Control.Feedback type="invalid">
          {errors.dj_name}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-3 lineInput">
        <InputGroup.Text>
          <i className="lnir lnir-envelope-alt"></i>
        </InputGroup.Text>
        <Form.Control type="email" placeholder="Email Address *" name="email" onChange={handleChange} isInvalid={ !!errors.email } />
        <Form.Control.Feedback type="invalid">
        {errors.email}
        </Form.Control.Feedback>
      </InputGroup>
      <InputGroup className="mt-3 lineInput"> 
        <InputGroup.Text>
          <i className="lnir lnir-lock-alt"></i>
        </InputGroup.Text>
        <Form.Control type="password" placeholder="Password *" name="password_hash" onChange={handleChange} isInvalid={ !!errors.password } />
        <Form.Control.Feedback type="invalid">
        {errors.password}
        </Form.Control.Feedback>
      </InputGroup>
      {/* <InputGroup className="mt-3 lineInput">
        <InputGroup.Text>
          <i className="lnir lnir-lock-alt-1"></i>
        </InputGroup.Text>
        <Form.Control type="password" placeholder="Re-type Password" name="confirm_password" onChange={handleChange} isInvalid={ !!errors.confirm_password } />
        <Form.Control.Feedback type="invalid">
        {errors.confirm_password}
        </Form.Control.Feedback>
      </InputGroup> */}
      <p className="mt-4 mb-0 infoInstuction">
        <small>
          Your personal data will be used to process your order, support your experience throughout this website, and for other purposes described in our <Link to={``}>privacy policy</Link>.
        </small>
      </p>

    </Modal.Body>
    <Modal.Footer>
        {processing ?<Button className="text-uppercase" type="submit" variant="light" className="primary-bg" disabled block> Processing....</Button>  : <Button className="text-uppercase" type="submit" variant="light" className="primary-bg" block> sign up</Button>}
        <Button className="text-uppercase" variant="light" block onClick={showLogin}>already has an account</Button>
    </Modal.Footer>
    </Form>
  </Modal>
  )  
}

export default SignUpModal;
