import * as types from './type';

import { User }  from  '../../services/user';

export function getCustomerProfile(token) {
    return {
      type: types.GET_CUSTOMER_PROFILE,
      payload: User.getCustomerProfile(token),
    };
};


export function forgetPassword(post) {
    return {
      type: types.RESET_CUSTOMER_PASSWORD_REQUEST,
      payload: User.forgetPassword(post),
    };
};

export function resetPassword(post) {
  return {
    type: types.RESET_PASSWORD,
    payload: User.resetPassword(post),
  };
};

export function updateProfile(post){
  return {
    type: types.UPDATE_PROFILE,
    payload: User.updateProfile(post),
  };
}

export function changePassword(post){
  return {
    type: types.CHANGE_PASSWORD,
    payload: User.changePassword(post),
  };
}

export function signUp(post){
  return {
    type: types.CUSTOMER_SIGNUP,
    payload: User.signUp(post),
  };
}

export function submitDJApp(post){
  return {
    type: types.SUBMIT_DJ_APP,
    payload: User.submitDJApp(post),
  };
}

