import React, {useEffect, useState} from "react";


import AccountNav from "../../elements/accountNav/accountNav";


import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';


import "./helpdesk.scss";
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import Table from 'react-bootstrap/Table';
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import {connect} from 'react-redux';
import SubmitTicket from "./submitTicket";
import RecentTickets from "./recentTickets";
import { useLocation, useRouteMatch } from "react-router-dom";

import * as helpdeskAction from '../../store/helpdesk/actions'



const Tickets = ({displayLoading, hideLoading, getTickets, tickets}) => {
    const [ page, setPage ] = useState();
    const [ status, setStatus ] = useState();
    const location = useLocation();
  
    useEffect(() => { 
		// load Page Data
		loadPageData();
    },[]);

    useEffect(() => { 

    },[location]);

    const loadPageData = async() => {
        let ticketStatus = 'all';
        if(location.pathname == '/tickets/open'){
            ticketStatus = 'open';
        } else if(location.pathname == '/tickets/close'){
            ticketStatus = 'close';
        }
        console.log(location.pathname)
        setStatus(ticketStatus);
        displayLoading();
        await getTickets({page: 1, pageLimit: 10, status: ticketStatus});
        hideLoading();
    }
    console.log(tickets)
    const handlePageChange = async (pageNumber) => {
        setPage(pageNumber);
        displayLoading();
        await getTickets({page: pageNumber, pageLimit: 10, status: status}).then((res) => {});
        hideLoading();
    }
    
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Helpdesk</h3>
            </div>
            <AccountNav/>        
            <div className="content-box">
            <Container fluid className="p-0">
              <Row className="">
                <Col lg={7}>
                  <Card className="border-0">
                    <Card.Body>
                        <h5 className="text-uppercase"><b>TICKETS</b></h5>

                        <Table hover responsive>
                            <thead>
                                <tr>
                                    <th>#Ticet</th>
                                    <th>Last Updated</th>
                                    <th>Subject</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            {
                                tickets && tickets.tickets && tickets.tickets.length > 0 ?
                                <tbody>
                                	{
                                		tickets.tickets.map((ticket, index)=>{
                                			return <tr key={`ticket-list${index}`}>
                                 					<td>{ticket.ticket_number}</td>
                                 					<td>{ticket.modified_at}</td>
                                					<td>{ticket.subject}</td>
                                					<td><Link to={`/ticket/details/${ticket.ticket_number}`}>View</Link></td>
                                 			</tr>
                                		})
                                	}	
                                    
                                </tbody>
                                : ''
                            }
                        </Table>
                        <div className="pagin text-center mt-5 mb-5">
                        {
                        tickets && tickets.pagination ? 
                            <Pagination
                                activePage={tickets.pagination.page}
                                itemsCountPerPage={tickets.pagination.perPageLimit}
                                totalItemsCount={tickets.pagination.totalCount}
                                // pageRangeDisplayed={pageRangeDisplayed}
                                onChange={handlePageChange}
                                itemClass="page-item"
                                linkClass="page-link"
                        /> : ''
                        }
                        </div>
                    </Card.Body>
                  </Card>
                </Col>
                <Col lg={5}>
                  {/* <RecentTickets /> */}
                  <SubmitTicket />
                </Col>
              </Row>
            </Container>
            </div>
    
        </MusicDashboard>
      );
}
function mapStateToProps(state) {
    return {
        tickets: state.helpdesk.tickets
    };
}

function mapDispatchToProps(dispatch) {
    return {
        displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getTickets: (post) => dispatch(helpdeskAction.getTickets(post))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets);