import React, { useState, useEffect } from "react";

import Spinner from 'react-bootstrap/Spinner';
import {connect} from 'react-redux';
import * as downloadActions from '../../store/download/actions';
import * as songActions from '../../store/song/actions';
import * as crateActions from '../../store/crate/actions';
import { useHistory } from "react-router-dom";

const DownloadCrateVersionButton = ({downloadCrateSongVersion, userProfile, song, version, getCustomerCrates, getCrateList, currentGenreId, perPageLimit, page, getCustomerDownloads }) => {
    
    const history = useHistory();
    const [ downloadInProcess, setDownloadInProcess ] = useState(false);

    const downloadSong = (version, songData) => {
        downloadCrateSongVersion({ version: version}).then(res => {
            getCustomerCrates();
            getCrateList({page: page, pageLimit: perPageLimit, genre_id: currentGenreId}, () => {
                // this.setState({genres: this.props.song.latestSong});
            })
            setDownloadInProcess(false);
            if(res.payload.response && res.payload.response.status === 403){
              alert(res.payload.response.data.message);
            } else if(res.payload.data) {
                getCustomerDownloads();
                const versionData = res.payload.data;
                const downloadFilename = versionData.uploaded_mp3_name;
                const url = songData.song_section_id == 1 ? `song/songsContainer/${versionData.version_code}/${versionData.file_name}` : `ck_kut/songsContainer/${versionData.version_code}/${versionData.file_name}`;
                var downloadurl = `https://www.clubkillers.com/neverbeendownload.php?url=${url}&file=${downloadFilename}`;
                window.location.assign(downloadurl);
              // CAll parent function
            }
        })
    }

    const downloadVersion = (song, version) => {
        setDownloadInProcess(true)
        if(userProfile && userProfile.group_id == 2){
            if(userProfile.payment_profile_status == 'paid'){
                downloadSong(version, song)
            } else if(userProfile.payment_profile_status == 'unpaid'){
                setDownloadInProcess(false);
                history.push('/subscription/plans')
            }
        }
    }

        if(downloadInProcess){
            return <span className="pointer"><Spinner animation="border" size="sm" variant="warning" /></span>
        } else {
            return (
                <span onClick={() => downloadVersion(song, version)} className="pointer"><i className="lnir lnir-download"></i></span>
            )
        }

    }
    function mapStateToProps(state) {
        return {
            userProfile: state.customer.customerProfile
        };
    }
   
   function mapDispatchToProps(dispatch) {
        return {
            downloadCrateSongVersion: (version) => dispatch(downloadActions.downloadCrateSongVersion(version)),
            getCustomerCrates: () => dispatch(songActions.getCustomerCrates()),
            getCrateList: (post) => dispatch(crateActions.getCrateList(post)),
            getCustomerDownloads: () => dispatch(downloadActions.getCustomerDownloads())
        };
    }
  
export default connect(mapStateToProps, mapDispatchToProps)(DownloadCrateVersionButton);
  