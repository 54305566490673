import React, { useEffect, useState } from "react";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";
import {connect} from 'react-redux';
import * as actions from '../../store/top20/actions';

//custom components
import PlayGroup from "../../elements/playgroup/playgroup";
import { Link } from "react-router-dom";
import { useLocation, useRouteMatch } from "react-router-dom";
import "./top20.scss";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import RightSide from "../../components/rightSide/rightSide";

const Top20Songs = ({getTop20Songs, top20Songs, displayLoading, hideLoading}) => {
    
  let match = useRouteMatch("/top20/songs/:id");
  const location = useLocation();

	const [ page, setPage ] = useState(0);
	const [dataProcessing, setDataProcessing] = useState();
	
	useEffect(() => { 
		displayLoading();
		getTop20Songs({page: page, top20_track_id: match.params.id}).then((res) => {
			hideLoading();
		});
	},[location])
	
	// currentTop20Track
	console.log(top20Songs, 'll')



  return (
    <MusicDashboard rightContent={<RightSide blocks={{mostDownloaded: true, trendingTracks: true }} />}>
			<div>
				<div className="d-flex align-items-baseline sectionTitleHead mb-5">
					<h3 className="text-truncate text-uppercase">{ top20Songs && top20Songs.currentTop20Track && top20Songs.currentTop20Track.track ? top20Songs.currentTop20Track.track.title : ''}</h3>
				</div>
				{
					top20Songs && top20Songs.currentTop20Track.songs && top20Songs.currentTop20Track.songs.length > 0 ?
					<PlayGroup songs={top20Songs.currentTop20Track.songs}   /> 
					: ''
				}
				
			</div>
    </MusicDashboard>
  );
}

function mapStateToProps(state) {
  return {
    top20Songs: state.top20.top20Songs
  };
 }
 
 function mapDispatchToProps(dispatch) {
  return {
		getTop20Songs: (data) => dispatch(actions.getTop20Songs(data)),
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar))
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Top20Songs);