import React, {useEffect, useState} from "react";

//custom components
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import DatePicker from 'react-modern-calendar-datepicker';

import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";
import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import SelectSongModal from '../../elements/mp3Modal/selectSongModal';

import Form from 'react-bootstrap/Form';
import { FilePond, File, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css'

import "./uploads.scss";

import { showLoading, hideLoading } from 'react-redux-loading-bar';
import { Link } from "react-router-dom";
import {connect} from 'react-redux';

import { useLocation, useRouteMatch, useHistory } from "react-router-dom";

import config from '../../config/config';

import  API_BASE_URL  from '../../config/config';

import * as genresActions from '../../store/genres/actions';
import * as versionAction from '../../store/version/actions'
import * as fileContainerAction from '../../store/filecontainer/actions';
import * as songActions from '../../store/song/actions';

const EditSong = ({ getEditSongVersions, getGenresWithCount, getSongEntry, removeSongVersion, updateSong, genres, songEntity, displayLoading, hideLoading, versions, getContainerSongsList, fileLists }) => {

    let match = useRouteMatch("/uploads/edit-song/:entity_id");
    const location = useLocation();
    const history = useHistory();

    const [ songId, setSongId ] = useState();
    const [ genresData, setGenresData ] = useState();
    const [ versionData, setVersionData ] = useState();
    const [ selectedSong, setSelectedSong ] = useState();
    const [ selectedVersion, setSelectedVersion ] = useState();
    const [selectedGenres, setSelectedGenres] = useState([]);
    const [ expireDate, setExpireDate ] = useState();
    const [ currentLabel, setCurrentLabel ] = useState();
    const [ btnDisable, setBtnDisable ] = useState(false);
   
    const [ formSubmited, setForrmSubmited ] = useState(false);
    const [ showSelectWindow, setShowSelectWindow ] = useState(false);
    const [formData, setFormData] = useState({
        title: '',
        artist: '',
        year: '',
        song_key: '',
        min_bpm: '',
        max_bpm: '',
        genres: '', 
        search_version_term: '',
        expire_date: ''
    });
    const [ errors, setErrors ] = useState({});
    const [validated, setValidated] = useState(false);
    const uploadURL = API_BASE_URL.apiUrl+"/upload/songs.json?store_id=1&lang_code=en";
    const [files, setFiles] = useState([]);

	useEffect(() => { 
        // load Page Data
        setSongId(match.params.entity_id)
		loadPageData();
    },[]);
    
    // console.log(songEntity)
  
	const loadPageData = async() => {
    
        displayLoading();
        
        getEditSongVersions({entity_id: match.params.entity_id}).then(res => {
            if(res && res.payload && res.payload.data){
                setVersionData(res.payload.data)
            }
        });
        getSongEntry({entity_id: match.params.entity_id}).then(res => {
            if(res && res.payload && res.payload.data){
                let editFormData = formData;
                const editSongDataRes = res.payload.data;
                editFormData.title = editSongDataRes.title;
                editFormData.artist = editSongDataRes.artist;
                editFormData.year = editSongDataRes.year;
                editFormData.song_key = editSongDataRes.song_key;
                editFormData.min_bpm = editSongDataRes.min_bpm;
                editFormData.search_version_term = editSongDataRes.search_version_term;
                editFormData.expire_date = editSongDataRes.expire_date;
                editFormData.genres = editSongDataRes.genres;
                setSelectedGenres(editSongDataRes.genres);
                console.log('editFormData', editFormData)
                setFormData(editFormData);
            }
        });
        if(genres){
            setGenresData(genres)
        } else {
            await getGenresWithCount().then(res=> {
                
                if(res && res.payload && res.payload.data && res.payload.data.data && res.payload.data.data.genres){
                    setGenresData(res.payload.data.data.genres)
                }
            });
        }
        
		
		hideLoading();
    }
    
    const saveSongEntry = async(post) => {
        setBtnDisable(true);
        displayLoading()
        await updateSong(post).then(res => {
            if(res && res.payload && res.payload.data){
                history.push('/uploads/index');
            }
        })
        hideLoading();
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });    
    }

    const handleCheckboxChange = event => {
        let newArray = [...selectedGenres, event.target.value];
        if (selectedGenres.includes(event.target.value)) {
           newArray = newArray.filter(genreId => genreId !== event.target.value);
        }
        setSelectedGenres(newArray);
        setFormData({
            ...formData,
            ['genres']: newArray
        }); 
    };

    const findFormErrors = () => {
        const { title, artist, year, song_key, min_bpm, genres } = formData
        const newErrors = {}

        if ( !title || title === '' ) newErrors.title = 'Song title cannot be blank!'
        if ( !artist || artist === '' ) newErrors.arrtist = 'Artist cannot be blank!'
        if ( !year || year === '' ) newErrors.year = 'Year cannot be blank!'
        if ( !song_key || song_key === '' ) newErrors.song_key = 'Song Key cannot be blank!'
        if(!min_bpm || min_bpm === '') newErrors.min_bpm = 'BPM cannot be blank!'
        if(!genres || genres.length === 0) newErrors.genres = 'Please select genre!'
        // if( !payment_method || payment_method === '')  newErrors.payment_method = 'Select a payment method'
        // if( !terms_condition || terms_condition === '')  newErrors.terms_condition = 'Terms & Condition is require'

        return newErrors
    }

    const handleSubmit = (event) => { 
        const newErrors = findFormErrors();
        const form = event.currentTarget;
        event.preventDefault();
        event.stopPropagation();
        setForrmSubmited(true);
        // console.log('formData', formData)
        if ( Object.keys(newErrors).length > 0 ) {
            
            // We got errors!
            setErrors(newErrors)
        } else {
            let postData = formData;
            // postData.versions = versionData;
            postData.expire_date = expireDate;
            saveSongEntry({entity_id: match.params.entity_id, songData: postData, versions: versionData});
        }
    }
    
    const changeLabelValue = (version, event) => {
        const { value } = event.target;
        setCurrentLabel(value);
        version.new_label = value;
    }

    const initChangeLabel = (version, newLabel) => {
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].new_label = newLabel;
        setVersionData(newVersionData);
        applyChanges(newVersionData);
    }
    
    const applyChanges = (version) => {
        setSelectedVersion(version);
    }

    const selectSong = (version) => {
        setSelectedVersion(version)
        getContainerSongsList();
        setShowSelectWindow(!showSelectWindow);
    }

    /**
     * update Version name of song
     * @param {*} version 
     * @param {*} event 
     */
    const saveLabel = (version, newLabel) => {
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].name = currentLabel;
        newVersionData[foundIndex].new_label = "";
        setVersionData(newVersionData);
        applyChanges(version);
    }

    const removeSong = (version) => { 
        console.log('version', version);
        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;

        if(version.entity_id !== undefined && version.song_version_id !== undefined){
            removeSongVersion({entity_id: version.entity_id, song_version_id: version.song_version_id});
            delete newVersionData[foundIndex]['entity_id'];
            delete newVersionData[foundIndex]['song_version_id'];
        }
        

        newVersionData[foundIndex].song = "";
        newVersionData[foundIndex].new_label = "";
        newVersionData[foundIndex].start_min = "";
        newVersionData[foundIndex].start_sec = "";

        setVersionData(newVersionData);
        if(selectedVersion){
            applyChanges('');
        } else{
            applyChanges(version);
        }
        
    }

    /**
     * update Version Start min of song
     * @param {*} version 
     * @param {*} event 
     */
    const updateVersionStartMin = (version , event) => {
        const { value } = event.target;

        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].start_min = value;
        setVersionData(newVersionData);
        applyChanges(version);
        
    }

    /**
     * update Version Start sec of song
     * @param {*} version 
     * @param {*} event 
     */
    const updateVersionStartSec = (version , event) => {
        const { value } = event.target;

        var foundIndex = versionData.findIndex(x => x.version_id === version.version_id);
        let newVersionData = versionData;
        newVersionData[foundIndex].start_sec = value;
        setVersionData(newVersionData);
        applyChanges(version);
    }

    const setSong = (song) => {
        const ver  = selectedVersion;
        ver.song = song.file;
        
        const tags = {};
        Object.keys(song.tag).forEach(function(key) {
            // console.log('Key : ' + key + ', Value : ' + song.tag[key])
            tags[key] = song.tag[key][0];
        })
        setFormData(tags)
        setShowSelectWindow(!showSelectWindow)
    }
  return (
    <MusicDashboard>
       <div className="d-flex align-items-baseline sectionTitleHead mb-4">
          <h3 className="text-truncate text-uppercase">ADD NEW</h3>
        </div>
        
        <AccountNav/>        

        <div className="content-box">
        <Card className="border-0">
              <Card.Body>
                <SelectSongModal showState={showSelectWindow} ShowFunction={() => setShowSelectWindow(!showSelectWindow)} fileLists={fileLists} setSong={setSong}  />
                <div className="cardMark mb-3 d-flex justify-content-between"><span>Add Song</span> <span className=""><Link to={`/uploads/index`} className="btn btn-light primary-bg">View All</Link></span></div>
                <Container>
                    <Row>
                        <Col lg={12}>
                            {/* <FilePond name="UploadForm[mp3Files]" allowMultiple={true} server={config.apiUrl+'/upload/songs.json?store_id=1&lang_code=en'} /> */}
                            <FilePond
                                files={files}
                                onupdatefiles={setFiles}
                                allowMultiple={true}
                                maxFiles={2}
                                server={uploadURL}
                                name="UploadForm[mp3Files]" 
                                labelIdle='Upload Songs'
                            />
                        </Col>
                    </Row>
                
                    <Form  validated={validated} onSubmit={handleSubmit}>                    
                        <Row>
                            { versionData && versionData.length > 0 ? 
                                    versionData.map((version, v) => {
                                        return <Col key={`version${v}`} lg={6} className="mb-3">
                                                { version.new_label !== ""  ?  <div className="mb-1"><input type="text" value={version.new_label} name="version_label" onChange={(e) => {
                                                                                changeLabelValue(version, e)
                                                                            }} />  <i className="lnir lnir-save" onClick={() => saveLabel(version, version.new_label)} ></i> </div> : <Form.Label onClick={() => initChangeLabel(version, version.name)}>{version.new_label !== '' ? version.new_label : version.name}</Form.Label>}
                                                {   version.song !== '' ?  
                                                                        <div>
                                                                                <p className="small">{version.song} <i onClick={() => removeSong(version)} className="lnir lnir-trash"  ></i></p>
                                                                                <div className="d-flex">
                                                                                    <input type="text" name="min" placeholder="Min" onChange={(e) => {
                                                                                        updateVersionStartMin(version, e)
                                                                                    }} /> 
                                                                                    <input type="text" name="sec" placeholder="Sec" onChange={(e) => {
                                                                                        updateVersionStartSec(version, e)
                                                                                    }} />
                                                                                </div>
                                                                                <span className="small">play song start time (min/sec)</span>
                                                                        </div> : 
                                                                        <button type="button" className="btn-block btn primary-bg p-3" onClick={() => selectSong(version)}>{version.name}{version.song}</button>
                                                }
                                            </Col>
                                    })
                                    : '' 
                                }
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control type="text" placeholder="Song Title" name="title" onChange={handleChange} isInvalid={ !!errors.title } value={formData.title} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.title }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>Artist</Form.Label>
                                        <Form.Control type="text" placeholder="Artist" name="arrtist" onChange={handleChange} isInvalid={ !!errors.arrtist } value={formData.artist} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.artist }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>Year</Form.Label>
                                        <Form.Control type="text" placeholder="Year" name="year" onChange={handleChange} isInvalid={ !!errors.year } value={formData.year} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.year }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                </Col>
                                <Col lg={6}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>BPM</Form.Label>
                                        <Form.Control type="text" placeholder="BPM" name="min_bpm" onChange={handleChange} isInvalid={ !!errors.min_bpm } value={formData.min_bpm} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.min_bpm }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>Key</Form.Label>
                                        <Form.Control type="text" placeholder="Song Key" name="song_key" onChange={handleChange} isInvalid={ !!errors.song_key } value={formData.song_key} />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.song_key }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>Insert Additional Search</Form.Label>
                                        <Form.Control type="text" placeholder="Additional Search Information" name="search_version_term" onChange={handleChange} isInvalid={ !!errors.cardholder_name } />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.cardholder_name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <Form.Group className="mb-3" controlId="formDepartmentId" >
                                        <Form.Label>(Song by default will disable in 90 days) or pick disable date</Form.Label>
                                        {/* <Form.Control type="text" placeholder="Select a disable date" name="expire_date" onChange={handleChange} isInvalid={ !!errors.expire_date } /> */}
                                        <DatePicker
                                            onChange={setExpireDate}
                                            inputPlaceholder="From" 
                                            placeholder="Select a disable date"
                                            shouldHighlightWeekends
                                            name="expire_date"
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            { errors.cardholder_name }
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    
                                </Col>
                            </Row>
                            
                            
                            
                            <Row>
                            {
                                songEntity && genresData && genresData.length > 0 ?
                                    genresData.map((item, index) => {
                                    return  <Col lg={4} key={`genrecheck${index}`}>
                                                <div className="card border-0">
                                                    <div className="card-body p-2">
                                                        <h3 className="sub-heading border-bottom border-dark pb-3 mb-3">{item.name}</h3>
                                                        { 
                                                            item.subgenre !== undefined && item.subgenre.length > 0 ?
                                                                item.subgenre.map((subgenres, si) => {
                                                                    return <div key={`subgenrrescheck${si}`}>
                                                                        <Form.Check 
                                                                            value={subgenres.genre_id}
                                                                            type='checkbox'
                                                                            id={`genres${subgenres.genre_id}`}
                                                                            onChange={handleCheckboxChange}
                                                                            name="genres[]"
                                                                            label={subgenres.name} 
                                                                            isInvalid={ !!errors.genres }
                                                                            checked={selectedGenres && selectedGenres.length > 0 && selectedGenres.includes(subgenres.genre_id)}
                                                                        />
                                                                        <Form.Control.Feedback type="invalid">
                                                                            { errors.genres }
                                                                        </Form.Control.Feedback>
                                                                    </div>
                                                                })
                                                            : ''
                                                        }
                                                    </div>
                                                </div>
                                            </Col>
                                    })
                                : ''
                            }
                        </Row>
                        <Row>
                            <Col lg={12}>
                            {btnDisable ? <a href="#" className="btn btn-light primary-bg disabled" disabled>Processing .....</a> : <Button variant="light" className="primary-bg" type="submit">Save</Button>}
                            </Col>
                        </Row>
                        
                    </Form>
                </Container>
              </Card.Body>
            </Card>
        </div>

    </MusicDashboard>
  );
}
function mapStateToProps(state) {
    return {
        genres: state.genre.editSongGenres,
        versions: state.version.editSongVersions,
        fileLists: state.filecontainer.songlists,
        songEntity: state.song.editSongEntity
    };
}

function mapDispatchToProps(dispatch) {
  return {
		displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
		hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
		getGenresWithCount: () =>dispatch(genresActions.getGenresWithCount()),
        getEditSongVersions: (post) =>dispatch(versionAction.getEditSongVersions(post)),
        getContainerSongsList: () => dispatch(fileContainerAction.getContainerSongsList()),
        getSongEntry: (post) => dispatch(songActions.getSongEntry(post)),
        removeSongVersion: (post) => dispatch(versionAction.removeSongVersion(post)),
        updateSong: (post) => dispatch(songActions.updateSong(post))
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EditSong);