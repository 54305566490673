import React, {useEffect, useState} from "react";


import Button from 'react-bootstrap/Button'
import AccountNav from "../../elements/accountNav/accountNav";

import payment from "../../assets/img/Payments.png";

import MusicDashboard from "../../Layout/MusicDashboard/MusicDashboard";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Card from 'react-bootstrap/Card';

import Table from 'react-bootstrap/Table';

import "./subscription.scss";

import config from '../../config/config';
import { Link, useHistory } from "react-router-dom";
import {connect} from 'react-redux';
import { showLoading, hideLoading } from 'react-redux-loading-bar';
import * as subscriptionAction from '../../store/subscription/actions';

const SubscriptionPlans = ({getServices, displayLoading, hideLoading, plans, getCustomerAgreement, agreement}) => {

   
    const history = useHistory();

    const [planId, setPlanId] = useState();

    if(agreement.profile_status == 'CREATED'){
        history.push('/account/manage-subscription');
    }

    useEffect(() => { 
        // load Page Data
        
		loadPageData();
	},[]);
    
	const loadPageData = async() => {
   		displayLoading();
        await getServices({subscription_type_id: 1});
        await getCustomerAgreement();
		hideLoading();
    }
    
    const selectPlan = (plan) => {

    }
    
    return (
        <MusicDashboard>
           <div className="d-flex align-items-baseline sectionTitleHead mb-4">
              <h3 className="text-truncate text-uppercase">Subscription</h3>
            </div>
            
            <AccountNav/>        
    
            <div className="content-box">
                <Container fluid className="p-0">
                    <Row>
                        <Col lg={12} className="text-center mb-4">
                            <h2 className="mb-3">Choose Your Plan</h2>
                            <p className="text-muted">
                                {
                                    agreement && agreement.plan_id ? 
                                        'Upgrade your account to experience on Clubkillers site'
                                        :
                                        'Create your account to experience on Clubkillers site'
                                }
                            </p>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        {
                            plans && plans.length > 0 ? 
                                plans.map((item, i) => {
                                    return <div className="planBox card-body" key={`service-`+i}>
                                                <div  className={item.id == planId ? "manage-subscription-plan-holder active" : "manage-subscription-plan-holder", 'card border-0'} key={`subscription`+i} onClick={() => selectPlan(item)}>
                                                    <div class=" p-lg-4">

                                                        <h5 className="plan-title text-uppercase"><b>{item.title }</b></h5>
                                                        <div className="plan-price"><sup>$</sup><span>{item.price}</span> <sub>/month</sub></div>                                                        
                                                        <h6 class="text-muted">{item.description}</h6>

                                                        {/* <div className="plan-validity m-0">{item.description}</div> */}
                                                        <hr/>
                                                        <div dangerouslySetInnerHTML={{__html: item.features}}></div>
                                                        {/* <ul className="feateureList">
                                                            <li><i className="lnir lnir-checkmark-circle"></i> Unlimited MP3 audio downloads</li>
                                                            <li><i className="lnir lnir-checkmark-circle"></i> Unlimited MP4 video downloads</li>
                                                            <li><i className="lnir lnir-checkmark-circle"></i> Tagged with CLub Killers, Genre &amp; Key </li>
                                                        </ul> */}

                                                    {/* <div className="custom-radio ml-auto">dds</div> */}
                                                    
                                                    </div>
                                                </div>
                                                <div class="action">
                                                        {
                                                            agreement && agreement.plan_id && item.id == agreement.plan_id ? 
                                                                <Button variant="light" className="primary-bg btn-block p-3" disabled>Upgrade Plan</Button>
                                                            :
                                                            <Link to={`/subscription/choose-payment/${item.id}`} className="btn btn-light primary-bg btn-block p-3">Upgrade Plan</Link>                                                        }                                                        
                                                    </div>
                                            </div>
                                })
                            : ''
                        }
                       
                    </Row>
                    <Row>
                        <Col lg={12} className="text-center mt-4">
                            <p className="text-muted">Your Payment will auto-pay monthly. Don't worry! You can cancel anytime.</p>
                            <div>
                                <img src={payment} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </MusicDashboard>
    )
}
function mapStateToProps(state) {
  return {
	agreement: state.subscription.agreement,
	plans: state.subscription.plans
  };
}

function mapDispatchToProps(dispatch) {
  	return {
        getServices: (post) => dispatch(subscriptionAction.getServices(post)),
      	displayLoading: (sectionBar) => dispatch(showLoading(sectionBar)),
        hideLoading: (sectionBar) => dispatch(hideLoading(sectionBar)),
        getCustomerAgreement: () => dispatch(subscriptionAction.getCustomerAgreement()),
  	};
}

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlans);