import React, {useState} from "react";
import "./albumtracks.scss";
import PlayPauseButton from '../actionBtn/playPauseBtn';
import DownloadVersionButton from '..//actionBtn/downloadVersion';
import {connect} from 'react-redux';
import * as playlistActions from '../../store/playlist/actions';
import * as downloadActions from '../../store/download/actions';


const Albumtracks = ({songs, type, currentSong, isPlaying}) =>  {

  const [activeAlbumSong, setActiveAlbumSong ] = useState(false);
  const [ firstVersionAdded, setFirstVersionAdded ] = useState(0);

  

  return(
    <div>
      {
        songs && songs.length > 0 ? 
          songs.map((song, index) => {
            return <div className="d-flex trackList align-items-center mt-4" key={`albumtracks-${type}-${index}`}>
                    {
                      isPlaying &&  currentSong && currentSong.songData && currentSong.version == song.version[0].song_version_id  && currentSong.id == song.entity_id ?
                        // activeAlbumSong  ?
                          <div className= "count active">
                              <PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
                            </div>
                      : 
                          <div className= "count"><span>{index+1}</span>
                            <PlayPauseButton song={song} version={song.version[0]} setActiveAlbumSong={setActiveAlbumSong} />
                          </div>
                     }
                    <div className="img">
                      <figure className="bg-dark" style={{ backgroundImage : song.image && song.image !== '' ? `url(${song.image})` : '', backgroundSize: 'cover' }}></figure>
                    </div>
                    <div className="info">
                      <h6 dangerouslySetInnerHTML={{__html: song.title}}></h6>
                      <p dangerouslySetInnerHTML={{__html: song.artist}}></p>
                    </div>
                    <div className="download-link">
                      <DownloadVersionButton song={song} version={song.version[0]} />
                    </div>
                </div>
          })
        : ''
      }
    </div>
  )
  
}

  function mapStateToProps(state) {
      return {
        playLists: state.playlist.playListSongs,
        currentSong: state.song.currentSong,
        isPlaying: state.song.isPlaying,
      };
  }
 
 function mapDispatchToProps(dispatch) {
  return {
    getPlayList: () => dispatch(playlistActions.getPlayList()),
  };
 }

export default connect(mapStateToProps, mapDispatchToProps)(Albumtracks);
