const config = {
    // apiUrl: 'http://apilocal.clubkillers.com/v1', // https://api.clubkillers.com/v1
    // blogApiUrl: 'https://www.clubkillers.com/updates/wp-json/wp/v2',
    // uploadUrl: 'http://apilocal.clubkillers.com/',
    // downloadUrl: "http://uploads.clubkillers.com/",
    // downloadBundleUrl: "http://apilocal.clubkillers.com/",
    apiUrl: 'https://sdemo.neverbeencool.com/v1', // https://api.clubkillers.com/v1
    blogApiUrl: 'https://www.clubkillers.com/updates/wp-json/wp/v2',
    uploadUrl: 'https://sdemo.neverbeencool.com/',
    // downloadUrl: "https://uploads.neverbeencool.com/",
    downloadUrl: "http://upload.clubkillers.com/",
    downloadSelfUrl: "https://sdemouploads.neverbeencool.com/",
    downloadBundleUrl: "https://api.neverbeencool.com/",
    liveSiteUrl: "https://new.neverbeencool.com",
    demoSiteUrl: "http://localhost:3000/",
    token: 'asdfd',
    itemsCountPerPage: 24,
    pageRangeDisplayed: 5,
    perVersionDownloadLimit: 2,
    googleCaptchaSiteKey: '6LcKu8YUAAAAAGtn2eMY0KZhdfofg1Fws2AwK_vU', // for live neverbeencool: '6LcKu8YUAAAAAGtn2eMY0KZhdfofg1Fws2AwK_vU' // for localhost: "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
    google_analytic_client_id: '538307733305-7m31826nj27mhbietnhchaht7umaec4n.apps.googleusercontent.com',
    google_analytic_client_secret : 'oZ6UjAdrJl-b1kJLHESuRvwU',
    facebookUrl: 'https://www.facebook.com/clubkillers',
    twitterUrl: 'https://twitter.com/clubkillers',
    youtubeUrl: 'https://www.youtube.com/clubkillerspromo',
    instagramUrl: 'https://www.instagram.com/clubkillers',
    shopifyApiKey: '5841318c5e339e7bcc49fb919fad51ee',
    shopifyApiPassword: '777c20eab1b5ac1bbf76449e37d9bb3c',
    shopifySharedSceret: 'eca2e4efb5ba725a510d8d9b4e1db098',
    //78bd5e4af67fdfc8556633e89abbaf2a:8179dc911b1a124fe1600cd881226886@
    shopifyProductAPIURL: 'https://clubkillers.myshopify.com/admin/api/2020-01/collections/129405845582/products.json?limit=10&sort_order=best-selling&fields=id,title',
    dropBoxAppKey: '913nqk58g4x2c28',
    dropBoxSecret: 'iw1e0oryycsxql6',
    dropBoxAuthUrl: 'https://www.dropbox.com/oauth2/authorize?client_id=913nqk58g4x2c28&response_type=token&redirect_uri=',
    dropBoxRedirectURI: 'http://localhost:3000/dropbox-auth',
    googleAPIClientId: '19540333165-ip4mhfpscgmnqo23q5qm94slf02llshh.apps.googleusercontent.com',
    googleAPIClientSceret: 'TJLzrZSSmMfitgahQM0igx86',
    googleDriveAuthUrl: 'https://accounts.google.com/o/oauth2/auth?response_type=token&client_id=538307733305-7m31826nj27mhbietnhchaht7umaec4n.apps.googleusercontent.com&state&scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fdrive&prompt=select_account%20consent&redirect_uri=',
    googleDriveRedirectURI: 'http://localhost:3000/google-auth'

};

export default config;